import React, { useMemo, useState } from "react";
import { rootNodeID, TemporaryMediaList } from "../../model/List";
import { DialogProps } from ".";
import { Dialog, DialogContent } from "./Dialog";
import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import { parseQueryString, isSearchQueryParseError, SearchRequestSort } from "../../model/Search";
import { ListTree } from "../ListTree";
import { MediaFileType } from "../../model/Media";
import { useApp } from "../..";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "75vh",
  },
}));

export type CreateSmartPlaylistDialogProps = DialogProps & {
  src: TemporaryMediaList,
};

export default function CreateSmartPlaylistDialog(): JSX.Element {
  return <Dialog
    id="CreateSmartPlaylistDialog"
    content={function Content(props: CreateSmartPlaylistDialogProps) {
      const { src } = props;
      const { list: List } = useApp();
      const [ listName, setListName ] = useState(generatePlaylistName(src.query ?? ""));

      const [ query, setQuery ] = useState(src.query ?? "");
      const queryParseResult = useMemo(() => parseQueryString(query), [ query ]);
      const queryError = isSearchQueryParseError(queryParseResult) ? queryParseResult.searchQueryParseError : null;

      const [ limit, setLimit ] = useState("300");
      const limitError = (/^[0-9]+$/.test(limit)) ? null : "Limit must be valid number";

      const [ mediaType, setMediaType ] = useState<MediaFileType>(src.mediaTypes[0]);

      const [ parentFolderID, setParentFolderID ] = useState(rootNodeID);

      const canSave = !queryError && !limitError;
      const save = async () => {
        if (!canSave) return;

        const parent = await List.get(parentFolderID);
        if (parent.type !== "folder") throw new Error(`Speficied parent node ${parentFolderID} is not folder.`);
        await List.createSmartPlaylist({
          parent,
          name: listName,
          query: {
            types: [ mediaType ],
            query,
          },
          limit: +limit,
          sort: SearchRequestSort.DateAdded,
        });
      };

      const classes = useStyles();
      return <DialogContent
        {...props}
        maxWidth="xl"
        className={classes.root}
        title={<>Create smart playlist</>}
        onSave={save}
        disableSave={!canSave}
      >
        <TextField
          label="Smart playlist name"
          fullWidth={true}
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        />
        <TextField
          label="Query"
          fullWidth={true}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          helperText={queryError}
          error={!!queryError}
        />

        <FormControl>
          <InputLabel>Media Type</InputLabel>
          <Select value={mediaType} onChange={(e) => setMediaType(e.target.value as MediaFileType)}>
            {src.mediaTypes.map((t) => <MenuItem value={t}>{t}</MenuItem>)}
          </Select>
        </FormControl>
        <TextField
          label="Limit"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
          helperText={limitError}
          error={!!limitError}
        />

        <p>Select folder:</p>
        <ListTree
          types={src.mediaTypes}
          showRootFolder={true}
          folderOnly={true}
          isSelected={(node) => node.id === parentFolderID}
          onFolderClick={(folder) => setParentFolderID(folder.id)}
        />
      </DialogContent>;
    }}
  />;
}

function generatePlaylistName(q: string): string {
  const query = parseQueryString(q);
  if (isSearchQueryParseError(query)) return "";

  let elements: string[] = [];
  const keywordCriteria = query.find((c) => c.t === "kw" || c.t === "akw");
  if (keywordCriteria) elements.push((keywordCriteria as any).v);

  const ratingCriteria = query.find((c) => c.t === "r");
  if (ratingCriteria && (ratingCriteria as any).l >= 1) {
    const lowerBound = (ratingCriteria as any).l as number;
    elements.push("★".repeat(lowerBound) + "☆".repeat(5 - lowerBound));
  }

  const adateCriteria = query.find((c) => c.t === "adatein");
  if (adateCriteria) elements.push(`in ${(adateCriteria as any).days} days`);

  return elements.join(" ");
}
