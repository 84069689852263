import { DateTime, Duration } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { Player } from "..";
import { HistoryMetadataPlayerEnum } from "../../api/generated/api-client";
import Clock from "../../Clock";
import { isAudioOrVideo, parseFileInfo } from "../../FileInfo";
import HistoryModel, { MediaPlayHistory } from "../../History";

type State = {
  historyID: string,
  startedAtUnixEpoch: number,
  lastObservedUnixEpoch: number,

  backupAtEpoch: number | null,

  mediaID: string,
  mediaDurationMills: number,
  fileHash: string | null,

  accumulatedDurationMills: number,
  streakStartMediaTimeSec: number | null,
  streakLastObservedMediaTimeSec: number | null,
};

const historyStorageKeyPrefix = "smss.media-play-history.";
const backupKeyOf = (state: State) => historyStorageKeyPrefix + state.historyID;
const backupIntervalMs = 3 * 1000;
const backupStaleCount = 5;

export type MediaPlayHistoryObserverDeps = {
  clock: Clock,
  player: Player,
  history: HistoryModel,
};

export default class MediaPlayHistoryObserver {
  private clock: Clock;
  private player: Player;
  private history: HistoryModel;
  private storage: Storage;

  constructor(deps: MediaPlayHistoryObserverDeps) {
    this.clock = deps.clock;
    this.player = deps.player;
    this.history = deps.history;
    this.storage = window.localStorage;

    this.salvageBackupedStates();
    this.setHandlers();
    this.startStateBackupWorker();
  }

  private state: null | State = null;
  private setHandlers() {
    const player = this.player;
    this.player.addListener("media-not-playable", () => {
      if (this.state !== null && player.media?.id === this.state.mediaID) this.discardState();
    });
    this.player.addListener("file-changed", () => {
      this.openState();
      if (this.state) {
        if (this.state.fileHash === null) this.state.fileHash = player.fileHash;
        this.state.lastObservedUnixEpoch = this.clock.unixEpochMills;
      }
    });
    this.player.addListener("pause-resume", () => {
      this.openState();
      this.endStreak();
      if (this.state) this.state.lastObservedUnixEpoch = this.clock.unixEpochMills;
    });
    this.player.addListener("seek-started", () => {
      this.endStreak();
      if (this.state) this.state.lastObservedUnixEpoch = this.clock.unixEpochMills;
    });
    this.player.addListener("current-time-changed", () => {
      const t = player.currentTimeSec;
      if (t === null) {
        this.endStreak();
      } else {
        const state = this.state;
        if (state !== null) {
          state.lastObservedUnixEpoch = this.clock.unixEpochMills;
          if (state.streakStartMediaTimeSec === null) state.streakStartMediaTimeSec = t;
          state.streakLastObservedMediaTimeSec = t;
        }
      }
    });
    this.player.addListener("end-of-media", () => { this.finishState(); });
    this.player.addListener("current-media-changed", () => {
      if (this.state !== null && player.media?.id !== this.state.mediaID) this.finishState();
    });
  }

  private openState() {
    if (this.state === null) {
      const player = this.player;
      const media = player.media;
      const fileInfo = media ? parseFileInfo(media.file.files[0]) : null;
      if (media && fileInfo && isAudioOrVideo(fileInfo)) {
        this.state = {
          historyID: `hist-play-${uuidv4().replaceAll("-", "")}`,
          startedAtUnixEpoch: this.clock.unixEpochMills,
          lastObservedUnixEpoch: this.clock.unixEpochMills,
          backupAtEpoch: null,

          mediaID: media.id,
          mediaDurationMills: fileInfo.duration.toMillis(),
          fileHash: null,

          accumulatedDurationMills: 0,
          streakStartMediaTimeSec: null,
          streakLastObservedMediaTimeSec: null,
        };
      }
    }
  }

  private endStreak() {
    if (this.state === null) return;

    const state = this.state;
    if (state.streakLastObservedMediaTimeSec !== null && state.streakStartMediaTimeSec !== null) {
      state.accumulatedDurationMills += (state.streakLastObservedMediaTimeSec - state.streakStartMediaTimeSec) * 1000;
    }
    state.streakStartMediaTimeSec = null;
    state.streakLastObservedMediaTimeSec = null;
  }

  private stateToHistory(state: State): MediaPlayHistory | null {
    if (!state.fileHash) return null;

    let playbackMills = state.accumulatedDurationMills;
    if (state.streakLastObservedMediaTimeSec !== null && state.streakStartMediaTimeSec !== null) playbackMills += (state.streakLastObservedMediaTimeSec - state.streakStartMediaTimeSec) * 1000;

    if (playbackMills <= 0) return null;
    return {
      id: state.historyID,
      type: "MediaPlayHistory",
      metadata: {
        at: DateTime.fromMillis(state.startedAtUnixEpoch).toISO(),
        player: HistoryMetadataPlayerEnum.WebBrowser,
      },
      mediaId: state.mediaID,
      fileHash: state.fileHash,
      playStartedAt: DateTime.fromMillis(state.startedAtUnixEpoch).toISO(),
      playEndedAt: DateTime.fromMillis(state.lastObservedUnixEpoch).toISO(),
      playbackTotalLength: Duration.fromMillis(playbackMills).toISO(),
      playbackCount: Math.round(playbackMills / state.mediaDurationMills),
    };
  }

  private discardState() {
    if (this.state === null) return;

    this.storage.removeItem(backupKeyOf(this.state));
    this.state = null;
  }

  private finishState() {
    if (this.state === null) return;

    this.enqueue(this.state);
    this.storage.removeItem(backupKeyOf(this.state));
    this.state = null;
  }

  private salvageBackupedStates() {
    const storage = this.storage;
    const states: State[] = [];
    for (let i = 0; true; i++) {
      const key = storage.key(i);
      if (key === null) break;
      if (! key.startsWith(historyStorageKeyPrefix)) continue;

      try {
        const state = JSON.parse(storage.getItem(key) ?? "{}");
        states.push(state);
      } catch(e) {
        console.error("Broken MediaPlayHistoryObserver.State", key, storage.getItem(key), e);
      }
    }
    for(const state of states) {
      if (typeof(state.historyID) !== "string" || typeof(state.accumulatedDurationMills) !== "number") {
        console.error("Broken MediaPlayHistoryObserver.State (missing properties)", state);
        continue;
      }
      if (state.backupAtEpoch === null || this.clock.unixEpochMills - state.backupAtEpoch < backupIntervalMs * backupStaleCount) continue;

      this.enqueue(state);
      storage.removeItem(backupKeyOf(state));
    }
  }

  private enqueue(state: State) {
    const history = this.stateToHistory(state);
    console.log("MediaPlayHistoryObserver.enqueue", state, history);
    if (history !== null) this.history.enqueue(history);
  }

  private startStateBackupWorker() {
    window.setInterval(() => {
      if (!this.state) return;

      this.state.backupAtEpoch = this.clock.unixEpochMills;
      this.storage.setItem(backupKeyOf(this.state), JSON.stringify(this.state));
    }, backupIntervalMs);
  }
}
