import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  ratingContainer: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
  },
}));

export default function RatingStarsEditable({ value, onChange }: {
  value: number,
  onChange: (stars: number) => void | Promise<void>,
}): JSX.Element {
  const [ hover, setHover ] = useState(-1);
  const [ saving, setSaving ] = useState(false);

  const onChangeWrapper = async (_: unknown, rawValue: number | null) => {
    const newValue = (rawValue ?? (value + 1)) - 1;
    if (value === newValue) return;

    setSaving(true);
    try {
      await onChange(newValue);
    } finally {
      setSaving(false);
    }
  };

  const labels: { [value: number]: string } = [ "None", "1/5", "2/5", "3/5", "4/5", "5/5" ];
  const classes = useStyles();
  return <div className={classes.ratingContainer}>
    <Rating
      name="size-large"
      value={value + 1}
      onChange={onChangeWrapper}
      size="large"
      disabled={saving}
      max={6}
      IconContainerComponent={(props: { value: number }) => {
        const { value, ...other } = props;
        return <span {...other}>
          {value === 1 ? <NotInterestedIcon /> : <StarIcon />}
        </span>;
      }}
      onChangeActive={(_, newHover) => { setHover(newHover); }}
    />
    <Box ml={2}>{labels[hover !== -1 ? hover - 1 : value]}</Box>
  </div>;
}
