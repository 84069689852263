import React, { useMemo } from "react";
import { Media } from "../../model/Media";
import { DialogProps } from ".";
import { Dialog, DialogContent } from "./Dialog";

export type MediaRawInfoDialogProps = DialogProps & {
  media: Media,
};

export default function MediaRawInfoDialog(): JSX.Element {
  return <Dialog
    id="MediaRawInfoDialog"
    content={function Content(props: MediaRawInfoDialogProps): JSX.Element {
      const { media } = props;
      const jsonStr = useMemo(() => JSON.stringify(media, null, 2), [ media ]);
      return <DialogContent
        {...props}
        title={<>{media.id} "{media.attributes.name}"</>}
        saveButtonLabel="Copy to clipboard"
        onSave={async () => {
          if (media) await navigator.clipboard.writeText(jsonStr);
        }}
      >
        <code><pre>{jsonStr}</pre></code>
      </DialogContent>
    }}
  />;
}
