import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MediaFileType } from '../model/Media';
import { Badge, IconButton } from '@material-ui/core';
import ListComponent from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mobileSyncFlagOf, isSmartMediaList, ListChildren, ListFolder, MediaList, MediaListWithItems, rootNodeID } from "../model/List";
import { useApp } from '..';
import MediaListContextMenu from "./contextmenu/MediaListContextMenu";
import { isContextMenuOpen, useContextMenuState } from './contextmenu';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import LabelIcon from '@material-ui/icons/Label';
import useListPinning from "../hooks/useListPinning";
import FilterListIcon from '@material-ui/icons/FilterList';
import { isPinnableList } from '../model/ListPinning';

const useStyles = makeStyles((theme) => ({
  listComponent: {
    "@media (pointer: fine)": {
      "& .show-on-hover": { visibility: "hidden" },
      "& .MuiListItem-container:hover .show-on-hover": { visibility: "visible" },
    },
  },
  folderChildren: {
    paddingLeft: theme.spacing(4),
  },
  mobileSyncIcon: {
    marginLeft: "0.3em",
    fontSize: "1.15rem",
    verticalAlign: "bottom",
  },
  listPinningIcon: {
    marginRight: "0.5rem",
  },
  emptyFolderMark: {
    color: theme.palette.text.secondary,
    marginLeft: "1.15rem",

    "&::before": {
      content: '"("',
    },
    "&::after": {
      content: '")"',
    },
  },
}));

export type ListTreeProps = {
  types: ReadonlyArray<MediaFileType>,

  /** Default: false */
  showRootFolder?: boolean,
  /** Do not show leaf element (media lists), default: false */
  folderOnly?: boolean,

  isSelected?: (node: ListChildren[number]) => boolean,
  onFolderClick?: (folder: ListFolder) => void,
  onSelect?: (mediaList: MediaListWithItems) => void,
};

export const ListTree = React.memo((props: ListTreeProps): JSX.Element => {
  const { showRootFolder } = props;
  const { list } = useApp();

  const [ root, setRoot ] = useState<ListFolder | null>(null);
  useEffect(() => {
    (async () => {
      const root = await list.get(rootNodeID);
      setRoot(root as ListFolder);
    })();
  }, [ list ]);

  const classes = useStyles();
  if (!root) return <ListItem>
    <CircularProgress /> Loading root node...
  </ListItem>;
  const rootUI = <ListItemListFolder {...props} node={root} expandByDefault={true} />;
  return (showRootFolder === true) ? <ListComponent dense component="div" disablePadding className={`${classes.listComponent}`}>
    {rootUI}
  </ListComponent> : rootUI;
});

/** Renders a ListFolder and it's children. */
const ListItemListFolder = React.memo((props: ListTreeProps & {
  node: ListChildren[number],
  expandByDefault: boolean,
}): JSX.Element => {
  const { node, expandByDefault, showRootFolder, folderOnly } = props;
  const { list } = useApp();
  const [ expanded, setExpanded ] = useState(expandByDefault);
  const [ fetchChildren, setFetchChildren ] = useState(expandByDefault);
  const [ children, setChildren ] = useState<ListChildren | null>(null);
  const [ treeVersion, setTreeVersion ] = useState(list.modificationVersion);
  useEffect(() => {
    if (fetchChildren) (async () => {
      // Intentionally create new array to re-render.
      setChildren([...await list.getChildren(node.id)].filter(
        (node) => node.disabled !== true && (folderOnly ? node.type === "folder" : true)
      ));
    })();
    return () => {};
  }, [ list, fetchChildren, setChildren, setExpanded, treeVersion, node.id, folderOnly ]);
  useEffect(() => {
    const h = list.addListener("tree-modified", () => setTreeVersion(list.modificationVersion));
    return () => h.remove();
  }, [ list, setTreeVersion ]);

  const classes = useStyles();
  return <>
    {node.id !== rootNodeID || showRootFolder === true ? <ListItemListTreeNode
      {...props}
      node={node} expanded={expanded}
      childrenCount={children === null ? undefined : children.length}
      notCollapsible={node.id === rootNodeID}
      onExpand={(expand) => {
        if(expand) {
          if (!fetchChildren) setFetchChildren(true);
          setExpanded(true);
        } else {
          setExpanded(false);
        }
      }}
    /> : null}
    {!expanded ? null
      : <ListComponent dense component="div" disablePadding className={`${classes.listComponent} ${node.id !== rootNodeID || showRootFolder ? classes.folderChildren : ""}`}>
        {children !== null
         ? <ListItems {...props} children={children} />
         : <ListItem><CircularProgress /> Loading...</ListItem>}
      </ListComponent>
    }
  </>;
});

/** Renders list of ListTreeNode-es. */
function ListItems(props: ListTreeProps & {
  children: ListChildren,
}): JSX.Element {
  const { children, types } = props;
  return <>{sortNodes(children).filter((child) =>
    (child.type === "folder") ? true
    : types.indexOf(child.mediaType) !== -1
  ).map((child) =>
    (child.type === "folder") ? <ListItemListFolder {...props} key={child.id} node={child} expandByDefault={false} />
    : <ListItemListTreeNode {...props} key={child.id} node={child} />
  )}</>;
}

/** Renders a ListTreeNode (without children). */
function ListItemListTreeNode(props: ListTreeProps & {
  node: ListChildren[number],
  expanded?: boolean,

  childrenCount?: number,
  notCollapsible?: boolean,
  onExpand?: (expand: boolean) => void,
}): JSX.Element {
  const { node, expanded, childrenCount, folderOnly, notCollapsible, isSelected, onSelect, onExpand, onFolderClick } = props;
  const { list } = useApp();
  const [ loading, setLoading ] = useState(list.isNodeLoading(node.id));
  useEffect(() => {
    if (!node) return undefined;
    const handles = [
      list.addListenerOnNode(node.id, "loading", () => { setLoading(true) }),
      list.addListenerOnNode(node.id, "loaded", () => { setLoading(false) }),
    ];
    return () => handles.forEach((h) => h.remove());
  }, [ list, setLoading, node ]);

  const isPinned = useListPinning(node.type !== "folder" && isPinnableList(node) ? node : null);

  const toggleExpand = () => {
    if (expanded && notCollapsible) return;
    if (onExpand) onExpand(!expanded);
  };

  const onClick = () => {
    if (!node || node.type === "folder") {
      toggleExpand();
      if (onFolderClick) onFolderClick(node);
    } else {
      list.get(node.id).then((loadedNode) => onSelect ? onSelect(loadedNode as MediaList) : void(0));
    }
  };
  const [ contextMenuProps, openContextMenu, onContextMenu ] = useContextMenuState();
  const classes = useStyles();
  return <ListItem button onClick={onClick} selected={isSelected ? isSelected(node) : false}>
    {node ? <ListItemIcon onContextMenu={onContextMenu}>
      {node.type === "folder" ? (expanded ? <FolderOpenIcon /> : <FolderIcon />)
      : (isSmartMediaList(node)) ? <FilterListIcon />
      : <Badge badgeContent={node.itemsCount} max={999} color="default"><ListAltIcon /></Badge>
      }
    </ListItemIcon> : null}
    <ListItemText
      onContextMenu={onContextMenu}
      primary={<>
        {isPinned ? <LabelIcon color="primary" fontSize="inherit" alignmentBaseline="baseline" className={classes.listPinningIcon} /> : null}
        {node.id === rootNodeID ? "/" : (node.name ?? "( no name )")}
        {mobileSyncFlagOf(node) ? <PhoneAndroidIcon className={classes.mobileSyncIcon} color="disabled" /> : null}
      </>}
    />
    {loading ? <CircularProgress size={20} /> : <ListItemSecondaryAction>
      {childrenCount === 0 ? <span className={classes.emptyFolderMark}>{folderOnly ? "No subfolder" : "Empty folder"}</span> : null}
      {<>
        <IconButton edge="end" className="show-on-hover" onClick={openContextMenu}>
          <MoreVertIcon />
        </IconButton>
        {isContextMenuOpen(contextMenuProps) ? <MediaListContextMenu node={node} {...contextMenuProps} /> : null}
      </>}
      {node.type === "folder" && childrenCount !== 0
        ? <IconButton edge="end" onClick={toggleExpand} disabled={expanded && notCollapsible === true}>{
          (expanded) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />
        }</IconButton>
        : null
      }
    </ListItemSecondaryAction>}
  </ListItem>;
}

const listNodeTypeSorter = {
  "folder": 0,
  "smart-media-list": 1,
  "smart-media-list-with-items": 1,
  "media-list": 2,
  "media-list-without-items": 2,
} as const;
function sortNodes(nodes: ListChildren): ListChildren {
  return [...nodes].sort((a, b) => {
    const tA = listNodeTypeSorter[a.type];
    const tB = listNodeTypeSorter[b.type];
    if (tA !== tB) return tA - tB;
    return (a.name ?? "").localeCompare(b.name ?? "");
  });
}
