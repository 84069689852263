import { Media, imageOrientationOf } from "../model/Media";
import { ImageOrientation } from "../model/FileInfo";

export default function useImageTransform(m: Media | null): string | undefined {
  if (! m) return undefined;

  const orient = imageOrientationOf(m);
  switch(orient) {
    case ImageOrientation.Aligned: return undefined;
    case ImageOrientation.Unknown: return undefined;

    case ImageOrientation.FlipX: return "rotateY(180deg)";
    case ImageOrientation.Rotate180: return "rotate(-180deg)";
    case ImageOrientation.FlipY: return "rotateX(180deg)";
    case ImageOrientation.Rotate90ThenFlipX: return "rotate(-90deg) rotateY(180deg)";
    case ImageOrientation.Rotate90: return "rotate(-90deg)";
    case ImageOrientation.Rotate270ThenFlipX: return "rotate(-270deg) rotateY(180deg)";
    case ImageOrientation.Rotate270: return "rotate(-270deg)";
  }
}
