import { EventEmitter, EventSubscription } from 'fbemitter';
import { Media, MediaFileType } from './Media';
import API from "./api";
import { MediaList as RawMediaList, ListFolder as RawListFolder, MediaListWithoutItems as RawMediaListWithoutItems, SmartMediaList as RawSmartMediaList, SmartMediaListWithItems as RawSmartMediaListWithItems, ListGetResult } from "./api/generated/api-client";
import MediaModel from "./Media";
import ModelNotification from "./ModelNotification";
import { isSearchQueryParseError, parseQueryString, SearchQuery, SearchRequestSort } from './Search';
import { v4 as uuidv4 } from "uuid";

export type ListTreeNode = ListFolder | MediaList | SmartMediaListWithItems;
export type ListFolder = RawListFolder & {
  type: "folder",
  ephemeralId?: never,
};
export type MediaList = RawMediaList & {
  type: "media-list",
  mediaType: MediaFileType,
  ephemeralId?: never,
  items: Media[],
};
export type MediaListWithoutItems = Omit<MediaList, "items" | "type"> & {
  type: "media-list-without-items",
  itemsCount: RawMediaListWithoutItems["itemsCount"],
};

export type SmartMediaListWithoutItems = RawSmartMediaList & {
  type: "smart-media-list",
  mediaType: MediaFileType,
  itemsCount?: never, // Smart list does not provide count because it generate list on-demand / for each request.
};
export type SmartMediaListWithItems = RawSmartMediaListWithItems & Omit<SmartMediaListWithoutItems, "type"> & {
  type: "smart-media-list-with-items",
  ephemeralId?: never,
  items: Media[],
};

export type ListChildren = (ListFolder | MediaListWithoutItems | SmartMediaListWithoutItems)[];

function listNodeUpcast(raw: RawMediaList | RawListFolder | RawSmartMediaList): ListTreeNode {
  return raw as any;
}

export type TemporaryMediaList = {
  id?: never, // Do not have "id". Some implementation assume "id" should be persisted on server.
  ephemeralId: string,
  mediaTypes: MediaFileType[],

  name: string,
  query?: string,
  items: Media[],
};

/** MediaList-like object that has "items" property. */
export type MediaListWithItems = MediaList | SmartMediaListWithItems | TemporaryMediaList;
/** MediaList or MediaListWithoutItems, capable to add/remove media into it. */
export type EditableMediaList = MediaList | MediaListWithoutItems; // Smart media list is not editable (cannot change items manually).
export type SmartMediaList = SmartMediaListWithoutItems | SmartMediaListWithItems;
export type AnyMediaList = MediaList | MediaListWithoutItems | SmartMediaListWithoutItems | SmartMediaListWithItems | TemporaryMediaList;
export const isMediaList = (list: AnyMediaList | ListTreeNode): list is MediaList => (list as MediaList).type === "media-list";
export const isMediaListWithItems = (list: AnyMediaList | ListTreeNode): list is MediaListWithItems => isMediaList(list) || (list as SmartMediaListWithItems).type === "smart-media-list-with-items" || isTemporaryMediaList(list);
export const isMediaListWithoutItems = (list: AnyMediaList | ListTreeNode): list is MediaListWithoutItems => (list as MediaListWithoutItems).type === "media-list-without-items";
export const isTemporaryMediaList = (list: AnyMediaList | ListTreeNode): list is TemporaryMediaList => typeof((list as TemporaryMediaList).ephemeralId) === "string";
export const isEditableMediaList = (list: AnyMediaList | ListTreeNode): list is EditableMediaList => isMediaList(list) || isMediaListWithoutItems(list);
export const isSmartMediaList = (list: AnyMediaList | ListTreeNode): list is SmartMediaList => (list as SmartMediaListWithoutItems).type === "smart-media-list" || (list as SmartMediaListWithItems).type === "smart-media-list-with-items";

export const isSameList = (a: MediaListWithItems, b: MediaListWithItems) =>
  a.id === b.id
  && a.ephemeralId === b.ephemeralId
  && a.items.length === b.items.length
  && a.items.filter((item, index) => item.id !== b.items[index].id).length === 0;

export const mobileSyncFlagOf = (node: AnyMediaList | ListTreeNode): boolean | null => (isMediaList(node) || isMediaListWithoutItems(node) || isSmartMediaList(node)) ? node.mobileSync : null;
export const mediaTypesOf = (list: AnyMediaList): MediaFileType[] => isTemporaryMediaList(list) ? list.mediaTypes : [ list.mediaType ];

export const rootNodeID = "";

type NodeCacheElement = {
  node: ListTreeNode;
  children: ListChildren;
};

export default class ListModel {
  private api: API;
  private media: MediaModel;
  private modelNotification: ModelNotification;
  constructor(deps: { api: API, media: MediaModel, modelNotification: ModelNotification }) {
    this.api = deps.api;
    this.media = deps.media;
    this.modelNotification = deps.modelNotification;
  }

  private emitter = new EventEmitter();
  private _modificationVersion = 0;
  get modificationVersion() { return this._modificationVersion; }
  private incrementVersion() {
    this._modificationVersion++;
    this.emitter.emit("tree-modified");
  }

  private nodeCache: {[id: string]: NodeCacheElement} = {};
  private nodeEmitter: {[id: string]: EventEmitter} = {};
  private nodeState: {
    [id: string]: { loading?: boolean },
  } = {};

  addListener(t: "tree-modified", f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }
  addListenerOnNode(id: string, t: "loading" | "loaded", f: Function): EventSubscription {
    return this.emitterOfNode(id).addListener(t, f);
  }
  private emitterOfNode(id: string): EventEmitter {
    return this.nodeEmitter[id] = this.nodeEmitter[id] ?? new EventEmitter();
  }

  isNodeLoading(id: string) { return Boolean(this.nodeStateOf(id).loading); }

  async getItemsOf(list: AnyMediaList): Promise<MediaListWithItems> {
    if (isMediaListWithItems(list)) return list;
    const fetched = await this.get(list.id);
    if (fetched.type === "folder") throw new Error(`Assumed ${list.id} to be MediaList-like object, but got ${fetched.type}`);
    return fetched;
  }

  async get(id: string, forceReload?: boolean): Promise<ListTreeNode> {
    return (await this.getNode(id, forceReload)).node;
  }

  async getChildren(id: string): Promise<ListChildren> {
    return (await this.getNode(id)).children;
  }

  private nodeLoader: {[id: string]: Promise<ListGetResult>} = {};

  private async getNode(id: string, forceReload?: boolean): Promise<NodeCacheElement> {
    if (this.nodeCache[id] && forceReload !== true) return this.nodeCache[id];

    this.nodeStateOf(id).loading = true;
    this.emitterOfNode(id).emit("loading");
    try {
      // Prevent concurrent request runs on the same node.
      const loader = this.nodeLoader[id] = (forceReload ? undefined : this.nodeLoader[id]) || (async () =>
        (id === "") ? (await this.api.mediaListApi.getRoot()).data : (await this.api.mediaListApi.getNode({ id })).data
      )();
      const result = await loader;
      const node = listNodeUpcast(result.node);
      if (node.type === "media-list" || node.type === "smart-media-list-with-items") {
        node.items.forEach((m, index) => {
          this.media.set(m as Media); // Update Media cache.
          this.media.addListenerOnMedia(m.id, "changed", async () => {
            node.items[index] = await this.media.get(m.id) ?? node.items[index];
          });
        });
      }
      const cacheElement = this.nodeCache[id] = {
        node,
        children: [...(result.subLists ?? [])] as ListChildren,
      };
      this.emitterOfNode(id).emit("loaded", cacheElement.node);
      return cacheElement;
    } finally {
      this.nodeStateOf(id).loading = false;
    }
  }
  private nodeStateOf(id: string) {
    return this.nodeState[id] = this.nodeState[id] || {};
  }

  async createFolder({ parent, name }: {
    parent: ListFolder,
    name: string,
  }) {
    const id = this.generateNewListId();
    await this.api.mediaListApi.createFolder({
      id,
      name,
      parentID: parent.id,
    })
    this.invalidateCacheOf(id, parent.id);
    this.incrementVersion();
    this.modelNotification.emit("MediaList.created", id);
  }

  async createPlaylist({ parent, mediaType, name, items }: {
    parent: ListFolder,
    mediaType: MediaFileType,
    name: string,
    items: Media[],
  }): Promise<void> {
    const id = this.generateNewListId();
    await this.api.mediaListApi.createMediaList({
      id,
      createMediaListRequestBody: {
        mediaType: mediaType as any,
        name,
        parentID: parent.id,
        items: items.map((media) => media.id),
      },
    });
    this.invalidateCacheOf(id, parent.id);
    this.incrementVersion();
    this.modelNotification.emit("MediaList.created", id);
  }

  async createSmartPlaylist({ parent, name, query, limit, sort }: {
    parent: ListFolder,
    name: string,
    query: SearchQuery,
    limit: number,
    sort: SearchRequestSort,
  }): Promise<void> {
    const q = parseQueryString(query.query);
    if (isSearchQueryParseError(q)) throw new Error(`Invalid search query (${q.searchQueryParseError}): ${query.query}`);
    if (query.types.length !== 1) throw new Error(`To create smart list, must specify exactly one media type but given: ${query.types}`);

    const id = this.generateNewListId();
    await this.api.mediaListApi.createSmartMediaList({
      id,
      createSmartMediaListRequestBody: {
        parentID: parent.id,
        mediaType: query.types[0] as any,
        name,
        query: {
          criteria: q,
          limit,
          sort: sort as any,
        },
      },
    });
    this.invalidateCacheOf(id, parent.id);
    this.incrementVersion();
    this.modelNotification.emit("MediaList.created", id);
  }

  async changeSmartPlaylistQuery(id: string, { query, limit, sort }: {
    query: SearchQuery,
    limit: number,
    sort: SearchRequestSort,
  }) {
    const q = parseQueryString(query.query);
    if (isSearchQueryParseError(q)) throw new Error(`Invalid search query (${q.searchQueryParseError}): ${query.query}`);

    await this.api.mediaListApi.changeQuery({
      id,
      searchQuery: {
        criteria: q,
        limit,
        sort: sort as any,
      },
    });
    this.invalidateCacheOf(id);
    this.incrementVersion();
    this.modelNotification.emit("MediaList.criteria-changed", id);
  }

  private generateNewListId(): string {
    return `list-${uuidv4().replaceAll("-", "")}`;
  }

  async rename(id: string, name: string): Promise<void> {
    await this.api.mediaListApi.renameList({ id, name });
    this.invalidateCacheOf(id);
    this.incrementVersion();
    this.modelNotification.emit("MediaList.renamed", id);
  }

  async disable(id: string): Promise<void> {
    await this.api.mediaListApi.disableList({ id });
    this.invalidateCacheOf(id);
    this.incrementVersion();
    this.modelNotification.emit("MediaList.deleted", id);
  }

  async setMobileSync(id: string, mobileSync: boolean): Promise<void> {
    await this.api.mediaListApi.setMobileSync({ id, mobileSync });
    this.invalidateCacheOf(id);
    this.incrementVersion();
    this.modelNotification.emit("MediaList.mobile-sync-set", id);
  }

  async changeParent(node: ListTreeNode | ListChildren[number], parent: ListFolder) {
    await this.api.mediaListApi.changeListParent({ id: node.id, parentID: parent.id });
    this.invalidateCacheOf(node.id); // Invalidate node and it's cached (= old) parent
    this.invalidateCacheOf(parent.id, null); // Invalidate new parent
    this.incrementVersion();
    this.modelNotification.emit("MediaList.moved", node.id, parent.id);
  }

  async addMediaToList(list: EditableMediaList, media: Media, index?: number) {
    await this.api.mediaListApi.insertMediaToList({
      id: list.id,
      mediaId: media.id,
      index,
    });
    this.invalidateCacheOf(list.id);
    this.incrementVersion();
    // List is not refreshed at this moment, thus cannot pass list instance itself.
    this.modelNotification.emit("MediaList.media-added", list.id, media);
  }

  async removeMediaFromList(list: EditableMediaList, media: Media, index: number) {
    await this.api.mediaListApi.removeMediaFromList({
      id: list.id,
      mediaId: media.id,
      index,
    });
    this.invalidateCacheOf(list.id);
    this.incrementVersion();
    // List is not refreshed at this moment, thus cannot pass list instance itself.
    this.modelNotification.emit("MediaList.media-removed", list.id, media);
  }

  /**
   * @param parentID null to not invalidate parent. string to specify parent node ID. Otherwise find parents from cache.
   */
  private invalidateCacheOf(id: string, parentId?: string | null) {
    delete this.nodeCache[id];
    delete this.nodeLoader[id];

    const parentIDs = [];
    if (parentId === null) {
      // Do nothing.
    } else if (parentId) {
      parentIDs.push(parentId);
    } else {
      for (const [ nodeID, node ] of Object.entries(this.nodeCache)) {
        if (node.children.find((child) => child.id === id)) {
          parentIDs.push(nodeID);
        }
      }
    }
    for (const parentID of parentIDs) {
      delete this.nodeCache[parentID];
      delete this.nodeLoader[parentID];
    }
  }
};
