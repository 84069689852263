import { useEffect } from "react";
import { useApp } from "../../..";
import { useAppSnackbar } from "./AppSnackbar";

export default function ModelNotificationSnackbar() {
  const { modelNotification, list: List } = useApp();
  const [ snackbar, setAlert ] = useAppSnackbar();
  useEffect(() => {
    const handles = [
      modelNotification.addListener("Media.media-attributes-changed", async (media, keys) => {
        setAlert({ color: "success", message: `Updated ${keys} of "${media.attributes.name}"` });
      }),

      modelNotification.addListener("MediaList.created", async (listID) => {
        const list = await List.get(listID);
        const typeName = {
          "folder": "folder",
          "media-list": "list",
          "smart-media-list-with-items": "smart list",
        }[list.type] || list.type;
        setAlert({ color: "success", message: `Created ${typeName} "${list.name}"` });
      }),
      modelNotification.addListener("MediaList.criteria-changed", async (listID) => {
        const list = await List.get(listID);
        setAlert({ color: "success", message: `Updated criteria of smart list "${list.name}"` });
      }),
      modelNotification.addListener("MediaList.renamed", async (listID) => {
        const list = await List.get(listID);
        setAlert({ color: "success", message: `Renamed list "${list.name}"` });
      }),
      modelNotification.addListener("MediaList.deleted", async () => {
        setAlert({ color: "success", message: `Deleted list.` });
      }),
      modelNotification.addListener("MediaList.mobile-sync-set", async (listID) => {
        const list = await List.get(listID);
        setAlert({ color: "success", message: `Mobile sync updated on list "${list.name}"` });
      }),
      modelNotification.addListener("MediaList.moved", async (listID, parentID) => {
        const list = await List.get(listID);
        const parent = await List.get(parentID);
        setAlert({ color: "success", message: `Moved list "${list.name}" under "${parent.name}"` });
      }),
      modelNotification.addListener("MediaList.media-added", async (listID, media) => {
        const list = await List.get(listID);
        setAlert({ color: "success", message: `Added "${media.attributes.name}" to the list "${list.name}"` });
      }),
      modelNotification.addListener("MediaList.media-removed", async (listID, media) => {
        const list = await List.get(listID);
        setAlert({ color: "success", message: `Removed "${media.attributes.name}" from the list "${list.name}"` });
      }),
    ];
    return () => handles.forEach((h) => h.remove());
  }, [ setAlert, modelNotification, List ]);
  return snackbar;
}
