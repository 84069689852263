import { EventSubscription, EventEmitter } from "fbemitter";
import { Media } from "../../../Media";
import { MediaPlayerEventType } from "./";
import Storage from "../../../Storage";
import { defaultPlayerSettings, PlayerSettings } from "../../PlayerSettings";
import Device from "../../../Device";
import { Duration } from "luxon";

const eventTypeMapping: { [ t in MediaPlayerEventType ]?: readonly string[] } = {
  "pause-resume": [ "pause", "play" ],
  "end-of-media": [ "ended" ],
  "current-time-changed": [ "timeupdate" ],
  "duration-changed": [ "durationchange" ],
  "settings-changed": [ "mute-changed", "volumechange" ],
} as const;

type MediaPlayState = "loading" | "can-play" | "unable-to-play";

export type HTMLMediaSource = { hash: string, mimeType: string, shouldValidUntil: Duration };

export type HTMLMediaElementPlayerDeps = { storage: Storage, device: Device };

/** Wrapper around https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement */
export default abstract class HTMLMediaElementPlayer<T extends HTMLMediaElement> {
  protected readonly storage: Storage;
  protected readonly device: Device;
  constructor(deps: HTMLMediaElementPlayerDeps) {
    this.storage = deps.storage;
    this.device = deps.device;
  }
  shutdown() {
    this.shutdownElement();
  }

  protected emitter = new EventEmitter();
  addListener(t: MediaPlayerEventType, f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  protected _element: T | null = null;
  protected abstract readonly elementName: string;
  get htmlElement() { return this._element; }
  private initElement(): T {
    if (this._element === null) {
      this._element = document.createElement(this.elementName) as T;
      this.setEventHandlers();
      this.syncSettings();
      this.emitter.emit("html-element-changed");
      console.debug(`<${this.elementName}> element created`, this._element);
    }
    return this._element;
  }
  private shutdownElement() {
    if (this._element === null) return;

    this._element.pause();
    this._element.src = "about:blank";
    this._element = null;
    this.emitter.emit("html-element-changed");
  }

  private setEventHandlers() {
    const element = this._element!!;
    for(const [destType, srcTypes] of Object.entries(eventTypeMapping)) {
      if (srcTypes) for(const srcType of srcTypes){
        element.addEventListener(srcType, () => {
          this.emitter.emit(destType);
        });
      }
    }
    for (const canPlayEvent of [ "canplaythrough", "canplay" ]) {
      element.addEventListener(canPlayEvent, () => {
        console.debug(`Got <${this.elementName}> '${canPlayEvent}' event.`);
        this.setMediaPlayState("can-play");
      });
    }
  }

  private _mediaPlayState: MediaPlayState | null = null;
  get mediaPlayable(): boolean { return this._mediaPlayState === "can-play"; }
  private setMediaPlayState(state: MediaPlayState) {
    if (this._mediaPlayState === state) return;
    this._mediaPlayState = state;

    this.emitter.emit("media-playable-changed");
    if (this.mediaPlayable && !this._paused) this._element?.play();
  }

  private _media: Media | null = null;
  get media() { return this._media; }
  set media(m: Media | null) {
    if (this._media?.id === m?.id) return;

    this._media = m;
    if (m === null) {
      this.setFileHash(null);
    } else {
      this.setFileHash(this.chooseFile(m));
      if (this.fileHash === null) this.emitter.emit("media-not-playable");
    }
  }

  private _source: HTMLMediaSource | null = null;
  get fileHash(): string | null { return this._source?.hash ?? null; }
  private setFileHash(source: HTMLMediaSource | null) {
    if (this._source?.hash === source?.hash) return;
    this._source = source;
    this.emitter.emit("file-changed");

    if (source === null) {
      this.setElementSource(null);
      this.setMediaPlayState("unable-to-play");
    } else {
      this.storage.getDownloadUrl(source.hash, source.shouldValidUntil).then(({ url }) => {
        if (this._source?.hash !== source.hash) return;
        this.setElementSource({
          url,
          mimeType: source.mimeType,
        });
        this.setMediaPlayState("loading");
      }, (e) => {
        console.error("Failed to get file URL", e);
        this.emitter.emit("media-not-playable");
        this.setMediaPlayState("unable-to-play");
      });
    }
  }
  private setElementSource(src: { url: string, mimeType: string } | null) {
    const element = this.initElement();
    [...element.children].filter((c) => c.tagName.toLowerCase() === "source").forEach((c) => element.removeChild(c));
    if (src !== null) {
      const c = document.createElement("source");
      c.src = src.url;
      c.type = src.mimeType;
      element.appendChild(c);
    }
    element.load(); // load() re-choose files from <source> elements.
  }

  /** @return FileHash */
  protected abstract chooseFile(m: Media): HTMLMediaSource | null;
  isCompatible(m: Media): boolean { return this.chooseFile(m) !== null; }


  private _paused = false;
  get paused(): boolean { return this._paused; }
  set paused(value: boolean) {
    this._paused = value;
    if (value) {
      this.initElement().pause();
    } else {
      if (this.mediaPlayable) {
        this.initElement().play();
      }
    }
  }

  /** Implementation should ignore duplicate call. */
  prefetch(m: Media): void {
    // TODO: Implement !
  }

  get durationSec() { return this._element?.duration ?? null; }
  get currentTimeSec() { return this._element?.currentTime ?? null; }
  set currentTimeSec(value: number | null) {
    if (value === null || !this._element) return;
    this._element.currentTime = value;
    this.emitter.emit("seek-started");
  }

  private _settings: PlayerSettings = { ...defaultPlayerSettings };
  get settings(): PlayerSettings { return { ...this._settings }; }
  set settings(settings: PlayerSettings) {
    this._settings = { ...settings };
    this.syncSettings();
  }
  private syncSettings() {
    const element = this._element;
    if (!element) return;
    element.muted = this._settings.mute;
    element.volume = this._settings.volume;
  }
}
