import { EventEmitter, EventSubscription } from 'fbemitter';
import { DateTime } from 'luxon';
import API from "./api";
import { ModelFileSpecialFileNameKindEnum } from "./api/generated/api-client";
import { Attributes, toRawAttributes } from "./Attributes";

export type SpefialFileNameKind = ModelFileSpecialFileNameKindEnum;
export const specialFileNameKinds: SpefialFileNameKind[] = [ ModelFileSpecialFileNameKindEnum.GameMovie ];

export type UploadAttrs = {
  confidential: boolean,
  specialFileNameKind?: SpefialFileNameKind,
  createdAt?: DateTime,
  modifiedAt?: DateTime,
  attributes: Attributes,
};

type UploadFile = UploadAttrs & {
  name: string,
  bytes: number,
} & ({ status: "uploading", file: File } | UploadFileSucceeded | UploadFileFailed);
type UploadFileSucceeded = { status: "uploaded", contentType: string, fileHash: string, file: undefined };
type UploadFileFailed = { status: "failed", error: any, file: File };

type QueueElement = Omit<UploadFile, "status"> & {
  status: "waiting",
  file: File,
};

export type Upload = QueueElement | UploadFile;

export default class Uploader {
  private api: API;
  constructor(deps: { api: API }) {
    this.api = deps.api;
  }

  private emitter = new EventEmitter();

  addListener(t: "uploaded", f: (file: UploadFile & UploadFileSucceeded) => void): EventSubscription;
  addListener(t: "upload-failed", f: (file: UploadFile & UploadFileFailed) => void): EventSubscription;
  addListener(t: "uploads-changed", f: Function): EventSubscription;
  addListener(t: "uploaded" | "upload-failed" | "uploads-changed", f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  private ended: UploadFile[] = [];
  private current: UploadFile | null = null;
  private queue: QueueElement[] = [];
  get uploads(): Upload[] { return [ ...this.ended, ...(this.current ? [ this.current ] : []), ...this.queue ]; }

  retryFailed() {
    this.ended
      .filter((it) => it.status === "failed")
      .map((it): QueueElement => ({ ...(it as UploadFile & UploadFileFailed), status: "waiting" }))
      .forEach((it) => this.queue.push(it));
    this.ended = this.ended.filter((it) => it.status !== "failed");
    this.startUploader();
    this.emitter.emit("uploads-changed");
  }

  enqueue(file: File, attrs: UploadAttrs) {
    this.queue.push({
      status: "waiting",
      name: file.name,
      bytes: file.size,

      file,
      ...attrs,
    });
    this.startUploader();
    this.emitter.emit("uploads-changed");
  }

  private uploaderRunning = false;
  private startUploader() {
    window.setTimeout(() => this.uploader(), 1);
  }
  private async uploader() {
    if (this.uploaderRunning) return;
    this.uploaderRunning = true;
    try {
      let next: QueueElement | undefined = undefined;
      // eslint-disable-next-line no-cond-assign
      while(next = this.queue.pop()) {
        this.current = { ...next, status: "uploading" };
        this.emitter.emit("uploads-changed");
        try {
          await this.upload(this.current, next);
        } finally {
          this.ended.push(this.current);
          this.current = null;
        }
      }
    } finally {
      this.uploaderRunning = false;
    }
  }
  private async upload(current: UploadFile, file: Readonly<QueueElement>): Promise<void> {
    try {
      const { hash, contentType } = (await this.api.mediaUploadApi.post({
        file: file.file,
        fileName: file.name,
        confidential: file.confidential,
        specialFileNameKind: file.specialFileNameKind,
        createdAt: file.createdAt?.toISO(),
        modifiedAt: file.modifiedAt?.toISO(),
        attributesJson: JSON.stringify(toRawAttributes(file.attributes)),
      })).data as { hash: string, contentType: string };

      current.status = "uploaded";
      (current as UploadFileSucceeded).fileHash = hash;
      (current as UploadFileSucceeded).contentType = contentType;
      delete (current as UploadFileSucceeded)["file"];
      this.emitter.emit("uploaded", this.current);
    } catch(e) {
      current.status = "failed";
      (current as UploadFileFailed).error = e;
      this.emitter.emit("upload-failed", this.current);
    } finally {
      this.emitter.emit("uploads-changed");
    }
  }
};
