import { ReactNode, useMemo } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {useMediaQuery} from '@react-hook/media-query';

export const ThemeSwitcher = ({ children }: {
  children?: ReactNode,
}) => {
  const darkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => createMuiTheme({
    palette: {
      type: darkMode ? "dark" : "light",
    },
  }), [darkMode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
