import React, { useState } from "react";
import { Button, CircularProgress, Dialog as MuiDialog, DialogActions, DialogContent as MuiDialogContent, DialogTitle } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { DialogProps, DialogsProps, DialogID, useDialogProps } from ".";

export type DialogContentProps = {
  fullScreen?: boolean,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  className?: string,

  title: React.ReactNode,
  children: React.ReactNode,

  buttons?: React.ReactNode,
  /**
   * If passed, shows "Save" button.
   * No need to call "onClose()" in the callback, dialog implementation automatically close.
   */
  onSave?: () => void | Promise<void>,
  saveButtonLabel?: React.ReactNode,
  disableSave?: boolean,
};

export function Dialog<T extends DialogID>(props: {
  id: T,
  /** Called only when dialog open. Supply/render the dialog. */
  content: (props: DialogsProps[T]) => JSX.Element,
}): JSX.Element {
  // Caveats: When dialog is not open, this object only contains DialogProps properties.
  // Do NOT pass this object to content because content expects full props object that inherits DialogProps but not DialogProps itself.
  const dialogProps = useDialogProps(props.id);

  if (!dialogProps.open) return <></>;
  // Render component only when dialog is open.
  // This improve UI performance even if content() function uses heavy computation.
  return <props.content {...dialogProps} />;
}

export function DialogContent({ open, onClose, fullScreen, maxWidth, className, title, children, buttons, onSave, saveButtonLabel, disableSave }: DialogProps & DialogContentProps): JSX.Element {
  const isCancel = !!onSave; // Show "Cancel" button instead of "Close"

  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState<any>(null);

  const save = async () => {
    if (onSave) {
      setLoading(true);
      try {
        await onSave();
      } catch(e) {
        console.error(e);
        setError(e);
      } finally {
        setLoading(false);
      }
      onClose();
    } else {
      onClose();
    }
  };
  return <MuiDialog
    fullScreen = {fullScreen}
    maxWidth = {maxWidth}
    fullWidth = {maxWidth ? true : false}
    open = {open}
    onClose={onClose}
  >
    <DialogTitle>{title}</DialogTitle>
    <MuiDialogContent className = {className}>
      {error ? <Alert severity="error">Error: {`${error}`}</Alert> : null}
      {children}
    </MuiDialogContent>
    <DialogActions>
      {buttons}
      {isCancel ? <Button onClick={() => onClose()} variant="contained" color="default" disabled={loading}>
        Cancel
      </Button> : <Button onClick={() => onClose()} variant="contained" color="primary" disabled={loading}>
        Close
      </Button>}
      {onSave ? <Button onClick={save} variant="contained" color="primary" disabled={loading || disableSave}>
        {loading ? <CircularProgress /> : saveButtonLabel ?? "Save"}
      </Button> : null}
    </DialogActions>
  </MuiDialog>;
}
