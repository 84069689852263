import { Design, inject, injectClass } from "typesafe-di";
import API, { ApiDeps } from "./api";
import { playerDesign, PlayerObjects } from "./player";
import Clock from "./Clock";
import CurrentWindow from "./CurrentWindow";
import Firebase from "./Firebase";
import History from "./History";
import Historia from "./Historia";
import List from "./List";
import Search, { SearchStateless } from "./Search";
import Session from "./Session";
import Storage from "./Storage";
import Uploader from "./Uploader";
import Device from "./Device";
import MediaModel from "./Media";
import ModelNotification from "./ModelNotification";
import ListPinning from "./ListPinning";
import buildConstants, { BuildConstants } from "./BuildConstants";
import Sentry from "./Sentry";

export type Models = PlayerObjects & {
  api: API,
  buildConstants: BuildConstants,
  clock: Clock,
  device: Device,
  firebase: Firebase,
  history: History,
  historia: Historia,
  list: List,
  listPinning: ListPinning,
  media: MediaModel,
  modelNotification: ModelNotification,
  search: Search,
  searchStateless: SearchStateless, // search.ts
  sentry: Sentry,
  session: Session,
  storage: Storage,
  uploader: Uploader,
};

export const modelDesign = Design.empty
  .merge(playerDesign)
  .bind("api", inject((params: ApiDeps & {
    sentry: Sentry,  // Initialize after Sentry to make sure error reporting.
  }) => new API(params), [ "buildConstants", "session", "sentry" ]))
  .bind("buildConstants", () => buildConstants)
  .bind("clock", injectClass(Clock, []))
  .bind("currentWindow", injectClass(CurrentWindow, []))
  .bind("device", injectClass(Device, []))
  .bind("firebase", injectClass(Firebase, []))
  .bind("history", injectClass(History, [ "session", "api" ]))
  .bind("historia", injectClass(Historia, [ "api" ]))
  .bind("list", injectClass(List, [ "api", "media", "modelNotification" ]))
  .bind("listPinning", injectClass(ListPinning, [ "list" ]))
  .bind("media", injectClass(MediaModel, [ "api", "modelNotification" ]))
  .bind("modelNotification", injectClass(ModelNotification, []))
  .bind("search", injectClass(Search, [ "api" ]))
  .bind("searchStateless", injectClass(SearchStateless, [ "api" ]))
  .bind("sentry", injectClass(Sentry, [ "buildConstants" ]))
  .bind("session", injectClass(Session, [ "firebase" ]))
  .bind("storage", injectClass(Storage, [ "clock", "api" ]))
  .bind("uploader", injectClass(Uploader, [ "api" ]))
;
