import React, { useState } from "react";
import prettyBytes from 'pretty-bytes';
import { Typography, makeStyles, Button, FormGroup, FormControlLabel, Checkbox, Select, InputLabel, FormControl, MenuItem, Divider } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import NoEncryptionOutlinedIcon from '@material-ui/icons/NoEncryptionOutlined';
import { specialFileNameKinds, SpefialFileNameKind } from "../../../../model/Uploader";
import AttributesEditor from "../../../../components/AttributesEditor";
import { Attribute, Attributes, attributesDefault } from "../../../../model/Attributes";
import Uploader from "../../../../model/Uploader";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  navigationButtons: {
    textAlign: "right",
    "& button": {
      marginLeft: "10px",
    },
  },
  overline: {
    textAlign: "right",
  },
  filename: {
    flex: "1 1 auto",
    fontSize: theme.typography.pxToRem(20),
  },
  fileAdditionalInfo: {
    flex: "0 0 fit-content",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default React.memo(({ files, onCancel, onUpload }: {
  files: File[],
  onCancel: () => void,
  onUpload: (uploads: Parameters<InstanceType<typeof Uploader>["enqueue"]>[]) => void,
}): JSX.Element => {
  const [ confidential, setConfidential ] = useState<boolean | null>(null);
  const [ specialFileNameKind, setSpecialFileNameKind ] = useState<SpefialFileNameKind | null>(null);

  const validationErrors: string[] = [];
  if (confidential === null) validationErrors.push("Must select confidential / non-confidential.");

  const [ attributeOverrides, setAttributeOverrides ] = useState<Attributes>({ ...attributesDefault });
  const [ attributes, setAttributes ] = useState<Attributes[]>(files.map(() => ({ ...attributesDefault }))); // Note: must create searate object for each file.
  if (files.length !== attributes.length) setAttributes(files.map(() => ({ ...attributesDefault })));

  const startUpload = () => {
    if (validationErrors.length > 0) {
      alert(JSON.stringify(validationErrors));
      return;
    }
    onUpload(files.map((file, index): Parameters<InstanceType<typeof Uploader>["enqueue"]> => {
      return [ file, {
        confidential: confidential!!,
        specialFileNameKind: specialFileNameKind ?? undefined,
        modifiedAt: DateTime.fromMillis(file.lastModified),
        attributes: {
          ...attributes[index],
          ...attributeOverrides,
        },
      } ];
    }));
    setSpecialFileNameKind(null);
    setAttributeOverrides({ ...attributesDefault });
    setAttributes(files.map(() => ({ ...attributesDefault })));
  };

  const classes = useStyles();
  const navigationButtons = (key: string) => <div key={key} className={classes.navigationButtons}>
    <Button variant="contained" onClick={onCancel} startIcon={<ArrowBackIcon />}>Back</Button>
    <Button variant="contained" disabled={validationErrors.length !== 0} onClick={startUpload} startIcon={<CloudUploadIcon />} color="primary">Upload</Button>
  </div>;
  const validationAlerts = (key: string) => validationErrors.map((msg, index) => <Alert key={`${key}-${index}`} severity="warning">{msg}</Alert>);
  const overline = (key: string) => <div key={key} className={classes.overline}>
    <Typography variant="overline">{files.length} files, {prettyBytes(totalSizeOf(files))}</Typography>
  </div>;
  return <div>
    {navigationButtons("navigationButtons-top")}
    {validationAlerts("validationAlerts-top")}
    <div>
      <StagedFile
        key="all-files"
        files={files}
        file={null}
        confidential={confidential}
        setConfidential={setConfidential}
        specialFileNameKind={specialFileNameKind}
        setSpecialFileNameKind={setSpecialFileNameKind}
        attributes={attributeOverrides}
        setAttributes={(attrs) => {
          console.log("setAttributeOverrides", attrs);
          setAttributeOverrides(attrs);
        }}
      />
      {[...files].map((file, index) => <StagedFile
        key={index}
        files={files}
        file={file}
        confidential={confidential}
        setConfidential={undefined}
        specialFileNameKind={specialFileNameKind}
        setSpecialFileNameKind={undefined}
        attributes={attributes[index]}
        attributeOverrides={attributeOverrides}
        setAttributes={(attrs) => {
          const list = [...attributes];
          list[index] = { ...attrs };
          setAttributes(list);
        }}
      />)}
    </div>
    {(files.length < 10) ? null : overline("overline-bottom")}
    {(files.length < 10) ? null : validationAlerts("validationAlerts-bottom")}
    {(files.length < 10) ? null : navigationButtons("navigationButtons-bottom")}
  </div>;
});

const useStagedFileStyle = makeStyles((theme) => ({
  filename: {
    flex: "1 1 auto",
    fontSize: theme.typography.pxToRem(20),
  },
  fileAdditionalInfo: {
    flex: "0 0 fit-content",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionDetailBody: {
    flex: "1 1 auto",
  },
  specialSettings: {
    display: "flex",
    marginBottom: "20px",
  },
  specialFileNameKindForm: {
    marginLeft: "30px",
    minWidth: "20em",
  },
  attributesEditor: {
    width: "100%",
  },
}));

/**
 * Those attributes should not be set on "All Files".
 */
const attributesMaskOnAllFiles: Attribute[] = [ "name", "lyrics" ];

const StagedFile = React.memo(({ files, file, confidential, setConfidential, specialFileNameKind, setSpecialFileNameKind, attributes, attributeOverrides, setAttributes }: {
  files: File[],
  file: File | null,
  confidential: boolean | null,
  setConfidential?: (confidential: boolean) => void,
  specialFileNameKind: SpefialFileNameKind | null,
  setSpecialFileNameKind?: (value: SpefialFileNameKind | null) => void,
  attributes: Attributes,
  attributeOverrides?: Attributes,
  setAttributes: (attrs: Attributes) => void,
}): JSX.Element => {
 const classes = useStagedFileStyle();
  return <Accordion defaultExpanded={!file}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <div className={classes.filename}>
        {(confidential === null) ? <NoEncryptionOutlinedIcon /> : confidential ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon /> }
        {file ? <><FileCopyOutlinedIcon /> {file.name}</> : <><FileCopyIcon /> All Files</>}
      </div>
      <div className={classes.fileAdditionalInfo}>
        {file ? <>{file.type} {prettyBytes(file.size)}</> : <>{files.length} files, {prettyBytes(totalSizeOf(files))}</>}
      </div>
    </AccordionSummary>
    <AccordionDetails>
      <div className={classes.accordionDetailBody}>
        <div className={classes.specialSettings}>
          {!setConfidential ? null : <FormGroup row>
            <FormControlLabel
              control={<Checkbox
                checked={confidential ?? false}
                indeterminate={confidential === null}
                onChange={(e) => { setConfidential(e.target.checked) }}
              />}
              label="Confidential"
            />
          </FormGroup>}
          {!setSpecialFileNameKind ? <>{specialFileNameKind}</> : <FormControl className={classes.specialFileNameKindForm}>
            <InputLabel>Special File Name Kind</InputLabel>
            <Select
              value={(specialFileNameKind === null) ? -1 : specialFileNameKinds.indexOf(specialFileNameKind)}
              onChange={(event) => setSpecialFileNameKind((event.target.value === -1) ? null : specialFileNameKinds[event.target.value as any])}
            >
              <MenuItem id="#none" value={-1}><em>None</em></MenuItem>
              {specialFileNameKinds.map((kind, index) => <MenuItem key={index} id={`${index}`} value={index}>{`${kind}`}</MenuItem>)}
            </Select>
          </FormControl>}
        </div>
        <Divider />
        <div>
          <Typography variant="h6">Attributes</Typography>
          <AttributesEditor
            className={classes.attributesEditor}
            attrs={{
              ...attributes,
              ...(attributeOverrides ?? {}),
            }}
            setAttrs={setAttributes}
            mask={(file === null) ? attributesMaskOnAllFiles : []}
            fixed={
              Object.fromEntries(Object.entries(attributeOverrides ?? {})
                .filter(([, value]) => typeof(value) !== "undefined")
                .map(([attr]) => [ attr, `Overridden by "All Files".` ]))
            }
          />
        </div>
      </div>
    </AccordionDetails>
  </Accordion>;
});

const totalSizeOf = (files: File[]) => files.reduce((sum, file) => sum + file.size, 0);
