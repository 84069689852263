import { Design, inject } from "typesafe-di";
import Player, { PlayerDeps } from "./Player";
import { playerInternalDesign } from "./internal";

export { Player };

export type PlayerObjects = {
  // Only public objects listed.
  player: Player,
};
export const playerDesign = Design.empty
  .merge(playerInternalDesign)
  .bind("player", inject((deps: PlayerDeps) => new Player(deps), [ "storage", "playlistController", "device" ]), async (player) => player.shutdown())
;
