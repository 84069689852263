import { v4 as uuidv4 } from "uuid";

const windowIdSymbol = Symbol("smss-current-window-id");

export default class CurrentWindow {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(deps: {}) {}

  get id(): string {
    const w = (window as any);
    return w[windowIdSymbol] = w[windowIdSymbol] || `window-id-${uuidv4().replace("-", "")}`;
  }
}
