import { IconButton } from "@material-ui/core";
import SkipNextIcon from '@material-ui/icons/SkipNext';
import useCurrentMediaList from "../hooks/useCurrentMediaList";
import { useApp } from "..";

export default function PlayingNextMediaButtion({small}: {
  small?: boolean,
}): JSX.Element {
  const { player } = useApp();
  const list = useCurrentMediaList();
  if (!list || list.items.length <= 1) return <></>;
  return <IconButton size={small ? "small" : "medium"} color="inherit" onClick={() => player.nextTrack()}>
    <SkipNextIcon />
  </IconButton>;
};
