import { AppBar, IconButton, makeStyles, Toolbar } from "@material-ui/core";
import { AppState, AppUIMode, AppUIProps, toolbarHeight, uiStylesBase } from "..";
import MenuButton from "../MenuButton";
import { useCallback, useState } from "react";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoGrid from "./PhotoViewer";
import CriteriaEditbox from "./CriteriaEditbox";
import CalendarNavigator from "./CalendarNavigator";
import { SearchQuery } from "../../../model/Search";
import { dayOfWeekLabelOf } from "../../../lib/dayOfWeek";
import { Interval } from "luxon";

export type ImageCriteria = {
  chronological: ImageCriteriaChronological,
  geological: ImageCriteriaGeological,
};

export type ImageCriteriaChronological = {
  /**
   * year, month (optional), dayOfMonth (optional).
   *
   * Month and dayOfMonth is 1-start (not 0-start month).
   */
  dateRange?: [ number ] | [ number, number ] | [ number, number, number ],
};
export type ImageCriteriaGeological = {
  geohash?: string,
};

export function toSearchQuery(criteria: ImageCriteria): SearchQuery {
  return {
    confidential: true,
    types: [ "image", "video" ],
    query: [
      ...toSearchQueryChrono(criteria.chronological),
      ...toSearchQueryGeo(criteria.geological),
    ].filter((it) => !!it).join(" "),
  };
}

function toSearchQueryChrono({ dateRange }: ImageCriteriaChronological): (string | null)[] {
  return [
    dateRange ? `date:"${dateRangeMinMax(dateRange).join("..")}"` : null,
  ];
}

function toSearchQueryGeo({ geohash }: ImageCriteriaGeological): (string | null)[] {
  return [
    geohash ? `geohash:${geohash}` : null,
  ];
}

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.mixins.toolbar,
    height: `calc(100vh - ${toolbarHeight})`,
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      display: "none",
    },

    display: "flex",
    flexDirection: "column",
  },

  //
  // --- Top level components ---
  //

  /** 1st row */
  facetBrowser: {
    flex: "1 1 500px",
    display: "flex",
    minHeight: "100px", // Required, overflown content will scroll vertically.
  },
  /** 2nd row */
  criteriaBar: {
    flex: "0 0 auto",
    display: "flex",
  },
  /** 3rd row */
  photoList: {
    flex: "6 3 300px",
    display: "flex",
  },

  //
  // --- Facet browser (1st row) ---
  //
  calendar: {
    flex: "1 1 300px",
    overflowY: "scroll",
    minHeight: "0px",
  },
  map: {
    flex: "1 1 300px",
  },

  //
  // --- Facet list (2nd row) ---
  //
  criteriaEditboxContainer: {
    flex: "1 1 auto",
  },
  criteriaBarExpandButtonSection: { // Child of mapCriteria
    flex: "0 0 auto",
  },

  //
  // --- Photo list (3rd row) ---
  //

  photoGrid: {
    flex: "1 1 300px",
  },
  photoInfo: {
    flex: "0 0 calc(min(300px, 25vw))",
  },
}));

const initialCriteria: ImageCriteria = {
  chronological: {},
  geological: {},
};

export default function ImageUI({ showDialog, appState, setMode }: AppUIProps & {
  appState: AppState,
  setMode: (mode: AppUIMode) => void,
}): JSX.Element {
  const { mode } = appState;

  const [ criteriaStack, setCriteriaStack ] = useState<ImageCriteria[]>([ initialCriteria ]);
  const criteria = criteriaStack[criteriaStack.length - 1];
  const criteriaUndoAvailable = criteriaStack.length >= 2;
  const changeCriteria = useCallback((criteria: ImageCriteria) => {
    setCriteriaStack([ ...criteriaStack, criteria ]);
  }, [ criteriaStack, setCriteriaStack ]);
  const undoCriteria = useCallback(() => {
    if (! criteriaUndoAvailable) return;
    setCriteriaStack(criteriaStack.slice(0, -1));
  }, [ criteriaStack, setCriteriaStack, criteriaUndoAvailable ]);

  const [ showFacetBrowser, setShowFacetBrowser ] = useState(true);

  const classes = { ...uiStylesBase(), ...useStyles() };
  return <div>
    <AppBar position="sticky">
      <Toolbar>
        <div className={classes.toolBarButtons}>
          <MenuButton mode={mode} onSelected={setMode} showDialog={showDialog} />
        </div>
      </Toolbar>
    </AppBar>
    <div className={classes.root}>
      <div className={classes.facetBrowser} style={{ display: showFacetBrowser ? undefined : "none" }}>
        <div className={classes.calendar}>
          <CalendarNavigator
            criteria={criteria}
            onChange={changeCriteria}
          />
        </div>
        <div className={classes.map}>
          Map
        </div>
      </div>
      <div className={classes.criteriaBar}>
        <div className={classes.criteriaEditboxContainer}>
          <CriteriaEditbox
            criteria={criteria}
            onChange={changeCriteria}
            onUndo={criteriaUndoAvailable ? undoCriteria : null}
          />
        </div>
        <div className={classes.criteriaBarExpandButtonSection}>
          <IconButton onClick={() => setShowFacetBrowser(!showFacetBrowser)}>
            {showFacetBrowser ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      <div className={classes.photoList}>
        <div className={classes.photoGrid}>
          <PhotoGrid
            criteria={criteria}
          />
        </div>
      </div>
    </div>
  </div>;
}

export function dateRangeToString(dateRange: Exclude<ImageCriteriaChronological["dateRange"], undefined>): string {
  let rangeStr = dateRange.map((part, index) => index >= 1 ? `${part}`.padStart(2, "0") : `${part}`).join("/");
  if (dateRange.length === 3) rangeStr += ` (${dayOfWeekLabelOf(...dateRange)})`;
  return rangeStr;
}

export function dateRangeInterval(dateRange: Exclude<ImageCriteriaChronological["dateRange"], undefined>): Interval {
  const [ min, max ] = dateRangeMinMax(dateRange);
  return Interval.fromISO(`${min}/${max}`);
}

function dateRangeMinMax(dateRange: Exclude<ImageCriteriaChronological["dateRange"], undefined>): [ string, string ] {
  const d2 = (n: number) => `${n}`.padStart(2, "0");
  const offset = timezoneOffsetOf(dateRange);
  switch(dateRange.length) {
    case 1: {
      const [ year ] = dateRange;
      return [
        `${year}-01-01T00:00:00${offset}`,
        `${year}-12-31T23:59:59.999${offset}`,
      ];
    }
    case 2: {
      const [ year, month ] = dateRange;
      const maxDays = new Date(year, month - 1 + 1, 0).getDate();
      return [
        `${year}-${d2(month)}-01T00:00:00${offset}`,
        `${year}-${d2(month)}-${d2(maxDays)}T23:59:59.999${offset}`,
      ];
    }
    case 3: {
      const [ year, month, date ] = dateRange;
      return [
        `${year}-${d2(month)}-${d2(date)}T00:00:00${offset}`,
        `${year}-${d2(month)}-${d2(date)}T23:59:59.999${offset}`,
      ];
    }
  }
}

function timezoneOffsetOf(dateRange: Exclude<ImageCriteriaChronological["dateRange"], undefined>): string {
  let d: Date;
  switch(dateRange.length) {
    case 1: d = new Date(dateRange[0], 0, 1); break;
    case 2: d = new Date(dateRange[0], dateRange[1] - 1, 1); break;
    case 3: d = new Date(dateRange[0], dateRange[1] - 1, dateRange[2]); break;
  }

  let offset = d.getTimezoneOffset();
  const sign = (offset < 0) ? "+" : "-"; // Caveats: Sign of ISO and JS are opposite.
  offset = (offset < 0) ? -offset : offset;
  return sign + `${Math.floor(offset / 60)}`.padStart(2, "0") + ":" + `${(offset % 60)}`.padStart(2, "0");
}
