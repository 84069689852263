import { Chip, IconButton, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { dateRangeToString, ImageCriteria } from ".";
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import UndoIcon from '@material-ui/icons/Undo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: "row",
    height: "100%",
  },

  chipArrayContainer: {
    flex: "1 1 auto",
    height: "100%",
  },
  chipArray: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    height: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },

  buttons: {
    flex: "0 0 auto",
  },
}));

export default React.memo(function({ criteria, onUndo, onChange }: {
  criteria: ImageCriteria,
  onUndo: null | (() => void),
  onChange: (criteria: ImageCriteria) => void,
}): JSX.Element {

  const classes = useStyles();
  const chips = criteriaToChips(classes.chip, onChange, criteria);
  return <div className={classes.root}>
    <div className={classes.chipArrayContainer}>
      <Paper component={chips.length > 0 ? "ul" : undefined} className={classes.chipArray}>
        {chips.map((chip, i) => <li key={i}>{chip}</li>)}
        {chips.length === 0 ? "No filtering criteria specified." : null}
      </Paper>
    </div>
    <div className={classes.buttons}>
      {onUndo ? <IconButton onClick={onUndo}>
        <UndoIcon />
      </IconButton> : null}
    </div>
  </div>;
});

function criteriaToChips(
  className: string,
  onChange: (criteria: ImageCriteria) => void,
  criteria: ImageCriteria
): JSX.Element[] {
  const result: JSX.Element[] = [];
  const { chronological, geological } = criteria;

  const { dateRange } = chronological;
  if (dateRange) result.push(<Chip
    className={className}
    icon={<DateRangeIcon />}
    label={dateRangeToString(dateRange)}
    onDelete={() => onChange({ ...criteria, chronological: { ...chronological, dateRange: undefined } })}
  />);

  const { geohash } = geological;
  if (geohash) result.push(<Chip
    className={className}
    icon={<LocationOnIcon />}
    label={geohash}
    onDelete={() => onChange({ ...criteria, geological: { ...geological, geohash: undefined } })}
  />);

  return result;
}
