import UAParser from "ua-parser-js";
import { AudioCodingFormat, VideoCodingFormat } from "./FileInfo";

export default class Device {
  private readonly ua = new UAParser();

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor, no-empty-pattern
  constructor({}: {}) {}

  get audioFormatPreference(): AudioCodingFormat[] {
    return this.isFastNetwork ? [
      AudioCodingFormat.Flac,
      AudioCodingFormat.Aac,
      AudioCodingFormat.Vorbis,
      AudioCodingFormat.Opus,
      AudioCodingFormat.Mp3,
    ] : [
      AudioCodingFormat.Aac,
      AudioCodingFormat.Vorbis,
      AudioCodingFormat.Opus,
      AudioCodingFormat.Mp3,
    ];
  }

  get videoFormatPreference(): VideoCodingFormat[] {
    return this.isSafari ? [
      VideoCodingFormat.H265,
      VideoCodingFormat.H264,
    ] : [
      VideoCodingFormat.Vp9,
      VideoCodingFormat.H264,
    ];
  }

  private get isFastNetwork(): boolean {
    return !this.isIOS;
  }

  private get isSafari(): boolean {
    switch(this.ua.getBrowser().name?.toLowerCase()) {
      case "safari": return true;
      case "mobile safari": return true;
      default: return false;
    }
  }

  private get isIOS(): boolean {
    return this.ua.getOS().name?.toLowerCase() === "ios";
  }
}
