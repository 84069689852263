import React, { useEffect, useMemo, useState } from "react";
import { SmartMediaList } from "../../model/List";
import { DialogProps } from "./";
import { Dialog, DialogContent } from "./Dialog";
import { CircularProgress, makeStyles, TextField } from "@material-ui/core";
import { parseQueryString, isSearchQueryParseError, SearchRequestSort, queryToString } from "../../model/Search";
import { useApp } from "../..";
import MediaListView from "../MediaListView";
import useSearch from "../../hooks/useSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "75vh",
  },
}));

export type EditSmartMediaListDialogProps = DialogProps & {
  list: SmartMediaList,
};

export default function EditSmartMediaListDialog(): JSX.Element {
  return <Dialog
    id="EditSmartMediaListDialog"
    content={function Content(props: EditSmartMediaListDialogProps) {
      const { list } = props;
      const { list: List } = useApp();

      const { result: preview, setQuery: setPreviewQuery } = useSearch({ isolate: true });

      const [ query, setQuery ] = useState(queryToString(list.searchQuery.criteria));
      const queryParseResult = useMemo(() => parseQueryString(query), [ query ]);
      const queryError = isSearchQueryParseError(queryParseResult) ? queryParseResult.searchQueryParseError : null;
      useEffect(() => {
        setPreviewQuery({
          types: [ list.mediaType ],
          query,
        });
      }, [ list, query, setPreviewQuery ]);

      const [ limit, setLimit ] = useState(`${list.searchQuery.limit ?? 300}`);
      const limitError = (/^[0-9]+$/.test(limit)) ? null : "Limit must be valid number";

      const canSave = !queryError && !limitError;
      const save = async () => {
        if (!canSave) return;

        await List.changeSmartPlaylistQuery(
          list.id,
          {
            query: {
              types: [ list.mediaType ],
              query,
            },
            limit: +limit,
            sort: SearchRequestSort.DateAdded,
          },
        );
      };

      const classes = useStyles();
      return <DialogContent
        {...props}
        maxWidth="xl"
        className={classes.root}
        title={<>Edit smart playlist "{list.name}"</>}
        onSave={save}
        disableSave={!canSave}
      >
        <TextField
          label="Query"
          fullWidth={true}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          helperText={queryError}
          error={!!queryError}
        />

        <TextField
          label="Limit"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
          helperText={limitError}
          error={!!limitError}
        />

        <p>Preview:</p>
        {preview === null ? <CircularProgress /> : <>
          <MediaListView
            list={preview}
            clickToPlay={false}
            secondaryAction="play"
          />
        </>}
      </DialogContent>;
    }}
  />;
}
