import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Switch } from "@material-ui/core";
import { Attributes, Attribute, attributes, AttributeValue, toRawAttribute } from "../model/Attributes";
import AttributeEditor from "../atoms/AttributeEditor";

const useStyles = makeStyles((theme) => ({
  formTable: {
    width: "100%",
    border: "none",
  },
}));

const AttributesEditor = React.memo(({ className, attrs, setAttrs, mask, fixed }: {
  className?: string,
  attrs: Attributes,
  setAttrs: (attrs: Attributes) => void,
  /** Hide those attribute inputs. */
  mask?: Attribute[],
  /** Disable editing on those attributes. Pass string to show reason why fixed. */
  fixed?: { [attr in Attribute]?: string | null },
}): JSX.Element => {
  const setValue = useCallback((attr: Attribute, value: AttributeValue | undefined) => {
    if (toRawAttribute(attrs[attr]) !== toRawAttribute(value)) setAttrs({ ...attrs, [attr]: value });
  }, [ attrs, setAttrs ]);
  const classes = useStyles();
  return <div className={className}>
    <table className={classes.formTable}>
      <tbody>
        {attributes.map((attr) => {
          const isMasked = ((mask?.indexOf(attr) ?? -1) !== -1);
          const isFixed = typeof(fixed?.[attr]) !== "undefined";

          return <AttributeRow
            key={attr}
            hidden={isMasked} // Keep <AttributeRow> element to prevent losing user input state.
            attr={attr}
            value={attrs[attr]}
            setValue={isFixed ? undefined : setValue}
            helperText={isFixed ? fixed?.[attr] ?? undefined : undefined}
          />;
        })}
      </tbody>
    </table>
  </div>;
});
AttributesEditor.displayName = "AttributesEditor";
export default AttributesEditor;

const useRowStyles = makeStyles((theme) => ({
  row: {
    "&.hidden": {
      display: "none",
    },
    "& > th": {
      border: "none",
      textAlign: "right",
      color: theme.palette.text.hint,
    },
    "& > td": {
      border: "none",
    },
  },
  emptizeSwitchColumn: {
    width: "65px",
  },
  valueColumn: {
    width: "100%",
  },
}));

const AttributeRow = React.memo(({ hidden, attr, value, setValue, helperText }: {
  hidden: boolean,
  attr: Attribute,
  value: AttributeValue | undefined,
  setValue?: (attr: Attribute, value: AttributeValue | undefined) => void,
  helperText?: string,
}): JSX.Element => {
  const [ isUndefined, setUndefined ] = useState(typeof(value) === "undefined");
  const [ inputValue, setInputValue ] = useState(value); // Separate state to preserve input value on setUndefined(true).
  useEffect(() => { // Watch params modification
    setUndefined(typeof(value) === "undefined");
    if (typeof(value) !== "undefined") setInputValue(value); // Keep input state if pushed value is undefined.
  }, [value]);

  const [ bubbleTrigger, setBubbleTrigger ] = useState(value); // Debounce
  const updateBubbleTrigger = (isUndefined: boolean, inputValue: AttributeValue | undefined) => setBubbleTrigger(toRawAttribute(isUndefined ? undefined : inputValue));
  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (setValue) setValue(attr, isUndefined ? undefined : inputValue);
    }, 300);
    return () => window.clearTimeout(timer);
  }, [ setValue, bubbleTrigger, attr, isUndefined, inputValue ]);

  const classes = useRowStyles();
  return <tr className={`${classes.row} ${hidden ? "hidden" : ""}`}>
    <th>{attr}</th>
    <td className={classes.emptizeSwitchColumn}>
      <Switch
        checked={!isUndefined}
        disabled={!setValue}
        onChange={(event) => {
          const isUndefinedAfter = !event.target.checked;
          setUndefined(isUndefinedAfter)
          updateBubbleTrigger(isUndefinedAfter, inputValue);
        }}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </td>
    {/* Keep <AttributeEditor> component to preserve UI input state. */}
    <td className={classes.valueColumn}>
      <AttributeEditor
        attr={attr}
        value={inputValue}
        setValue={setValue ? (value) => {
          setInputValue(value);
          setUndefined(typeof(value) === "undefined");
          updateBubbleTrigger(isUndefined, value);
        } : undefined}
        helperText={helperText}
      />
    </td>
  </tr>;
});
