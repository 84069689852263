import { CircularProgress, IconButton, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { dateRangeInterval, dateRangeToString, ImageCriteria, ImageCriteriaChronological, toSearchQuery } from ".";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useApp } from "../../..";
import useHistoriaHistories from "../../../hooks/useHistoriaHistories";

type CalendarNavigatorProps = {
  criteria: ImageCriteria,
  onChange: (criteria: ImageCriteria) => void,
};

export default React.memo(function(props: CalendarNavigatorProps): JSX.Element {
  const [ yearsExpanded, setYearsExpanded ] = useState<{ [year: number]: boolean }>({});
  const filesPerYear = useFilesPerDateRange(props, []);
  const years = Object.keys(filesPerYear ?? {}).map((it) => +it).sort((a, b) => b - a);
  const maxFiles = Object.values(filesPerYear ?? {}).reduce((a, b) => Math.max(a, b), 0);

  if (!filesPerYear) return <><CircularProgress /> Counting images...</>;
  return <List dense>
    {years.filter((year) => filesPerYear[year] >= 1).flatMap((year) => [
      <ListItem key={`${year}-item`} button onClick={() => setDateRange(props, [ year ])}>
        <ListItemTextOf {...props} dateRange={[year]} files={filesPerYear[year]} maxFiles={maxFiles} />
        <IconButton onClick={(e) => {
          e.stopPropagation();
          setYearsExpanded({ ...yearsExpanded, [year]: !yearsExpanded[year] });
        }}>
          { yearsExpanded[year] ? < ExpandLessIcon/> : <ExpandMoreIcon /> }
        </IconButton>
      </ListItem>,
      yearsExpanded[year] ? <Monthes key={`${year}-children`} {...props} year={year} /> : null,
    ])}
  </List>;
});

const useMonthesStyles = makeStyles(theme => ({
  row: {
    paddingLeft: theme.spacing(4),
  },
}));

const Monthes = React.memo(function (props: CalendarNavigatorProps & { year: number }): JSX.Element {
  const { year } = props;

  const filesPerMonth = useFilesPerDateRange(props, [ year ]);
  const monthes = Object.keys(filesPerMonth ?? {}).map((it) => +(it.split("-")[1])).sort((a, b) => b - a);
  const maxFiles = Object.values(filesPerMonth ?? {}).reduce((a, b) => Math.max(a, b), 0);

  const [ monthesExpanded, setMonthesExpanded ] = useState<{ [year: number]: boolean }>({});

  const classes = useMonthesStyles();
  if (!filesPerMonth) return <><CircularProgress /> Counting images...</>;
  return <>{monthes.flatMap((month) => [
    <ListItem key={`${year}-${month}-item`} className={classes.row} button onClick={() => setDateRange(props, [ year, month ])}>
      <ListItemTextOf {...props} dateRange={[year, month]} files={filesPerMonth[`${year}-${month}`]} maxFiles={maxFiles} />
      <IconButton onClick={(e) => {
        e.stopPropagation();
        setMonthesExpanded({ ...monthesExpanded, [month]: !monthesExpanded[month] });
      }}>
        { monthesExpanded[month] ? < ExpandLessIcon/> : <ExpandMoreIcon /> }
      </IconButton>
    </ListItem>,
    monthesExpanded[month] ? <Days key={`${year}-${month}-children`} {...props} year={year} month={month} /> : null,
  ])}</>;
});

const useDaysStyles = makeStyles(theme => ({
  row: {
    paddingLeft: theme.spacing(6),
  },
}));

const Days = React.memo(function (props: CalendarNavigatorProps & { year: number, month: number }): JSX.Element {
  const { year, month } = props;

  const filesPerDay = useFilesPerDateRange(props, [ year, month ]);
  const days = Object.keys(filesPerDay ?? {}).map((it) => +(it.split("-")[2])).sort((a, b) => b - a);
  const maxFiles = Object.values(filesPerDay ?? {}).reduce((a, b) => Math.max(a, b), 0);

  const classes = useDaysStyles();
  if (!filesPerDay) return <><CircularProgress /> Counting images...</>;
  return <>{days.map((day) => <ListItem key={`${year}-${month}-${day}-item`} className={classes.row} button onClick={() => setDateRange(props, [ year, month, day ])}>
    <ListItemTextOf {...props} dateRange={[year, month, day]} files={filesPerDay[`${year}-${month}-${day}`]} maxFiles={maxFiles} />
  </ListItem>)}</>;
});

const useListItemTextStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
  },
  itemsBarChart: {
    display: "inline-block",
    height: "0.75em",
    width: "75px",
    padding: "0",
    margin: "0",
    marginRight: "1em",
    border: `1px solid ${theme.palette.primary.light}`,
  },
  itemsBarChartBar: {
    display: "block",
    height: "100%",
    padding: "0",
    margin: "0",
    background: `${theme.palette.primary.main}`,
  },
  files: {
    marginRight: "1em",
  },
  events: {
    marginRight: "1em",
  },
  event: {
    marginRight: "1em",
  },
}));

function ListItemTextOf(props: CalendarNavigatorProps & { dateRange: Exclude<ImageCriteriaChronological["dateRange"], undefined>, files: number, maxFiles: number }): JSX.Element {
  const { dateRange, files, maxFiles } = props;
  const classes = useListItemTextStyles();
  const histories = useHistoriaHistories(dateRangeInterval(dateRange));
  return <ListItemText
    className={classes.root}
    primary={dateRangeToString(dateRange)}
    secondary={[
      dateRange.length >= 2 ? <span key="itemsBarChart" className={classes.itemsBarChart}>
        <span className={classes.itemsBarChartBar} style={{width: `${Math.round(files / maxFiles * 100)}%`}} />
      </span> : null,
      <span className={classes.files}>{files} files</span>,
      histories ? (
        histories.length === 1 && dateRange.length === 3 ? <span className={classes.event}>{histories[0].content.split("\n")[0]}</span>
        : histories.length >= 1 ? <span className={classes.events}>{histories.length} events</span>
        : null
      ) : <span className={classes.events}>loading events...</span>,
    ]}
  />;
}

function setDateRange(props: CalendarNavigatorProps, dateRange: ImageCriteriaChronological["dateRange"]) {
  const { onChange, criteria } = props;
  onChange({
    ...criteria,
    chronological: {
      ...criteria.chronological,
      dateRange,
    },
  });
}

function useFilesPerDateRange(props: CalendarNavigatorProps, range: [] | [ number ] | [ number, number ]): null | { [key: string]: number } {
  const { criteria } = props;
  const { searchStateless } = useApp();

  const [ result, setResult ] = useState<null | { [key: string]: number }>(null);
  useEffect(() => {
    (async () => {
      setResult(await searchStateless.count(
        (range.length === 0) ? "DateAddedYear" : (range.length === 1) ? "DateAddedMonth" : "DateAddedDay",
        toSearchQuery({
          ...criteria,
          chronological: {
            dateRange: (range.length === 0) ? undefined : range,
          },
        }),
      ));
    })();
  }, [ searchStateless, criteria, range ]);
  return result;
}
