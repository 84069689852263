import React, { useCallback, useContext, useState } from "react";
import CreateFolderDialog, { CreateFolderDialogProps } from "./CreateFolderDialog";
import CreateSmartPlaylistDialog, { CreateSmartPlaylistDialogProps } from "./CreateSmartPlaylistDialog";
import ListMoveDialog, { ListMoveDialogProps } from "./ListMoveDialog";
import MediaListBulkAddDialog, { MediaListBulkAddDialogProps } from "./MediaListBulkAddDialog";
import MediaListRenameDialog, { MediaListRenameDialogProps } from "./MediaListRenameDialog";
import MediaRawInfoDialog, { MediaRawInfoDialogProps } from "./MediaRawInfoDialog";
import EditSmartMediaListDialog, { EditSmartMediaListDialogProps } from "./EditSmartMediaListDialog";

export type DialogProps = {
  open: boolean,
  onClose: () => void,
};
export const dialogPropsInitial: Readonly<DialogProps> = {
  open: false,
  onClose: () => {},
};

// --- Modify below when add dialog ---

export type DialogsProps = {
  CreateFolderDialog: CreateFolderDialogProps,
  CreateSmartPlaylistDialog: CreateSmartPlaylistDialogProps,
  ListMoveDialog: ListMoveDialogProps,
  MediaListBulkAddDialog: MediaListBulkAddDialogProps,
  MediaListRenameDialog: MediaListRenameDialogProps,
  MediaRawInfoDialog: MediaRawInfoDialogProps,
  EditSmartMediaListDialog: EditSmartMediaListDialogProps,
};
const dialogComponents: {
  [dialog in DialogID]: () => JSX.Element;
} = {
  CreateFolderDialog,
  CreateSmartPlaylistDialog,
  ListMoveDialog,
  MediaListBulkAddDialog,
  MediaListRenameDialog,
  MediaRawInfoDialog,
  EditSmartMediaListDialog,
} as const;
const dialogs: Readonly<(DialogID)[]> = [
  "CreateFolderDialog",
  "CreateSmartPlaylistDialog",
  "ListMoveDialog",
  "MediaListBulkAddDialog",
  "MediaListRenameDialog",
  "MediaRawInfoDialog",
  "EditSmartMediaListDialog",
] as const;

// -------------------------------------

export type DialogID = keyof DialogsProps;
type DialogPropsType<T extends DialogsProps[DialogID]> = Omit<T, keyof DialogProps>;

type DialogsOpener = <K extends DialogID>(dialog: K, props: DialogPropsType<DialogsProps[K]>) => void;
type DialogCloser = (dialog: DialogID) => void;

const DialogsOpenerContext = React.createContext<DialogsOpener>(() => {});
const DialogCloserContext = React.createContext<DialogCloser>(() => {});

/** Hook to open/close dialog */
export const useDialog = <K extends DialogID>(dialog: K): [
  /** Function to open dialog. Must supply props to initialize dialog state. */
  (props: DialogPropsType<DialogsProps[K]>) => void,
  /** Function to close dialog. */
  () => void,
] => {
  const opener = useContext(DialogsOpenerContext);
  const closer = useContext(DialogCloserContext);
  return [
    useCallback((props: DialogPropsType<DialogsProps[K]>) => opener(dialog, props), [ dialog, opener ]),
    useCallback(() => closer(dialog), [ closer, dialog ]),
  ];
};

const DialogPropsContext = React.createContext<DialogsProps>(undefined as any);
/** @internal Do not use directly. Only used in implementation of Dialogs. */
export const useDialogProps = <K extends DialogID>(dialog: K): DialogsProps[K] => useContext(DialogPropsContext)[dialog];

/** Mount all Dialog components. Always mount on App root. */
export const Dialogs = React.memo(({ children }: {
  children: React.ReactNode,
}): JSX.Element => {
  const [ props, updater, closer ] = useDialogsProps();

  return <DialogPropsContext.Provider value={props}>
    <DialogsOpenerContext.Provider value={updater}>
      <DialogCloserContext.Provider value={closer}>
        {children}

        {Object.entries(dialogComponents).map(([ dialogID, DialogComponent ]) => <DialogComponent key={`dialog-${dialogID}`} />)}
      </DialogCloserContext.Provider>
    </DialogsOpenerContext.Provider>
  </DialogPropsContext.Provider>;
});

function useDialogsProps(): [DialogsProps, DialogsOpener, DialogCloser] {
  let dialogsProps: DialogsProps;
  let setDialogsProps: (props: DialogsProps) => void;

  const opener: DialogsOpener = (dialog, props) => {
    setDialogsProps({
      ...dialogsProps,
      [dialog]: {
        ...dialogsProps[dialog],
        ...props,
        open: true,
      },
    });
  };

  const closer: DialogCloser = (dialog) => {
    setDialogsProps({
      ...dialogsProps,
      [dialog]: {
        ...dialogPropsInitial,
        open: false,
      } as any, // Clear props on dialog close. Not preserve dialog state for next dialog open.
    });
  };

  const state = useState(Object.fromEntries(dialogs.map((dialog) => [ dialog, {
    // Hack: Store partial props when dialog is not open.
    ...dialogPropsInitial,
    onClose: () => closer("CreateFolderDialog"),
  } as DialogsProps[typeof dialog] ])) as DialogsProps);
  dialogsProps = state[0];
  setDialogsProps = state[1];

  return [
    dialogsProps,
    useCallback(opener, [ dialogsProps, setDialogsProps ]),
    useCallback(closer, [ dialogsProps, setDialogsProps ]),
  ];
}
