import { MediaFileType } from '../model/Media';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import MovieIcon from '@material-ui/icons/Movie';
import PhotoIcon from '@material-ui/icons/Photo';

export default function MediaFileTypeIcon({ type }: { type: MediaFileType }): JSX.Element {
  switch(type) {
    case "audio": return <MusicNoteIcon />;
    case "video": return <MovieIcon />;
    case "image": return <PhotoIcon />;
  }
  throw new Error(`Invalid MediaFileType: ${type}`);
}
