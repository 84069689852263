import { Design, inject } from "typesafe-di";
import MediaPlayHistoryObserver, { MediaPlayHistoryObserverDeps } from "./MediaPlayHistoryObserver";
import PlayerSettingsSync, { PlayerSettingsSyncDeps } from "./PlayerSettingsSync";
import PlaylistController, { PlaylistControllerDeps } from "./PlaylistController";
import MediaSessionObserver, { MediaSessionObserverDeps } from "./MediaSessionObserver";

export const playerInternalDesign = Design
  .bind("mediaPlayHistoryObserver", inject((deps: MediaPlayHistoryObserverDeps) => new MediaPlayHistoryObserver(deps), [ "clock", "player", "history" ]))
  .bind(
    "mediaSessionObserver", inject((deps: Omit<MediaSessionObserverDeps, "mediaSession">) => {
      if (window.navigator.mediaSession) {
        return new MediaSessionObserver({ ...deps, mediaSession: window.navigator.mediaSession });
      } else {
        return null;
      }
    }, [ "player", "storage" ])
  )
  .bind("playerSettingsSync", inject((deps: PlayerSettingsSyncDeps) => new PlayerSettingsSync(deps), [ "clock", "player", "currentWindow" ]), async (obj) => obj.shutdown())
  .bind("playlistController", inject((deps: PlaylistControllerDeps) => new PlaylistController(deps), []))
;
