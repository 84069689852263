import { Media, MediaFileType } from "../../../Media";
import { EventSubscription, EventEmitter } from 'fbemitter';
import { MediaPlayer, MediaPlayerEventType } from ".";
import { defaultPlayerSettings, PlayerSettings } from "../../PlayerSettings";

export class StubMediaPlayer implements MediaPlayer {
  constructor(readonly type: MediaFileType) {}
  shutdown() {}

  private emitter = new EventEmitter();
  addListener(t: MediaPlayerEventType, f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  htmlElement: HTMLElement | null = document.createElement("audio");

  readonly mediaPlayable: boolean = true;
  private _media: Media | null = null;
  get media(): Media | null { return this._media; }
  set media(m: Media | null) {
    if (this._media?.id === m?.id) return; // Unchanged
    this._media = m;
  }

  get fileHash() { return null; }

  private _paused: boolean = false;
  get paused(): boolean { return this._paused; }
  set paused(value: boolean) {
    if (this._paused === value) return; // Unchanged
    this._paused = value;
    this.emitter.emit("pause-resume");
  }

  prefetch(m: Media): void {}

  currentTimeSec: number | null = 30;
  durationSec: number | null = 120;

  private _settings: PlayerSettings = { ...defaultPlayerSettings };
  get settings(): PlayerSettings { return { ...this._settings }; }
  set settings(settings: PlayerSettings) {
    this._settings = { ...settings };
    this.emitter.emit("settings-changed");
  }

  isCompatible(m: Media): boolean { return true; }
}
