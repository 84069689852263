import { useEffect, useState } from 'react';
import { useApp } from '..';
import { isMediaListWithItems, isSameList, ListTreeNode, MediaListWithItems } from '../model/List';

export default function useMediaList(list: string | MediaListWithItems | null): MediaListWithItems | null {
  const id = (typeof(list) === "string") ? list : list?.id;
  const { list: List } = useApp();

  // Those states could have one of following state:
  // Fresh or Loading : freshList is any           && fetchingListVersion === List.modificationVersion
  // Stale            : freshList is MediaListLike && fetchingListVersion !== List.modificationVersion
  // Empty            : freshList == null          && fetchingListVersion === null
  const [ freshList, setFreshList ] = useState(typeof(list) === "string" ? null : list);
  const [ fetchingListVersion, setFetchingListVersion ] = useState(freshList === null ? null : List.modificationVersion);

  // If the "list" parameter itself changed, update this hook's state.
  useEffect(() => {
    if (typeof(list) === "object" && list !== null) {
      if (freshList == null || !isSameList(freshList, list)) {
        setFreshList(list);
        setFetchingListVersion(List.modificationVersion);
      }
    } else if (typeof(id) === "string" && freshList?.id !== id){
      setFreshList(null);
      setFetchingListVersion(null);
    }
  }, [ List, list, id, freshList, setFreshList, setFetchingListVersion ]);

  // Monitor list modification.
  const [ modificationVersion, setModificationVersion ] = useState(List.modificationVersion);
  useEffect(() => {
    const h = List.addListener("tree-modified", () => setModificationVersion(List.modificationVersion));
    return () => h.remove();
  }, [ List, setModificationVersion ]);
  useEffect(() => {
    if (!id) return;
    if (fetchingListVersion === null || fetchingListVersion !== modificationVersion) {
      setFetchingListVersion(modificationVersion);
      (async () => {
        const version = modificationVersion;
        const list = await List.get(id);
        if (isMediaListWithItems(list) && list.id === id && List.modificationVersion === version) setFreshList(list);
      })();
    }
  }, [ List, id, modificationVersion, fetchingListVersion, setFetchingListVersion, setFreshList ]);

  useEffect(() => {
    const h = id ? List.addListenerOnNode(id, "loaded", (list: ListTreeNode) => {
      if (isMediaListWithItems(list) && list.id === id && List.modificationVersion === modificationVersion) setFreshList(list);
    }) : null;
    return () => h?.remove();
  }, [ List, id, modificationVersion ]);

  return freshList;
}
