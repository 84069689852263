import firebase from 'firebase/app';
import '@firebase/auth';
import { Button } from "@material-ui/core";

export default function LoginForm(): JSX.Element {
  const googleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
  };

  return <Button variant="outlined" color="primary" onClick={googleLogin}>Login with Google</Button>;
}
