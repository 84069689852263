import { EventEmitter, EventSubscription } from "fbemitter";
import List, { isMediaList, MediaListWithItems, MediaListWithoutItems, isMediaListWithoutItems, SmartMediaListWithoutItems, AnyMediaList, isSmartMediaList, isMediaListWithItems } from "./List";

export type PinnableList = MediaListWithItems | MediaListWithoutItems | SmartMediaListWithoutItems;
export const isPinnableList = (list: AnyMediaList): list is PinnableList => isMediaListWithItems(list) || isMediaListWithoutItems(list) || isSmartMediaList(list);

export default class ListPinning {
  private list: List;
  constructor(deps: { list: List }){
    this.list = deps.list;

    this.list.addListener("tree-modified", () => this.reloadLists());
  }

  private emitter = new EventEmitter();
  addListener(t: "changed", f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  private pinnedLists: {
    [id: string]: PinnableList,
  } = {};

  private async reloadLists() {
    for(const [ id, list ] of Object.entries(this.pinnedLists)) {
      if (isMediaList(list) || isMediaListWithoutItems(list)) {
        this.pinnedLists[id] = await this.list.get(id) as PinnableList;
      }
    }
    this.emitter.emit("changed");
  }

  get lists(): PinnableList[] {
    return Object.values(this.pinnedLists);
  }

  isPinned(list: PinnableList): boolean {
    return !!this.pinnedLists[idOf(list)];
  }

  pin(list: PinnableList) {
    if (this.isPinned(list)) return;
    this.pinnedLists[idOf(list)] = list;
    this.emitter.emit("changed");
  }

  unpin(list: PinnableList) {
    if (!this.isPinned(list)) return;
    delete this.pinnedLists[idOf(list)];
    this.emitter.emit("changed");
  }
}

const idOf = (list: PinnableList) => list.id ?? list.ephemeralId;
