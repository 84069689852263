import { useEffect } from "react";
import prettyBytes from 'pretty-bytes';
import { useApp } from "../../..";
import { useAppSnackbar } from "./AppSnackbar";

export default function UploadSnackbar() {
  const { uploader } = useApp();
  const [ snackbar, setAlert ] = useAppSnackbar();
  useEffect(() => {
    const handles = [
      uploader.addListener("uploaded", (upload) => {
        setAlert({ color: "success", message: `Uploaded "${upload.name}" (${prettyBytes(upload.bytes)})` });
      }),
      uploader.addListener("upload-failed", (upload) => {
        setAlert({ color: "error", message: `Failed to upload "${upload.name}" (${prettyBytes(upload.bytes)}): ${upload.error}` });
      }),
    ];
    return () => handles.forEach((h) => h.remove());
  }, [ setAlert, uploader ]);
  return snackbar;
}
