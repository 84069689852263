import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { isSearchQueryParseError, SearchQuery, SearchQueryParseError } from "../model/Search";
import { useApp } from '..';
import { useEffect, useState } from 'react';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInputRoot: {
    color: 'inherit',
  },
  searchInputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  tooltip: {
    maxWidth: 'calc(100vw - 40px)',
  },
}));

export default function SearchInput({queryTemplate}: {
  queryTemplate: Omit<SearchQuery, "query">,
}): JSX.Element {
  const { search } = useApp();
  const classes = useStyles();
  const [ showTooltip, setShowTooltip ] = useState(false);
  const [ queryError, setQueryError ] = useState<SearchQueryParseError | null>(null);
  const [ queryString, setQueryString ] = useState(search.queryOriginalString);
  useEffect(() => {
    const h = search.addListener("query-changed", () => {
      setQueryString(search.queryOriginalString);
    });
    return () => h.remove();
  }, [ search ]);

  return <>
    <Tooltip
      open={showTooltip || queryError !== null}
      arrow
      classes={ { tooltip: classes.tooltip } }
      title={(queryError !== null) ? <><p>Query Error: ${queryError.searchQueryParseError}</p><SearchInputTooltip /></> : ""}
    >
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.searchInputRoot,
            input: classes.searchInputInput,
          }}
          fullWidth
          value={queryString ?? ""}
          onChange={(event) => {
            const text = event.target.value;
            setQueryString(text);
            if (text === "") {
              search.clearSearch();
            } else {
              const err = search.search({
                ...queryTemplate,
                query: text,
              });
              setQueryError(isSearchQueryParseError(err) ? err : null);
            }
          }}
          onFocus={() => setShowTooltip(true)}
          onBlur={() => setShowTooltip(false)}
        />
        {queryString ? <div className={classes.clearIcon}>
          <IconButton onClick={() => search.clearSearch()}>
            <ClearIcon />
          </IconButton>
        </div> : null}
      </div>
    </Tooltip>
  </>;
}

function SearchInputTooltip(): JSX.Element {
  return <>
    <p>ex) <code>Keyword Search rating:4..5 artist:"Artist Name"</code></p>

    Query Syntax:
    <ul>
      <li>Query := Criteria (<code>[ ]+</code> Criteria)+</li>
      <li>Criteria := KeywordCriteria | AttributeCriteria | RatingCriteria | HiddenCriteria | FileHashCriteria | GeoHashCriteria | DateAddedCriteria | DateAddedInCriteria | MediaIdCriteria</li>
      <li>KeywordCriteria := String</li>
      <li>AttributeCriteria := String<code>:</code>String</li>
      <li>RatingCriteria := <code>rating:</code>Int<code>..</code>Int</li>
      <li>HiddenCriteria := <code>hidden:true|false</code></li>
      <li>FileHashCriteria := <code>hash:[0-9a-f]+</code></li>
      <li>GeoHashCriteria := <code>geohash:[0-9a-z]+</code></li>
      <li>DateAddedCriteria := <code>date:</code>ISODateTime<code>..</code>ISODateTime</li>
      <li>DateAddedInCriteria := <code>in:[0-9]+</code></li>
      <li>MediaIdCriteria := <code>id:media-[0-9a-f]+</code></li>
      <li>String := <code>[^ :""]+</code> | <code>"([^"]|\")+"</code></li>
    </ul>
  </>;
}
