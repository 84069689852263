import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { DateTime } from "luxon";
import { Attribute, AttributeValue, getAttributeDummyValue, attributeIsBoolean, attributeIsDateTime, attributeIsNumber, attributeIsString, movieTypes, MovieType } from "../model/Attributes";

export type AttributeEditorProps<T extends AttributeValue> = {
  className?: string,
  attr: Attribute,
  value: T | undefined,
  /** If not given, disable editing. */
  setValue?: (value: T | undefined) => void,
  helperText?: string,
};

export default function AttributeEditor(props: AttributeEditorProps<AttributeValue>): JSX.Element {
  const { attr } = props;
  const dummy = getAttributeDummyValue(attr); // value could be null thus detect type from dummy value.
  if (attr === "movieType") return <MovieTypeEditor {...(props as AttributeEditorProps<MovieType>)} />;
  if (attributeIsString(dummy)) {
    return <StringEditor multiline={attr === "lyrics" || attr === "comment"} {...(props as AttributeEditorProps<string>)} />;
  }
  if (attributeIsBoolean(dummy)) return <BooleanEditor {...(props as AttributeEditorProps<boolean>)} />;
  if (attributeIsDateTime(dummy)) return <DateTimeEditor {...(props as AttributeEditorProps<DateTime>)} />;
  if (attributeIsNumber(dummy)) return <NumberEditor {...(props as AttributeEditorProps<number>)} />;
  throw new Error(`Attribute "${attr}" is not supported (dummy value = ${dummy})`);
};

const MovieTypeEditor = React.memo(({ className, attr, value, setValue, helperText }: AttributeEditorProps<MovieType>): JSX.Element => {
  return <FormControl className={className} fullWidth>
    <InputLabel shrink>{attr}</InputLabel>
    <Select
      value={value ?? ""}
      disabled={!setValue}
      onChange={(event) => {
        const raw = event.target.value as MovieType | "";
        if (setValue) setValue(raw === "" ? undefined : raw);
      }}
      fullWidth
    >
      <MenuItem value=""><em>None</em></MenuItem>
      {movieTypes.map((value) => <MenuItem key={value} value={value}>{value}</MenuItem>)}
    </Select>
    {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
  </FormControl>;
});

const StringEditor = React.memo(({ className, attr, value, setValue, helperText, multiline }: AttributeEditorProps<string> & { multiline: boolean }): JSX.Element => {
  return <TextField
    className={className}
    label={attr}
    value={value}
    disabled={!setValue}
    onChange={(event) => setValue ? setValue(event.target.value) : null}
    helperText={helperText}
    InputProps={{
      readOnly: (!setValue),
    }}
    multiline={multiline}
    fullWidth
  />;
});

const BooleanEditor = React.memo(({ className, attr, value, setValue, helperText }: AttributeEditorProps<boolean>): JSX.Element => {
  return <></>;
});

const DateTimeEditor = React.memo(({ className, attr, value, setValue, helperText }: AttributeEditorProps<DateTime>): JSX.Element => {
  return <></>;
});

const NumberEditor = React.memo(({ className, attr, value, setValue, helperText }: AttributeEditorProps<number>): JSX.Element => {
  return <></>;
});
