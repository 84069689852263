import React, { useState } from "react";
import { ListFolder } from "../../model/List";
import { Dialog, DialogContent } from "./Dialog";
import { ListTree } from "../ListTree";
import { mediaFileTypes } from "../../model/Media";
import { TextField } from "@material-ui/core";
import { useApp } from "../..";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { DialogProps } from ".";

export type CreateFolderDialogProps = DialogProps & {
  initialParentFolder: ListFolder | null,
  initialName: string,
};

export default function CreateFolderDialog(): JSX.Element {
  return <Dialog
    id="CreateFolderDialog"
    content={function Content(props: CreateFolderDialogProps) {
      const { list: List } = useApp();
      const [ name, setName ] = useState(props.initialName);
      const [ parentFolder, setParentFolder ] = useState(props.initialParentFolder);

      return <DialogContent
        {...props}
        maxWidth="lg"
        title={<><CreateNewFolderIcon fontSize="inherit" /> Create new folder</>}
        disableSave={parentFolder === null}
        onSave={async () => await List.createFolder({ name, parent: parentFolder! })}
      >
        <TextField
          label="Folder name"
          fullWidth={true}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        Select parent:
        <ListTree
          showRootFolder={true}
          folderOnly={true}
          isSelected={(node) => node.id === parentFolder?.id}
          onFolderClick={setParentFolder}
          types={mediaFileTypes}
        />
      </DialogContent>;
    }}
  />;
}

