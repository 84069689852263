import { useEffect, useState } from "react";
import { useApp } from "..";
import LoginForm from "./LoginForm";

export const LoginGate = ({ content }: {
  content: () => JSX.Element,
}): JSX.Element => {
  const { session } = useApp();
  const [login, setLogin] = useState(session.sessionAvailable);
  useEffect(() => {
    const handle = session.addListener("changed", () => {
      setLogin(session.sessionAvailable);
    });
    return () => handle.remove();
  }, [ session, setLogin ]);

  if (!login) {
    return <>
      <LoginForm />
    </>
  }
  return <>
    {content()}
  </>
};
