import React, { useEffect, useState } from "react";
import { Button, makeStyles, Divider, Menu, MenuItem } from "@material-ui/core";
import { DialogProps } from ".";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { TaskTimingModifyRequest } from "../../../model/api/generated/api-client";
import { TaskStat } from "../../../model/api/generated/api-client";
import { DataGrid, GridCellClassParams, GridColumns, GridToolbarContainer, GridColumnsToolbarButton, GridDensitySelector } from '@material-ui/data-grid';
import FullScreenDialog from "./FullScreenDialog";
import UpdateIcon from '@material-ui/icons/Update';
import SnoozeIcon from '@material-ui/icons/Snooze';
import RefreshIcon from '@material-ui/icons/Refresh';
import { DateTime, Duration, DurationObject } from "luxon";
import { useApp } from "../../..";

const useStyles = makeStyles((theme) => ({
  grid: {
    "& .awaiting.valueNotZero": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
    "& .sleeping.valueNotZero": {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
    "& .retry.valueNotZero": {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
    },
    "& .willRetry.valueNotZero": {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
    },
    "& .running.valueNotZero": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
}));

export default React.memo((props: DialogProps): JSX.Element => {
  const { api } = useApp();
  const [ stats, setStats ] = useState<TaskStat[] | null>(null);
  const [ reloadRequest, setReloadRequest ] = useState(0);
  useEffect(() => {
    api.taskApi.stat().then((res) => setStats(sortStats(res.data)));
  }, [ api, setStats, reloadRequest ]);
  const requestReload = () => {
    setStats(null);
    setReloadRequest(reloadRequest + 1);
  };

  const [ selectedTask, setSelectedTask ] = useState<string | null>(null);

  const classes = useStyles();
  const countColumn = {
    type: "number",
    width: 120, headerAlign: "right", align: "right",
    cellClassName: (({value, field, row}: GridCellClassParams) => {
      return [ row.taskType, field, (value !== 0 ? "valueNotZero" : "valueZero") ];
    }),
  } as const;
  const columns: GridColumns = [
    {
      field: "taskType", headerName: "Type",
      flex: 1, width: 200, headerAlign: "right", align: "right",
      valueFormatter: ({value}) => (value === "Task" ? "( All Tasks )" : value),
    },
    { field: "total", headerName: "Total", ...countColumn },
    { field: "awaiting", headerName: "Awaiting", ...countColumn },
    { field: "sleeping", headerName: "Sleeping", ...countColumn },
    { field: "retry", headerName: "Retry", ...countColumn },
    { field: "willRetry", headerName: "Will Retry", ...countColumn },
    { field: "running", headerName: "Running", ...countColumn },
    { field: "completed", headerName: "Completed", ...countColumn },
    { field: "abandoned", headerName: "Abandoned", ...countColumn },
  ];
  return <FullScreenDialog {...props} icon={<LibraryBooksIcon />} title="Tasks">
    <DataGrid
      className={classes.grid}
      components={{
        Toolbar: () => <GridToolbarContainer>
          <GridColumnsToolbarButton />
          <GridDensitySelector />
          <Divider orientation="vertical" flexItem />
          <Button size="small" color="primary" startIcon={<RefreshIcon />} onClick={requestReload}>Reload</Button>
          {selectedTask === null ? null : <>
            <Divider orientation="vertical" flexItem />
            <GridRunNowButton task={selectedTask} />
            <GridSnoozeButton task={selectedTask} />
          </>}
        </GridToolbarContainer>,
      }}
      loading={stats === null}
      rows={stats ?? []} columns={columns}
      onRowSelected={({ data: row }) => setSelectedTask(row.taskType)}
      getRowId={(row) => row.taskType}
      density="compact"
      hideFooter={true}
    />
  </FullScreenDialog>;
});

function GridRunNowButton({ task }: { task: string }): JSX.Element {
  const { api, clock } = useApp();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const onClose = () => setAnchorEl(null);
  const modifyTiming = (hightPriority: boolean, req: Partial<Omit<TaskTimingModifyRequest, "at" | "taskType">>) => {
    api.taskApi.modifyTiming({
      taskTimingModifyRequest: {
        at: clock.nowDateTime.minus(Duration.fromObject({ days: (hightPriority ? 30 : 0) })).toISO(),
        taskType: task,

        awaitingTasks: false,
        sleepingTasks: false,
        retryTasks: false,
        ...req
      },
    }).then(({data}) => showAlert(data), showAlert);
    onClose();
  };
  return <>
    <Button size="small" color="primary" startIcon={<UpdateIcon />} onClick={(event) => setAnchorEl(event.currentTarget)}>Run now</Button>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      getContentAnchorEl={null} // https://medium.com/cloud-native-the-gathering/material-ui-how-to-change-a-menus-menuitems-anchor-to-popover-below-instead-of-over-ab222e175cfd
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <MenuItem onClick={() => modifyTiming(false, { sleepingTasks: true, awaitingTasks: true, retryTasks: true })}>Run all {task}</MenuItem>
      <MenuItem onClick={() => modifyTiming(true, { sleepingTasks: true, awaitingTasks: true, retryTasks: true })}>Run all {task} (high priority)</MenuItem>
      <MenuItem onClick={() => modifyTiming(false, { sleepingTasks: true })}>Run sleeping {task}</MenuItem>
      <MenuItem onClick={() => modifyTiming(true, { sleepingTasks: true })}>Run sleeping {task} (high priority)</MenuItem>
      <MenuItem onClick={() => modifyTiming(false, { awaitingTasks: true })}>Run awaiting {task}</MenuItem>
      <MenuItem onClick={() => modifyTiming(true, { awaitingTasks: true })}>Run awaiting {task} (high priority)</MenuItem>
      <MenuItem onClick={() => modifyTiming(false, { retryTasks: true })}>Run retrying {task}</MenuItem>
      <MenuItem onClick={() => modifyTiming(true, { retryTasks: true })}>Run retrying {task} (high priority)</MenuItem>
    </Menu>
  </>;
}

function GridSnoozeButton({ task }: { task: string }): JSX.Element {
  const { api } = useApp();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const onClose = () => setAnchorEl(null);
  const modifyTiming = (d: DurationObject, req: Partial<Omit<TaskTimingModifyRequest, "at" | "taskType">>) => {
    api.taskApi.modifyTiming({
      taskTimingModifyRequest: {
        at: DateTime.now().plus(Duration.fromObject(d)).toISO(),
        taskType: task,

        awaitingTasks: false,
        sleepingTasks: false,
        retryTasks: false,
        ...req
      },
    }).then(({data}) => showAlert(data), showAlert);
    onClose();
  };
  return <>
    <Button size="small" color="primary" startIcon={<SnoozeIcon />} onClick={(event) => setAnchorEl(event.currentTarget)}>Snooze</Button>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      getContentAnchorEl={null} // https://medium.com/cloud-native-the-gathering/material-ui-how-to-change-a-menus-menuitems-anchor-to-popover-below-instead-of-over-ab222e175cfd
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
      <MenuItem onClick={() => modifyTiming({ days: 1 }, { sleepingTasks: true, awaitingTasks: true, retryTasks: true })}>Snooze all {task} 1 day</MenuItem>
      <MenuItem onClick={() => modifyTiming({ days: 7 }, { sleepingTasks: true, awaitingTasks: true, retryTasks: true })}>Snooze all {task} 7 day</MenuItem>
      <MenuItem onClick={() => modifyTiming({ days: 1 }, { sleepingTasks: true })}>Run sleeping {task} 1 day</MenuItem>
      <MenuItem onClick={() => modifyTiming({ days: 7 }, { sleepingTasks: true })}>Run sleeping {task} 7 day</MenuItem>
      <MenuItem onClick={() => modifyTiming({ days: 1 }, { awaitingTasks: true })}>Run awaiting {task} 1 day</MenuItem>
      <MenuItem onClick={() => modifyTiming({ days: 7 }, { awaitingTasks: true })}>Run awaiting {task} 7 day</MenuItem>
      <MenuItem onClick={() => modifyTiming({ days: 1 }, { retryTasks: true })}>Run retrying {task} 1 day</MenuItem>
      <MenuItem onClick={() => modifyTiming({ days: 7 }, { retryTasks: true })}>Run retrying {task} 7 day</MenuItem>
    </Menu>
  </>;
}

function sortStats(stats: TaskStat[]): TaskStat[] {
  return [...stats].sort((a, b) => {
    if (a.taskType !== b.taskType) {
      // Bring "Task" (total) first.
      if (a.taskType === "Task") return -1;
      if (b.taskType === "Task") return +1;
      return a.taskType.localeCompare(b.taskType);
    }
    return 0;
  });
}

function showAlert(obj: any) {
  alert(JSON.stringify(obj))
}
