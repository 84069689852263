import { useEffect } from "react";
import { useApp } from "../../..";
import { useAppSnackbar } from "./AppSnackbar";

export default function MediaAlertsSnackbar() {
  const { player } = useApp();
  const [ snackbar, setAlert ] = useAppSnackbar();
  useEffect(() => {
    const handles = [
      player.addListener("media-not-playable", () => {
        setAlert({ color: "warning", message: `Media not playable` });
      }),
    ];
    return () => handles.forEach((h) => h.remove());
  }, [ setAlert, player ]);
  return snackbar;
}
