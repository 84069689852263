import { IconButton } from "@material-ui/core";
import useCurrentMediaList from "../hooks/useCurrentMediaList";
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { useApp } from "..";

export default function PlayingPreviousMediaButtion({small}: {
  small?: boolean,
}): JSX.Element {
  const { player } = useApp();
  const list = useCurrentMediaList();
  if (!list || list.items.length <= 1) return <></>;
  return <IconButton size={small ? "small" : "medium"} color="inherit" onClick={() => player.previousTrack()}>
    <SkipPreviousIcon />
  </IconButton>;
};
