import { useCallback, useEffect, useState } from 'react';
import { toolbarHeight, uiStylesBase, AppUIProps, useAppMode, ListNavigation } from ".";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { PlayerUI } from "../../components/PlayerUI";
import MediaListView from "../../components/MediaListView";
import SearchInput from "../../atoms/SearchInput";
import PlayingMediaThumbnail from "../../atoms/PlayingMediaThumbnail";
import PlayingMediaName from "../../atoms/PlayingMediaName";
import PlayingPauseResumeButton from "../../atoms/PlayingPauseResumeButton";
import PlayingNextMediaButtion from "../../atoms/PlayingNextMediaButtion";
import { MediaListWithItems } from '../../model/List';
import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuButton from "./MenuButton";
import { useApp } from '../..';
import ImageUI from "./imageUI";

const useSmallUIStyles = makeStyles(theme => ({
  belowAppBar: {
    height: `calc(100vh - ${toolbarHeight})`,
    // paddingBottom: toolbarHeight,
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      display: "none",
    },
  },
  betweenAppBar: {
    height: `calc(100vh - ${toolbarHeight})`,
    // paddingBottom: toolbarHeight,
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      display: "none",
    },
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
  },
  bottomAppBarMediaName: {
    marginLeft: "8px",
  },
  mediaPlayerSmallUI: {
    height: "100%",
  },
}));
// eslint-disable-next-line no-empty-pattern
export default function SmallUI({ showDialog }: AppUIProps): JSX.Element {
  const { player } = useApp();
  const [ showPlayer, setShowPlayer ] = useState(false);

  useEffect(() => {
    const h = player.addListener("current-list-changed", () => {
      setShowPlayer(true);
    });
    return () => h.remove();
  }, [player, setShowPlayer]);

  const [ appState, setMode ] = useAppMode();
  const { mode, searchQuery } = appState;
  const [ mediaList, setMediaList ] = useState<MediaListWithItems | null>(null);
  const onSelectMediaList = useCallback((mediaList: MediaListWithItems) => {
    setMediaList(mediaList);
    setShowPlayer(true);
  }, [ setMediaList, setShowPlayer ]);
  const showCurrentMediaList = useCallback(() => {
    if (player.currentList) setMediaList(player.currentList);
  }, [ player, setMediaList]);

  const classes = { ...uiStylesBase(), ...useSmallUIStyles() };
  if (appState.mode === "confidential-films") return <ImageUI showDialog={showDialog} appState={appState} setMode={setMode} />;
  return <>
    <div className={classes.root + " " + !showPlayer ? classes.betweenAppBar : classes.belowAppBar}>
      {!showPlayer ? <div>
        <AppBar position="sticky">
          <Toolbar>
            <div className={classes.toolBarButtons}>
              <MenuButton mode={mode} onSelected={setMode} showDialog={showDialog} />
            </div>
            <div className={classes.toolBarGrow}>
              <SearchInput queryTemplate={searchQuery} />
            </div>
          </Toolbar>
        </AppBar>
        <ListNavigation {...appState} onSelect={onSelectMediaList} />
      </div> : <div className={classes.mediaPlayer + " " + classes.mediaPlayerSmallUI}>
        <div className={classes.playerContainer}><PlayerUI /></div>
        <div className={classes.mediaListContainer}>{mediaList ? <MediaListView clickToPlay={true} list={mediaList} /> : <>No list selected</>}</div>
      </div>}
    </div>
    <AppBar position="fixed" color="primary" className={classes.bottomAppBar}>
      <Toolbar>
        {!showPlayer ? <>
          <div className={`${classes.toolBarGrow} clickable`} onClick={() => { showCurrentMediaList(); setShowPlayer(true) }}>
            <PlayingMediaThumbnail />
            <Typography noWrap variant="h6" className={classes.bottomAppBarMediaName}><PlayingMediaName /></Typography>
          </div>
          <div className={classes.toolBarButtons}>
            <PlayingPauseResumeButton />
            <PlayingNextMediaButtion />
          </div>
        </> : <>
          <IconButton edge="start" color="inherit" onClick={() => setShowPlayer(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </>}
      </Toolbar>
    </AppBar>
  </>;
}
