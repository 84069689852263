import 'react-app-polyfill/stable'; // This must be the first line in src/index.js
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Design } from "typesafe-di";
import './index.css';
import AppView from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeSwitcher } from "./components/wrapper/ThemeSwitcher";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { modelDesign, Models } from "./model";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

export type App = {} & Models;
const appDesign = Design.empty.merge(modelDesign);
const AppContext = React.createContext<null | App>(null);
export function useApp(): App {
  const app = useContext(AppContext);
  if (app === null) throw new Error("Component uses useApp() hook must be contained in <AppContextProvider>.");
  return app;
}

if (window.location.hostname === "localhost") document.title += " [DEV]";

ReactDOM.render(
  // Intentionally skipped <React.StrictMode> due to https://stackoverflow.com/a/61705445/914786
  <AppContextProvider body={() => <>
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <ThemeSwitcher>
        <AppView />
      </ThemeSwitcher>
    </MuiPickersUtilsProvider>
  </>} />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function AppContextProvider({ body }: {
  /** Rendered only when App available. */
  body: () => JSX.Element,
}): JSX.Element {
  const [ app, setApp ] = useState<App | null>(null);
  const [ error, setError ] = useState<any>(null);
  const finalizerRef = useRef<() => Promise<void> | null>();
  useEffect(() => {
    appDesign.resolve({}).then(
      ({container: app, finalize}) => {
        setApp(app);
        finalizerRef.current = finalize;
      },
      setError
    );
    return () => { finalizerRef.current ? finalizerRef.current() : void(1) };
  }, [ setApp, setError ]);

  if (error != null) return <Alert severity="error">{`${error}`}{error.stack ? error.stack : null}</Alert>;
  if (app != null) return <AppContext.Provider value={app}>
    {body()}
  </AppContext.Provider>;
  return <>
    <CircularProgress size="40px" />
  </>;
}
