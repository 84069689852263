import React, { useCallback, useEffect, useState } from "react";
import { EditableMediaList, AnyMediaList, MediaListWithItems, rootNodeID, mediaTypesOf, isEditableMediaList } from "../../model/List";
import { DialogProps } from ".";
import { Dialog, DialogContent } from "./Dialog";
import { useApp } from "../..";
import { CircularProgress, makeStyles, Tab, Tabs, TextField } from "@material-ui/core";
import List from "../../model/List";
import { Media, MediaFileType } from "../../model/Media";
import { ListTree } from "../ListTree";
import MediaListView from "../MediaListView";

const TabDefinitions = {
  "create-new-list": "Setup new list",
  "select-list": "Select destination list",
  "pick-items": "Pick items to copy",
} as const;
type TabID = keyof (typeof TabDefinitions);

type DestCreateNewList = { type: "create-new-list" } & Omit<Parameters<InstanceType<typeof List>["createPlaylist"]>[0], "items">;
type DestSelectedList = { type: "selected-list", list: EditableMediaList };
type Dest = DestCreateNewList | DestSelectedList;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "75vh",
  },
  container: {},
  tabBar: {
    top: "-8px", // To cancel MuiDialogContent padding
    marginTop: "-8px", // To cancel MuiDialogContent padding
    position: "sticky",
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  tab: {},
}));

export type MediaListBulkAddDialogProps = DialogProps & {
  src: AnyMediaList,
  // If null, create new MediaList on save (present list name input UI and folder select UI).
  dest: EditableMediaList | "create-new-list" | "select-list",
};

export default function MediaListBulkAddDialog(): JSX.Element {
  return <Dialog
    id="MediaListBulkAddDialog"
    content={function Content(props: MediaListBulkAddDialogProps) {
      const { src, dest: destMode } = props;
      const { list: List } = useApp();

      const tabs: TabID[] = [];
      if (destMode === "create-new-list") tabs.push("create-new-list");
      if (destMode === "select-list") tabs.push("select-list");
      tabs.push("pick-items");
      const [ selectedTab, setSelectedTab ] = useState<TabID>(tabs[0]); // Select 1st tab by default

      const mediaType = mediaTypesOf(src)[0]; // Dirty hack: Pick 1st itemType always...

      const [ dest, setDest ] = useState<Dest | null>((typeof(destMode) === "object") ? { type: "selected-list", list: destMode } : null);
      const destListName = (dest === null) ? null : (dest.type === "create-new-list") ? dest.name : dest.list.name ?? null;

      const [ items, setItems ] = useState<Media[]>([]);

      const [ srcList, setSrcList ] = useState<MediaListWithItems | null>(null);
      useEffect(() => {
        List.getItemsOf(src).then(setSrcList);
      }, [ List, src, setSrcList ]);

      const onSave = useCallback(async () => {
        if (dest === null) throw new Error(`Destination should not be null`);
        if (dest.type === "create-new-list") {
          await List.createPlaylist({
            ...dest,
            items,
          });
        } else {
          for(const item of items) {
            await List.addMediaToList(dest.list, item);
          }
        }
      }, [ List, dest, items ]);

      const classes = useStyles();
      if (srcList === null) return <>
        <CircularProgress />
      </>;
      return <DialogContent
        {...props}
        maxWidth="xl"
        className={classes.root}
        title={<>Copy items to the {destMode === "create-new-list" ? "new playlist" : "playlist"} {destListName === null ? null : `"${destListName}"`}</>}
        disableSave={dest === null}
        onSave={onSave}
      >
        <div className={classes.container}>
          <Tabs className={classes.tabBar} value={selectedTab} onChange={(_, tabID) => setSelectedTab(tabID)}>
            {tabs.map((tabID) => <Tab key={tabID} value={tabID} label={TabDefinitions[tabID]} />)}
          </Tabs>
          <div className={classes.tab}>
            <NewPlaylistEditor visible={selectedTab === "create-new-list"} setDest={setDest} mediaType={mediaType} />
            <DestListPicker visible={selectedTab === "select-list"} dest={dest} setDest={setDest} mediaType={mediaType} />
            <ItemPicker visible={selectedTab === "pick-items"} srcList={srcList} items={items} setItems={setItems} />
          </div>
        </div>
      </DialogContent>;
    }}
  />;
}

const NewPlaylistEditor = React.memo(({ visible, setDest, mediaType }: {
  visible: boolean,
  setDest: (dest: DestCreateNewList) => void,
  mediaType: MediaFileType,
}): JSX.Element => {
  const { list: List } = useApp();
  const [ listName, setListName ] = useState("");
  const [ listNameError, setListNameError ] = useState<string | null>(null);
  const [ parentFolderID, setParentFolderID ] = useState(rootNodeID);

  useEffect(() => {
    (async () => {
      const parent = await List.get(parentFolderID);
      if (parent.type !== "folder") throw new Error(`Non folder node selected as a new list parent: ${parentFolderID}`);

      if (listName === "") {
        setListNameError("Specify list name");
        return;
      } else {
        setListNameError(null);
      }

      setDest({
        type: "create-new-list",
        mediaType,
        name: listName,
        parent,
      });
    })();
  }, [ List, mediaType, listName, parentFolderID, setListNameError, setDest ]);

  return <div style={{ display: visible ? "block" : "none" }}>
    <TextField
      label="Playlist name"
      fullWidth={true}
      value={listName}
      autoFocus
      onChange={(e) => {
        setListName(e.target.value);
      }}
      helperText={listNameError}
      error={!!listNameError}
    />

    <p>Select folder:</p>
    <ListTree
      types={[mediaType]}
      showRootFolder={true}
      folderOnly={true}
      isSelected={(node) => node.id === parentFolderID}
      onFolderClick={(folder) => {
        setParentFolderID(folder.id);
      }}
    />
  </div>;
});

const DestListPicker = React.memo(({ visible, dest, setDest, mediaType }: {
  visible: boolean,
  dest: Dest | null,
  setDest: (dest: DestSelectedList) => void,
  mediaType: MediaFileType,
}): JSX.Element => {
  return <div style={{ display: visible ? "block" : "none" }}>
    <p>Select destination list:</p>
    <ListTree
      types={[mediaType]}
      showRootFolder={false}
      isSelected={(node) => dest !== null && dest.type === "selected-list" && dest.list.id === node.id}
      onSelect={(node) => {
        if (isEditableMediaList(node)) setDest({
          type: "selected-list",
          list: node,
        });
      }}
    />
  </div>;
});

const ItemPicker = React.memo(({ visible, srcList, items, setItems }: {
  visible: boolean,
  srcList: MediaListWithItems,
  items: Media[],
  setItems: (items: Media[]) => void,
}): JSX.Element => {
  return <div style={{ display: visible ? "block" : "none" }}>
    <MediaListView
      list={srcList}
      clickToPlay={false}
      secondaryAction="play"
      onClick={(list, index) => {
        const item = list.items[index];
        if (containsItem(items, item)) {
          setItems(excludeItem(items, item));
        } else {
          setItems([ ...items, item ]);
        }
      }}
      isSelected={(list, index) => {
        const item = list.items[index];
        return containsItem(items, item);
      }}
    />
  </div>;
});

const containsItem = (list: Media[], item: Media) => !!list.find((elm) => item.id === elm.id);
const excludeItem = (list: Media[], item: Media) => list.filter((elm) => item.id !== elm.id);
