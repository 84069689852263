import { Avatar, Badge, makeStyles, withStyles } from "@material-ui/core";
import useStorageUrl from "../hooks/useStorageUrl";
import { Media } from "../model/Media";
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import MediaFileTypeIcon from "./MediaFileTypeIcon";
import CloudOffIcon from '@material-ui/icons/CloudOff';
import useThumbnail from "../hooks/useThumbnail";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: "none", // `2px solid ${theme.palette.background.paper}`,
    boxShadow: `0px 0px 6px ${theme.palette.background.default}`,
    background: theme.palette.background.default,
  },
}))(Avatar);

const useStyle = makeStyles((theme) => ({
  incompatible: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.grey[700],
  },
}));

export default function MediaAvatar({ media, badge, incompatible }: { media: Media | null, badge?: null | "playing", incompatible?: boolean }): JSX.Element {
  const image = useThumbnail(media, [ 40, 40 ]);
  const imageUrl = useStorageUrl(image?.fileHash ?? null);

  const classes = useStyle();
  if (!media) return <></>;
  const avatar = <Avatar
    variant="square"
    src={imageUrl ?? undefined}
  >
    <MediaFileTypeIcon type={media.type} /> {/* Fallback icon */}
  </Avatar>;
  const icon = incompatible ? <Avatar className={classes.incompatible}><CloudOffIcon /></Avatar> : avatar;
  if (badge) {
    return <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={<SmallAvatar><PlayCircleFilledOutlinedIcon color="primary" /></SmallAvatar>}
    >{icon}</Badge>;
  } else {
    return icon;
  }
};
