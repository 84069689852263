import { IconButton } from "@material-ui/core";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import useCurrentMedia from "../hooks/useCurrentMedia";
import usePlayerPaused from "../hooks/usePlayerPaused";
import usePlayerPlayable from "../hooks/usePlayerPlayable";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { useApp } from "..";

export default function PlayingPauseResumeButton({small}: {
  small?: boolean,
}): JSX.Element {
  const { player } = useApp();
  const media = useCurrentMedia();
  const paused = usePlayerPaused();
  const playable = usePlayerPlayable();

  if (!media) return <></>;
  return <IconButton
    size={small ? "small" : "medium"} color="inherit"
    onClick={() => player.paused = !paused}
    disabled={!playable}
  >
    { !playable ? <HourglassEmptyIcon />
     : paused ? <PlayArrowIcon />
     : <PauseIcon />
    }
  </IconButton>;
};
