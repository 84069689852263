import { Duration } from "luxon";
import React from "react";
import { Media, MediaThumbnail } from "../model/Media";
import useStorageUrl from "../hooks/useStorageUrl";
import { ImageFileInfo, parseFileInfo } from "../model/FileInfo";

/** Shows tiled thumbnail corresponds to given duration. */
const MediaTiledThumbnail = React.memo((props: {
  media: Media,
  currentTime: Duration,
  /** Max width, height [px] */
  maxSize: [ number, number ],

  /** If given media does not have tiled, use this instead. */
  alternativeElement?: () => JSX.Element,
}): JSX.Element => {
  const thumbnail = props.media.thumbnails.find((t) => t.tiling);
  const AltElement = props.alternativeElement ?? (() => <></>);
  if (!thumbnail) return <AltElement />;
  return <MediaTiledThumbnailImpl {...props} thumbnail={thumbnail} AltElement={AltElement} />;
});
export default MediaTiledThumbnail;

function MediaTiledThumbnailImpl({ thumbnail, currentTime, maxSize, AltElement }: {
  thumbnail: MediaThumbnail,
  currentTime: Duration,
  maxSize: [ number, number ],
  AltElement: () => JSX.Element,
}): JSX.Element {
  const src = useStorageUrl(thumbnail.imageFile.fileHash);
  const { widthPx: imgWidth, heightPx: imgHeight } = (parseFileInfo(thumbnail.imageFile.files[0]) as ImageFileInfo);
  const { tiles, tileInterval, tileHeight, tileWidth, tileX: tilesPerRow, tileY: tileRows } = thumbnail.tiling!!;

  // Decide which tile to show.
  const interval = Duration.fromISO(tileInterval);
  const tileIndex = Math.max(0, Math.min(tiles - 1, enforceFinite(Math.round(
    currentTime.toMillis() / interval.toMillis()
  ), 0)));
  const tileX = tileIndex % tilesPerRow;
  const tileY = Math.floor(tileIndex / tilesPerRow);

  // Decide image element size.
  const [ maxW, maxH ] = maxSize;
  const zoomRatio = enforceFinite(Math.min(maxW / tileWidth, maxH / tileHeight), 1);

  if (!src) return <AltElement />;
  return <div style={{
    display: "inline-block",
    overflow: "hidden",
    width: `${Math.round(tileWidth * zoomRatio)}px`,
    height: `${Math.round(tileHeight * zoomRatio)}px`,
  }}>
    <img
      src={src}
      alt=""
      style={{
        zoom: `${zoomRatio * 100}%`,
        width: `${Math.round(imgWidth * zoomRatio)}px`,
        height: `${Math.round(imgHeight * zoomRatio)}px`,
        marginLeft: `-${Math.ceil(tileX * tileWidth * zoomRatio)}px`,
        marginTop: `-${Math.ceil(tileY * tileHeight * zoomRatio)}px`,
        clipPath: `inset(
          ${Math.ceil(tileY * tileHeight * zoomRatio)}px
          ${Math.ceil((tilesPerRow - tileX - 1) * tileWidth * zoomRatio)}px
          ${Math.ceil((tileRows - tileY - 1) * tileHeight * zoomRatio)}px
          ${Math.ceil(tileX * tileWidth * zoomRatio)}px
        )`,
      }}
    />
  </div>;
}

const enforceFinite = (num: number, fallback: number) => (isFinite(num) ? num : fallback);
