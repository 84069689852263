import { DateTime, Duration } from "luxon";
import API from "./api";
import Clock from "./Clock";

const urlUsageMargin = Duration.fromObject({ minutes: 3 });
const urlRequestMargin = Duration.fromObject({ hours: 3 });

export default class Storage{
  private clock: Clock;
  private api: API;
  constructor(deps: { clock: Clock, api: API }){
    this.clock = deps.clock;
    this.api = deps.api;
  }

  private cache: { [fileHash: string]: {
    url: string,
    validUntil: DateTime,
  } } = {};
  private requestQueue: {
    [fileHash: string]: ([(result: { url: string, validUntil: DateTime }) => void, (e?: any) => void])[],
  } = {};

  async getDownloadUrl(fileHash: string, validUntil?: Duration): Promise<{ url: string, validUntil: DateTime }> {
    const cached = this.cache[fileHash];
    if (cached) {
      if (cached.validUntil.diffNow().as("milliseconds") > (validUntil ?? urlUsageMargin).as("milliseconds")) return this.cache[fileHash];
      console.log(`Storage URL expired, renewing...`, fileHash, cached, cached.validUntil.diffNow().toISO());
    }

    const p = new Promise<{ url: string, validUntil: DateTime }>((resolve, reject) => {
      (this.requestQueue[fileHash] = this.requestQueue[fileHash] || []).push([resolve, reject]);
      this.queueRequest();
    });
    return p;
  }

  private requestTimer: number | null = null;

  private queueRequest() {
    if (this.requestTimer !== null) return;
    if (Object.entries(this.requestQueue).length === 0) return;
    this.requestTimer = window.setTimeout(() => {
      this.requestUrls();
      this.requestTimer = null;
    }, 1);
  }
  private requestUrls() {
    const request = Object.fromEntries(Object.entries(this.requestQueue).slice(0, 25));
    for (const [ hash ] of Object.entries(request)) {
        delete this.requestQueue[hash];
    }

    this.api.storageDownloadApi.getUrlBulk({
      validUntilAtLeast: this.clock.nowDateTime.plus(urlRequestMargin).toISO(),
      fileHashes: Object.keys(request),
    }).then(
      (res) => {
        for(const [hash, chain] of Object.entries(request)) {
          for(const [resolve] of chain) {
            const entry = {
              url: res.data[hash].url,
              validUntil: DateTime.fromISO(res.data[hash].validUntil),
            };
            this.cache[hash] = entry;
            resolve(entry);
          }
        }
        this.queueRequest();
      },
      (err) => {
        for(const [, chain] of Object.entries(request)) {
          for(const [, reject] of chain) {
            reject(err);
          }
        }
        this.queueRequest();
      },
    )
  }
};
