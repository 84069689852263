import React, { useCallback, useEffect } from "react";
import { AppBar, Dialog, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { DialogProps } from ".";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    height: "100%",
  },
}));

export default function FullScreenDialog({ open, onClose, children, title, icon, confirmOnClose }: DialogProps & {
  children: React.ReactNode,
  title: String,
  icon?: JSX.Element,
  confirmOnClose?: string,
}): JSX.Element {
  useEffect(() => {
    if (! confirmOnClose) return;
    const f = (event: BeforeUnloadEvent) => {
      console.log(confirmOnClose);
      if (!window.confirm(confirmOnClose)) {
        event.preventDefault();
        event.stopPropagation();
        event.returnValue = confirmOnClose;
      }
    };
    const listenerOptions = { capture: true } as const; // Must match between listener addition and removal.
    window.addEventListener("beforeunload", f, listenerOptions);
    return () => window.removeEventListener("beforeunload", f, listenerOptions);
  }, [ confirmOnClose ]);

  const closeHandler = useCallback(() => {
    if (confirmOnClose) {
      if (!window.confirm(confirmOnClose)) return;
    }
    onClose();
  }, [ onClose, confirmOnClose ]);

  const classes = useStyles();
  return <Dialog fullScreen open={open} onClose={closeHandler}>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={closeHandler}>
          {icon}
        </IconButton>
        <Typography variant="h6" className={classes.title}>{title}</Typography>
        <IconButton edge="end" color="inherit" onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <div className={classes.container}>
      {/* IMPORTANT: Always keep children mounted. Otherwise could loose UI / user input state. */}
      {children}
    </div>
  </Dialog>
}
