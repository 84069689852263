import { EventEmitter, EventSubscription } from 'fbemitter';
import { Attributes } from './Attributes';
import { Media } from './Media';

export default class ModelNotification {
  private emitter = new EventEmitter();

  addListener(t: "MediaList.created", f: (listID: string) => void): EventSubscription;
  addListener(t: "MediaList.criteria-changed", f: (listID: string) => void): EventSubscription;
  addListener(t: "MediaList.renamed", f: (listID: string) => void): EventSubscription;
  addListener(t: "MediaList.deleted", f: (listID: string) => void): EventSubscription;
  addListener(t: "MediaList.mobile-sync-set", f: (listID: string) => void): EventSubscription;
  addListener(t: "MediaList.moved", f: (listID: string, parentID: string) => void): EventSubscription;
  addListener(t: "MediaList.media-added", f: (listID: string, media: Media) => void): EventSubscription;
  addListener(t: "MediaList.media-removed", f: (listID: string, media: Media) => void): EventSubscription;
  addListener(t: "Media.media-attributes-changed", f: (media: Media, keys: (keyof Attributes)[]) => void): EventSubscription;
  addListener(t: string, f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  emit(t: "MediaList.created", listID: string): void;
  emit(t: "MediaList.criteria-changed", listID: string): void;
  emit(t: "MediaList.renamed", listID: string): void;
  emit(t: "MediaList.deleted", listID: string): void;
  emit(t: "MediaList.mobile-sync-set", listID: string): void;
  emit(t: "MediaList.moved", listID: string, parentID: string): void;
  emit(t: "MediaList.media-added", listID: string, media: Media): void;
  emit(t: "MediaList.media-removed", listID: string, media: Media): void;
  emit(t: "Media.media-attributes-changed", media: Media, keys: (keyof Attributes)[]): void;
  emit(t: string, ...args: any[]) {
    this.emitter.emit(t, ...args);
  }
}
