import React, { ForwardedRef, forwardRef, useCallback, useState } from "react";
import { Menu } from "@material-ui/core";

export type ContextMenuProps = {
  onClose: () => void,
  anchorEl?: Element,
  anchorPosition?: { clientX: number, clientY: number },
};

export const isContextMenuOpen = (props: ContextMenuProps) => props.anchorEl || props.anchorPosition;

export function useContextMenuState(): [
  ContextMenuProps,
  (event: React.MouseEvent) => void, // onClick handler
  (event: React.MouseEvent) => void, // onContextMenu handler
] {
  const [ props, setProps ] = useState<Omit<ContextMenuProps, "onClose">>({});
  const onClose = useCallback(() => { setProps({}); }, [ setProps ]);
  return [
    { onClose, ...props },
    useCallback((event) => {
      setProps({
        anchorEl: event.currentTarget,
      });
      event.preventDefault();
      event.stopPropagation();
    }, [ setProps ]),
    useCallback((event) => {
      setProps({
        anchorPosition: {
          clientX: event.clientX - 2,
          clientY: event.clientY - 4,
        },
      });
      event.preventDefault();
      event.stopPropagation();
    }, [ setProps ]),
  ];
}

// forwardRef is required, otherwise cause warning.
const ContextMenu = React.forwardRef(({
  onClose,
  content,
  anchorEl,
  anchorPosition,
}: ContextMenuProps & {
  /**
   * Contents of the context menu. Define JSX element and pass it.
   * Rendered only when menu opened.
   *
   * Implementation must pass ref to the 1st child element: https://stackoverflow.com/a/56309771/914786
   */
  content: (props: { ref: ForwardedRef<any> }) => JSX.Element,
}, ref): JSX.Element => {
  const open = !!(anchorEl || anchorPosition);
  const ContentWrapper = forwardRef((_, ref) => content({ ref }));
  return <Menu
    ref={ref}
    onClose={onClose}
    anchorReference={anchorPosition ? "anchorPosition" : "anchorEl"}
    anchorEl={open ? (anchorPosition ? undefined : anchorEl) : undefined}
    anchorPosition={anchorPosition ? { top: anchorPosition.clientY, left: anchorPosition.clientX } : undefined}
    open={open}
  >
    <ContentWrapper />
  </Menu>;
});
export default ContextMenu;
