import React, { useState } from "react";
import { ListFolder, ListChildren, ListTreeNode } from "../../model/List";
import { DialogProps } from ".";
import { Dialog, DialogContent } from "./Dialog";
import { ListTree } from "../ListTree";
import { mediaFileTypes } from "../../model/Media";
import { useApp } from "../..";
import OpenWithIcon from '@material-ui/icons/OpenWith';

export type ListMoveDialogProps = DialogProps & {
  node: ListTreeNode | ListChildren[number],
};

export default function ListMoveDialog(): JSX.Element {
  return <Dialog
    id="ListMoveDialog"
    content={function Content(props: ListMoveDialogProps) {
      const { node } = props;
      const { list: List } = useApp();
      const [ parentFolder, setParentFolder ] = useState<ListFolder | null>(null);

      return <DialogContent
        {...props}
        maxWidth="lg"
        title={<><OpenWithIcon fontSize="inherit" /> Move "{node.name}" to another parent</>}
        disableSave={parentFolder === null}
        onSave={async () => await List.changeParent(node, parentFolder!)}
      >
        Select destination:
        <ListTree
          showRootFolder={true}
          folderOnly={true}
          isSelected={(node) => node.id === parentFolder?.id}
          onFolderClick={setParentFolder}
          types={mediaFileTypes}
        />
      </DialogContent>;
    }}
  />;
}

