import React from "react";
import prettyBytes from 'pretty-bytes';
import { Button, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const useStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    width: "100%",
    height: "min(400px, calc(100vh - 300px))",

    backgroundColor: "rgba(245, 245, 245, 1)",
    "&.idle": {},
    "&.dragover": {
      backgroundSize: "auto auto",
      backgroundImage: 'repeating-linear-gradient(135deg, transparent, transparent 20px, rgba(250, 250, 250, 1) 20px, rgba(250, 250, 250, 1) 40px )',
    },
  },
  hintText: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center",
    fontSize: "2.5em",
    color: "#666",
  },
  commitButtion: {
    padding: "16px 44px",
    margin: "15px",
    fontSize: "2rem",
  },
  files: {
    marginTop: "10px",
  },
}));

export default React.memo(({ files, setFiles, onFileSelected }: {
  files: File[],
  setFiles: (files: File[]) => void,
  onFileSelected: (files: File[]) => void,
}): JSX.Element => {
  const totalSize = files.reduce((sum, file) => sum + file.size, 0);

  const onDropAccepted = (moreFiles: File[]) => {
    setFiles([...files, ...moreFiles]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDropAccepted });

  const classes = useStyles();
  return <div className={classes.root}>
    <div {...getRootProps()} className={`${classes.dropZone} ${isDragActive ? "dragover" : "idle"}`}>
      <input {...getInputProps()} />
      <div className={classes.hintText}>
        {(isDragActive || files.length === 0) ? <CloudUploadOutlinedIcon color="primary" style={{ fontSize: "200px" }} /> : null}
        <br />
        {isDragActive ? <>
          Drop the files here ...
        </> : (files.length > 0) ? <>
          <Button onClick={(event) => { onFileSelected(files); event.stopPropagation(); }} variant="contained" className={classes.commitButtion} color="primary">
            Upload {files.length} files ({prettyBytes(totalSize)})
          </Button>
          <br />
          Or drop more files here.
        </>: <>
          Drop some files here, or click to select files.
        </>}
      </div>
    </div>
    <div className={classes.files}>
      <Files files={files} />
    </div>
  </div>;
});

const useFilesStyles = makeStyles((theme) => ({
  overline: {
    textAlign: "right",
  },
}));

const Files = React.memo(({ files }: {
  files: File[],
}): JSX.Element => {
  const totalSize = files.reduce((sum, file) => sum + file.size, 0);

  const classes = useFilesStyles();
  return <>
    <div className={classes.overline}>
      <Typography variant="overline">{files.length} files, {prettyBytes(totalSize)}</Typography>
    </div>
    <List>
      {files.map((file, index) => <ListItem key={index}>
        <ListItemIcon><FileCopyOutlinedIcon /></ListItemIcon>
        <ListItemText primary={file.name} secondary={<>
          {prettyBytes(file.size)} {file.type}
        </>} />
      </ListItem>)}
    </List>
  </>;
});
