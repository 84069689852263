import { DateTime } from "luxon";
import { Attributes as RawAttributes, AttributesMovieTypeEnum as RawMovieType } from "./api/generated/api-client";

export type Attributes = Omit<RawAttributes, "dateAdded" | "releaseAt"> & {
  dateAdded?: DateTime,
  releaseAt?: DateTime,
};
export type Attribute = keyof Attributes;

export type AttributeValue = string | number | boolean | DateTime; // To expand this type, need to modify UI implementations.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const attributeTypeDefinitionConsisntencyCheck: {
  [attr in Attribute]?: AttributeValue
} = ({} as Attributes);

export const attributes: Readonly<Attribute[]> = [
  "name",
  "dateAdded",
  "hidden",
  "rating",
  "comment",
  "artist",
  "musicComposer",
  "albumName",
  "albumArtist",
  "musicGenre",
  "discNumber",
  "discCount",
  "trackNumber",
  "trackCount",
  "releaseYear",
  "releaseAt",
  "lyrics",
  "gameBrand",
  "movieType",
  "geohash",
  "latitudeSeconds",
  "longitudeSeconds",
  "altitudeMeters",
] as const;

export const attributesDefault: Readonly<Attributes> = { hidden: false, rating: 0 } as const;

export function parseRawAttributes(raw: RawAttributes): Attributes {
  const { dateAdded, releaseAt } = raw;
  return {
    ...raw,
    dateAdded: (dateAdded) ? DateTime.fromISO(dateAdded) : undefined,
    releaseAt: (releaseAt) ? DateTime.fromISO(releaseAt) : undefined,
  };
}

export function toRawAttributes(attrs: Attributes): RawAttributes {
  return Object.fromEntries(Object.entries(attrs).map(([attr, value]) => [attr, toRawAttribute(value)])) as any;
}

export function toRawAttribute<T extends AttributeValue | undefined>(value: T): (T extends DateTime ? Exclude<T, DateTime> | string : T) {
  if (value instanceof DateTime) return value?.toISO() as any;
  return value as any;
}

export const attributeIsString = (value: AttributeValue): value is string => typeof(value) === "string";
export const attributeIsNumber = (value: AttributeValue): value is number => typeof(value) === "number";
export const attributeIsBoolean = (value: AttributeValue): value is boolean => typeof(value) === "boolean";
export const attributeIsDateTime = (value: AttributeValue): value is DateTime => typeof(value) === "object";

const attributesDummyValues: { [attr in Attribute]: Exclude<Attributes[attr], undefined>} = {
  name: "",
  dateAdded: DateTime.fromMillis(0),
  hidden: false,
  rating: 1,
  comment: "",
  artist: "",
  musicComposer: "",
  albumName: "",
  albumArtist: "",
  musicGenre: "",
  discNumber: 0,
  discCount: 0,
  trackNumber: 0,
  trackCount: 0,
  releaseYear: 0,
  releaseAt: DateTime.fromMillis(0),
  lyrics: "",
  gameBrand: "",
  movieType: RawMovieType.GameOthers,
  geohash: "",
  latitudeSeconds: 0,
  longitudeSeconds: 0,
  altitudeMeters: 0,
};
/** In conjunction with attributeIsXXX function, useful to detect type of field. */
export const getAttributeDummyValue = (attr: Attribute) => attributesDummyValues[attr];



export type MovieType = RawMovieType;
export const movieTypes = [ RawMovieType.GameOthers, RawMovieType.GameOp, RawMovieType.GameEd, RawMovieType.GameDemo, RawMovieType.AnimeOthers, RawMovieType.AnimeOp, RawMovieType.AnimeEd ] as const;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const movieTypesShouldCoverAllValues: (typeof movieTypes)[number] = ("" as MovieType);
