import API from "./api";
import { HistoriaHistory } from "./api/generated/api-client";

export type { HistoriaHistory } from "./api/generated/api-client";

export default class Historia {
  private api: API;

  constructor(deps: { api: API }) {
    this.api = deps.api;
  }

  private cache: Promise<HistoriaHistory[]> | null = null;

  async list(): Promise<HistoriaHistory[]> {
    if (! this.cache) this.cache = (async () =>
      (await this.api.historiaApi.list()).data.filter(h => h.photogenic ?? h.category.photogenic)
    )();
    return this.cache;
  }
}
