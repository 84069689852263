import axios, { AxiosInstance } from 'axios';
import { AuthApi, Configuration, MediaApi, MediaListApi, MediaUploadApi, StorageDownloadApi, TaskApi, HistoryApi, MediaSearchApi, HistoriaApi } from "./generated/api-client";
import Session from "../Session";
import { BuildConstants } from '../BuildConstants';

export type ApiDeps = {
  buildConstants: BuildConstants,
  session: Session,
};

export default class API {
  private session: Session;
  constructor(private deps: ApiDeps) {
    this.session = deps.session;

    this.session.setAuthCheckApi(async () => {
      const res = await this.authApi.getStatus();
      console.debug("User ID (principal)", res.data?.principal);
      return res.status === 200;
    });
  }

  private config: Configuration = new Configuration({
    accessToken: () => this.session.getJWT(),
  });
  private axios: AxiosInstance = axios.create({
    timeout: 30 * 60 * 1000, // Caveats: should consider long-living request such as file upload or large media list fetching.
  });

  private get basePath(): string { return this.deps.buildConstants.REACT_APP_API_BASE_PATH ?? ""; }
  private get historyBasePath(): string { return this.deps.buildConstants.REACT_APP_HISTORY_API_BASE_PATH ?? this.basePath; }

  readonly authApi = new AuthApi(this.config, this.basePath, this.axios);
  readonly mediaApi = new MediaApi(this.config, this.basePath, this.axios);
  readonly mediaListApi = new MediaListApi(this.config, this.basePath, this.axios);
  readonly mediaUploadApi = new MediaUploadApi(this.config, this.basePath, this.axios);
  readonly storageDownloadApi = new StorageDownloadApi(this.config, this.basePath, this.axios);
  readonly mediaSearchApi = new MediaSearchApi(this.config, this.basePath, this.axios);
  readonly taskApi = new TaskApi(this.config, this.basePath, this.axios);
  readonly historyApi = new HistoryApi(this.config, this.historyBasePath, this.axios);
  readonly historiaApi = new HistoriaApi(this.config, this.historyBasePath, this.axios);
};
