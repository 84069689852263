import { EventEmitter, EventSubscription } from 'fbemitter';
import { MediaFileType, parseRawMedia } from './Media';
import { SearchRequest, SearchCriterionConfidentialFlag, SearchRequestSortEnum as SearchRequestSort, SearchCriterionMediaID, SearchCriterionAttributeKeyword, SearchCriterionDateAdded, SearchCriterionFileHash, SearchCriterionGeoHash, SearchCriterionHiddenFlag, SearchCriterionRating, SearchCriterionAnyKeyword, SearchCriterionDateAddedIn, MediaSearchApiAggregatedSearchRequest } from "./api/generated/api-client";
import API from "./api";
import { DateTime } from 'luxon';
import { TemporaryMediaList } from './List';

export { SearchRequestSortEnum as SearchRequestSort } from "./api/generated/api-client";
export type SearchAggregateBy = MediaSearchApiAggregatedSearchRequest["aggregateBy"];

export type SearchQuery = {
  types: MediaFileType[],
  confidential?: boolean,
  query: string,
};
export type SearchResult = TemporaryMediaList;

export type SearchQueryParseError = {
  searchQueryParseError: string;
};
export const isSearchQueryParseError = (obj: any): obj is SearchQueryParseError => typeof(obj) === "object" && typeof(obj.searchQueryParseError) === "string";

const keywordSearchableAttributes = [ "name", "artist", "albumName", "albumArtist", "gameBrand" ] as const;
const searchLimitDefault = 300;
const queryDebounce = 500;

export default class Search {
  private api: API;
  constructor(deps: { api: API }) {
    this.api = deps.api;
  }

  /** Create another instance, separate from global state. */
  createSeparateInstance(): Search {
    return new Search({ api: this.api });
  }

  private emitter = new EventEmitter();
  private _result: SearchResult | null = null;

  addListener(t: "search-queued" | "result-changed" | "query-changed" | "search-performed", f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  get result(): SearchResult | null {
    return this._result;
  }

  private setResult(result: SearchResult | null) {
    this._result = result;
    this.emitter.emit("result-changed");
  }

  private query: (SearchRequest & { queryOriginalString: string }) | null = null;
  get queryOriginalString(): string | null {
    return this.query?.queryOriginalString ?? null;
  }

  /** Request search (async) */
  search(query: SearchQuery): SearchQueryParseError | undefined {
    const q = parseQuery(query);
    if (isSearchQueryParseError(q)) return q;

    this.query = {
      ...q,
      queryOriginalString: query.query,
      limit: searchLimitDefault,
      sort: SearchRequestSort.DateAdded,
    };
    this.requestQuery();
    this.emitter.emit("query-changed");
  }

  increaseLimit(plus: number) {
    if (!this.query) return;
    this.query = { ...this.query, limit: this.query.limit ?? searchLimitDefault + plus };
    this.requestQuery();
  }

  clearSearch() {
    this.query = null;
    this.requestID++;
    this.requestCompleted = this.requestID;

    this.cancelQuery();
    this.setResult(null);
    this.emitter.emit("query-changed");
    this.emitter.emit("search-performed"); // Always emit to notify searchQueued() change.
  }

  private queryTimer: number | null = null;
  private requestID: number = 0;
  private requestCompleted: number = 0;

  get searchQueued(): boolean {
    return (this.requestCompleted < this.requestID);
  }

  private cancelQuery() {
    if (this.queryTimer) {
      window.clearTimeout(this.queryTimer);
      this.queryTimer = null;
    }
  }

  private requestQuery() {
    this.cancelQuery();

    const query = this.query;
    if (!query) return;
    this.requestID++;
    const requestID = this.requestID;

    this.queryTimer = window.setTimeout(async () => {
      try {
        const result = await this.api.mediaSearchApi.search({
          searchRequest: query,
        });
        if (this.requestID === requestID) this.setResult({
          ephemeralId: `search-result-${requestID}`,
          mediaTypes: query.t as MediaFileType[],
          name: query.queryOriginalString,
          query: queryToString(query.q),
          items: result.data.map(parseRawMedia),
        });
      } catch(e) {
        console.error("Failed to search", query, e);
      } finally {
        this.requestCompleted = Math.max(this.requestCompleted, requestID);
        this.emitter.emit("search-performed"); // Always emit to notify searchQueued() change.
        console.debug("search-performed", this.searchQueued);
      }
    }, queryDebounce);

    this.emitter.emit("search-queued");
    console.debug("search-queued", this.searchQueued);
  }
};

/**
 * Retrieve aggregated count corresponds to arbitrary search query.
 */
export class SearchStateless {
  private api: API;
  constructor(deps: { api: API }) {
    this.api = deps.api;
  }

  async search(query: SearchQuery, { sort, limit }: { sort?: SearchRequestSort, limit?: number }): Promise<SearchResult> {
    const q = parseQuery(query);
    if (isSearchQueryParseError(q)) throw new Error(`SearchQueryParseError: ${JSON.stringify(q)}`);

    const req: SearchRequest = {
      ...q,
      sort, limit,
    };
    const cacheKey = JSON.stringify(req);
    const result = (await this.api.mediaSearchApi.search({ searchRequest: req })).data;
    return {
      ephemeralId: `stateful-search-result-${cacheKey}`,
      mediaTypes: q.t as MediaFileType[],
      name: query.query,
      query: query.query,
      items: result.map(parseRawMedia),
    };
  }

  private countCache: { [req: string]: Promise<{ [ key: string ]: number }> } = {};

  async count(aggregateBy: SearchAggregateBy, query: SearchQuery): Promise<{ [ key: string ]: number }> {
    const q = parseQuery(query);
    if (isSearchQueryParseError(q)) throw new Error(`SearchQueryParseError: ${JSON.stringify(q)}`);

    const req: MediaSearchApiAggregatedSearchRequest = {
      aggregateBy,
      searchRequest: {
        ...q,
      },
    };
    const cacheKey = JSON.stringify(req);
    if (!this.countCache[cacheKey]) this.countCache[cacheKey] = (async () => (await this.api.mediaSearchApi.aggregatedSearch(req)).data)();
    return this.countCache[cacheKey];
  }
}

function parseQuery(q: SearchQuery): Omit<SearchRequest, "limit" | "sort"> | SearchQueryParseError {
  const query = parseQueryString(q.query);
  if (isSearchQueryParseError(query)) return query;
  return {
    t: q.types,
    q: [
      ...query,
      ...(typeof(q.confidential) === "boolean" ? [{ t: "confidential", b: q.confidential }] : []),
    ],
  };
}

export function queryToString(q: SearchRequest["q"]): string {
  return q.map(criteriaToString).join(" ");
}

export function parseQueryString(query: string): SearchRequest["q"] | SearchQueryParseError {
  const tokens = tokenizeQuery(query);
  if (isSearchQueryParseError(tokens)) return tokens;

  const result: SearchRequest["q"] = [];
  for(let i = 0; i < tokens.length;) {
    const token = tokens[i];
    switch(token.type) {
      case "colon": return { searchQueryParseError: "Got unexpected colon. Colon could be placed between string pair." };
      case "string":
        if (i + 1 < tokens.length && tokens[i + 1].type === "colon") { // string ":" string
          if (i + 2 >= tokens.length || tokens[i + 2].type !== "string") return {
            searchQueryParseError: "Colon should not be placed end of query. Colon could be placed between string pair.",
          };
          const criteria = parseCriteria(token, tokens[i + 2] as QueryTokenString);
          if (isSearchQueryParseError(criteria)) return criteria;
          result.push(criteria);
          i += 3; // Consume string, ":", string
        } else {
          const criteria = parseCriteria(null, token);
          if (isSearchQueryParseError(criteria)) return criteria;
          result.push(criteria);
          i++;
        }
        break;
    }
  }
  return result;
}

function criteriaToString(c: SearchRequest["q"][number]): string {
  function escapeString(str: string): string {
    if (/^[a-zA-Z0-9]$/.test(str)) return str;
    return `"${str.replaceAll("\\", "\\\\").replaceAll("\"", "\\\"")}"`;
  }
  switch(c.t) {
    case "kw": return escapeString((c as SearchCriterionAnyKeyword).v);

    case "r": return `rating:${(c as SearchCriterionRating).l}..${(c as SearchCriterionRating).u}`;
    case "confidential": return `confidential:${(c as SearchCriterionConfidentialFlag).b ? "true" : "false"}`;
    case "hidden": return `hidden:${(c as SearchCriterionHiddenFlag).b ? "true" : "false"}`;
    case "h": return `hash:${(c as SearchCriterionFileHash).h}`;
    case "gh": return `geohash:${(c as SearchCriterionGeoHash).h}`;
    case "adate": return `date:${escapeString((c as SearchCriterionDateAdded).since)}..${escapeString((c as SearchCriterionDateAdded).until)}`;
    case "adatein": return `in:${(c as SearchCriterionDateAddedIn).days}`;
    case "mid": return `id:${(c as SearchCriterionMediaID).id}`;
    default: return `${escapeString((c as SearchCriterionAttributeKeyword).a)}:${escapeString((c as SearchCriterionAttributeKeyword).v)}`;
  }
}

/** Parse `string ":" string` or `string` (keyword search). */
function parseCriteria(key: QueryTokenString | null, value: QueryTokenString): SearchRequest["q"][number] | SearchQueryParseError {
  if (key === null) {
    return {
      t: "kw", // SearchCriterionAnyKeyword
      v: value.value,
    };
  }

  switch(key.value) {
    case "rating": return parseCriterionRating(value);
    case "confidential": return parseConfidentialFlag(value);
    case "hidden": return parseCriterionHiddenFlag(value);
    case "hash": return parseCriterionFileHash(value);
    case "geohash": return parseCriterionGeoHash(value);
    case "date": return parseCriterionAddedAt(value);
    case "in": return parseCriterionAddedWithin(value);
    case "id": return parseCriterionMediaID(value);
    default: return parseCriterionAttribute(key, value);
  }
}

function parseCriterionRating(t: QueryTokenString): SearchCriterionRating | SearchQueryParseError {
  const [lower, upper] = t.value.split("..") as [ string ] | [ string, string ];
  if (!/^[0-5]$/.test(lower) || (typeof(upper) === "string" && !/^[0-5]$/.test(upper))) return {
    searchQueryParseError: `"${t.value}" is not valid rating range specification. Valid format is [0-5]..[0-5] or [0-5]`,
  };
  return {
    t: "r",
    l: +lower,
    u: typeof(upper) === "string" ? +upper : +lower,
  };
}

function parseConfidentialFlag(t: QueryTokenString): SearchCriterionConfidentialFlag | SearchQueryParseError {
  const flag = {
    "true": true,
    "false": false,
    "yes": true,
    "no": false,
  }[t.value.toLowerCase().trim()];
  if (typeof(flag) !== "boolean") return {
    searchQueryParseError: `"${t.value}" is not valid flag specifier. Valid format is confidential:(true|false|yes|no)`,
  };
  return { t: "confidential", b: flag };
}

function parseCriterionHiddenFlag(t: QueryTokenString): SearchCriterionHiddenFlag | SearchQueryParseError {
  const flag = {
    "true": true,
    "false": false,
    "yes": true,
    "no": false,
  }[t.value.toLowerCase().trim()];
  if (typeof(flag) !== "boolean") return {
    searchQueryParseError: `"${t.value}" is not valid flag specifier. Valid format is hidden:(true|false|yes|no)`,
  };
  return { t: "hidden", b: flag };
}

function parseCriterionFileHash(t: QueryTokenString): SearchCriterionFileHash | SearchQueryParseError {
  const prefix = t.value;
  if (!/^[0-9a-f]+$/.test(prefix)) return { searchQueryParseError: `"${prefix}" is not valid file hash prefix.` };
  return { t: "h", h: prefix };
}

function parseCriterionGeoHash(t: QueryTokenString): SearchCriterionGeoHash | SearchQueryParseError {
  const prefix = t.value;
  if (!/^[0-9a-z]+$/.test(prefix)) return { searchQueryParseError: `"${prefix}" is not valid GeoHash prefix.` };
  return { t: "gh", h: prefix };
}

function parseCriterionAddedAt(t: QueryTokenString): SearchCriterionDateAdded | SearchQueryParseError {
  const [lower, upper] = t.value.split("..") as [ string ] | [ string, string ];
  if (checkISODateTime(lower) !== null || typeof(upper) !== "string" || checkISODateTime(upper) !== null) return {
    searchQueryParseError: `Invalid date:ISODateTime..ISODateTime criteria given ("${t.value}"): ${checkISODateTime(lower) || (upper ? checkISODateTime(upper) : "No upper bound given.")}`,
  };
  return {
    t: "adate",
    since: DateTime.fromISO(lower).toISO(),
    until: DateTime.fromISO(upper).toISO(),
  };
}

function parseCriterionAddedWithin(t: QueryTokenString): SearchCriterionDateAddedIn | SearchQueryParseError {
  const within = t.value;
  if (!/^[0-9a-f]+$/.test(within)) return { searchQueryParseError: `"${within}" is not valid days, specify positive integer.` };
  return { t: "adatein", days:+within };
}

function parseCriterionMediaID(t: QueryTokenString): SearchCriterionMediaID | SearchQueryParseError {
  // e.g. media-a93bb40cccce402689c0902c5cedbfdd
  if (!/^media-[0-9a-f]+$/.test(t.value)) return {
    searchQueryParseError: `"${t.value}" is not valid media ID.`,
  };
  return { t: "mid", id: t.value };
}

function parseCriterionAttribute(key: QueryTokenString, t: QueryTokenString): SearchCriterionAttributeKeyword | SearchQueryParseError {
  const attribute = keywordSearchableAttributes.find((attr) => attr.toLowerCase() === key.value.trim().toLowerCase());
  if (!attribute) return {
    searchQueryParseError: `"${key.value.trim()}" is not keyword-search-able attribute name. Valid attributes are: ${keywordSearchableAttributes}`,
  };
  return {
    t: "akw",
    a: attribute,
    v: t.value,
  };
}

function checkISODateTime(str: string): null | string {
  const dt = DateTime.fromISO(str);
  if (dt.invalidExplanation || dt.invalidReason) return `"${str}" is not valid ISO DateTime (${dt.invalidReason}): ${dt.invalidExplanation}`;
  return null;
}

type QueryToken = QueryTokenString | QueryTokenColon;
type QueryTokenString = { type: "string", value: string };
type QueryTokenColon = { type: "colon" };

type TokenizerMode = "initial" | "unquoted-string" | "quoted-string";

/** @internal */
export function tokenizeQuery(query: string): QueryToken[] | SearchQueryParseError {
  const tokens: QueryToken[] = [];

  let stringToken = "";
  let mode: TokenizerMode = "initial";
  for (let i = 0; i < query.length; ) {
    const c = query[i];
    switch(mode) {
      case "initial":
        if (c === ":") {
          tokens.push({ type: "colon" });
          i++;
        } else if (isSpaceCharacter(c)) {
          i++;
        } else if (c === "\"") {
          mode = "quoted-string";
          stringToken = "";
          i++;
        } else {
          mode = "unquoted-string";
          stringToken = c;
          i++;
        }
        break;
      case "unquoted-string":
        if(isSpaceCharacter(c) || ":\"".indexOf(c) !== -1) { // End of token
          tokens.push({ type: "string", value: stringToken });
          mode = "initial";
          // Not increment pointer, re-parse current character.
        } else {
          stringToken += c;
          i++;
        }
        break;
      case "quoted-string":
        if(c === "\"") {
          tokens.push({ type: "string", value: stringToken });
          mode = "initial";
          i++;
        } else if(c === "\\") {
          i++;
          stringToken += query[i];
          i++;
        } else {
          stringToken += c;
          i++;
        }
        break;
    }
  }
  switch(mode) { // EOF handling
    case "initial": break;
    case "unquoted-string":
      tokens.push({ type: "string", value: stringToken });
      break;
    case "quoted-string":
      return { searchQueryParseError: "Unclosed quoted-string. Missing '\"' character." };
  }
  return tokens;
}

const isSpaceCharacter = (c: string) => " 　\t\r\n".indexOf(c) !== -1;
