import { useEffect, useState } from "react";
import { useApp } from "..";
import { Media } from "../model/Media";

export default function useMedia(m: string | Media | null): Media | null {
  const id = (typeof(m) === "string") ? m : m?.id;
  const { media: Media } = useApp();
  const [ media, setMedia ] = useState((typeof(m) !== "string") ? m : null);
  useEffect(() => {
    if (!id) return;
    if (media === null || media.id !== id) (async () => setMedia(await Media.get(id)))();
  }, [ Media, media, id ]);
  useEffect(() => {
    if (!id) return;
    const handle = Media.addListenerOnMedia(id, "changed", async () => setMedia(await Media.get(id)));
    return () => handle.remove();
  }, [ Media, id ]);
  return media;
}
