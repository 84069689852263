import * as SentrySDK from "@sentry/react";
import { BuildConstants } from "./BuildConstants";

export default class Sentry {
  constructor(deps: {
    buildConstants: BuildConstants,
  }) {
    const { NODE_ENV, REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_RELEASE } = deps.buildConstants;
    if (NODE_ENV === 'production' && REACT_APP_SENTRY_RELEASE) {
      SentrySDK.init({
        dsn: REACT_APP_SENTRY_DSN,
        release: REACT_APP_SENTRY_RELEASE,
      });
    }
  }
}
