import { CircularProgress } from '@material-ui/core';
import { useCallback } from 'react';
import useSearchQueued from '../hooks/useSearchQueued';
import useSearchResult from '../hooks/useSearchResult';
import { MediaListWithItems } from '../model/List';
import MediaListView from './MediaListView';

// eslint-disable-next-line no-empty-pattern
export default function SearchResultView({ onSelect: _onSelect }: {
  onSelect?: (list: MediaListWithItems) => void,
}): JSX.Element {
  const result = useSearchResult();
  const loading = useSearchQueued();

  const onClick = useCallback((list: MediaListWithItems, index: number) => {
    if (_onSelect) _onSelect(list);
  }, [_onSelect]);

  if (!result || loading) return <>
    <CircularProgress />
  </>;
  if (result.items.length === 0) return <>No matching media found.</>;
  return <>
    <MediaListView list={result} clickToPlay={true} onClick={onClick} />
  </>
}
