import React, { useCallback, useEffect, useState } from "react";
import prettyBytes from 'pretty-bytes';
import { useApp } from "../../../..";
import { Badge, List, ListItem, ListItemIcon, ListItemText, CircularProgress, Typography, makeStyles, Button } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import WarningIcon from '@material-ui/icons/Warning';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme) => ({
  overline: {
    textAlign: "right",
  },
}));

// eslint-disable-next-line no-empty-pattern
export default React.memo(({ setUploadingCount, setFailureCount }: {
  setUploadingCount?: (count: number) => void,
  setFailureCount?: (count: number) => void,
}): JSX.Element => {
  const { uploader } = useApp();
  const [ uploads, setUploads ] = useState(uploader.uploads);
  useEffect(() => {
    const h = uploader.addListener("uploads-changed", () => setUploads(uploader.uploads));
    return () => h.remove();
  }, [ setUploads, uploader ]);

  const uploading = uploads.filter((it) => it.status === "waiting" || it.status === "uploading").reduce((sum) => sum + 1, 0);
  useEffect(() => (setUploadingCount) ? setUploadingCount(uploading) : undefined, [ uploading, setUploadingCount ]);

  const failures = uploads.filter((it) => it.status === "failed").reduce((sum) => sum + 1, 0);
  useEffect(() => (setFailureCount) ? setFailureCount(failures) : undefined, [ failures, setFailureCount ]);
  const retryFailed = useCallback(() => uploader.retryFailed(), [ uploader ]);

  const classes = useStyles();
  return <div>
    <div className={classes.overline}>
      <Typography variant="overline">{uploads.length} uploads</Typography>
      <div>
        {failures === 0 ? null : <Button color="secondary" variant="contained" onClick={retryFailed}>
          Retry failed files
        </Button>}
      </div>
    </div>
    <List>
      {[...uploads].reverse().map((upload, index) => <ListItem key={uploads.length - index}>
        <ListItemIcon>
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={upload.confidential ? <LockIcon /> : <LockOpenIcon />}
          >
            {(upload.status === "uploaded") ? <CloudDoneIcon color="primary" />
            : (upload.status === "failed") ? <WarningIcon color="secondary" />
            : (upload.status === "uploading") ? <CircularProgress />
            : <FileCopyIcon />
            }
          </Badge>
        </ListItemIcon>
        <ListItemText primary={upload.name} secondary={<>
          {prettyBytes(upload.bytes)} {(upload.status === "failed") ? `${upload.error}` : (upload.status === "uploaded") ? `${upload.fileHash} ${upload.contentType}` : null}
        </>} />
      </ListItem>)}
    </List>
  </div>;
});
