import React, { useCallback, useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import Alert, { Color as AlertColor } from '@material-ui/lab/Alert';
import { useApp } from "../../..";

type AppSnackbarParams = {
  at: Date,
  color: AlertColor,
  message: String,
};

export function useAppSnackbar(): [
  JSX.Element,
  (params: Omit<AppSnackbarParams, "at">) => void,
] {
  const { clock } = useApp();
  const [ params, setParams ] = useState<AppSnackbarParams | null>(null);
  return [
    params ? <AppSnackbar {...params} /> : <></>,
    useCallback((params) => setParams({ ...params, at: clock.nowDate }), [ clock ]),
  ];
}

const AppSnackbar = React.memo(({ at, color, message }: AppSnackbarParams) => {
  const [ open, setOpen ] = useState(true);
  useEffect(() => setOpen(true), [ color, message, at ]);
  const onClose = useCallback(() => setOpen(false), []);
  return <Snackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    open={open}
    onClose={onClose}
    autoHideDuration={3000}
  >
    <Alert elevation={6} variant="filled" onClose={onClose} severity={color}>
      {message}
    </Alert>
  </Snackbar>;
});
