import { useEffect, useState } from 'react';
import { useApp } from '..';

export default function usePlayerPlayable(): boolean {
  const { player } = useApp();
  const [ playable, setPlayable ] = useState(player.mediaPlayable);
  useEffect(() => {
    const h = player.addListener("media-playable-changed", () => setPlayable(player.mediaPlayable));
    return () => h.remove();
  }, [ player, setPlayable ]);
  return playable;
}
