import React, { useState } from "react";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { DialogProps } from "..";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FullScreenDialog from "../FullScreenDialog";
import UploadStatusList from "./UploadStatusList";
import FileSelector from "./FileSelector";
import StagedFileList from "./StagedFileList";
import { useApp } from "../../../..";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabs: {
    flex: "0 0 auto",
  },
  container: {
    flex: "1 1 auto",
  },
}));

export default React.memo((props: DialogProps): JSX.Element => {
  const { uploader } = useApp();
  const [ selectedTab, setSelectedTab ] = useState(0);
  const [ staged, setStaged ] = useState(false);
  const [ stagedFiles, setStagedFiles ] = useState<File[]>([]);
  const [ uploadingCount, setUploadingCount ] = useState(0);
  const [ failureCount, setFailureCount ] = useState(0);

  const classes = useStyles();
  return <FullScreenDialog
    icon={<CloudUploadIcon />}
    title="Upload"
    {...props}
    confirmOnClose={stagedFiles.length === 0 && uploadingCount === 0 && failureCount === 0 ? undefined : `Close upload dialog with discarding files? (staged ${stagedFiles.length} files, uploading ${uploadingCount} files, failed ${failureCount} files)`}
  >
    <div className={classes.root}>
      <div className={classes.tabs}>
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Upload" />
          <Tab label="Progress" />
        </Tabs>
      </div>
      <div className={classes.container}>
        <div style={{ display: (selectedTab === 0) ? "initial" : "none" }}>
          <div style={{ display: `${staged ? "none" : "initial"}` }}>
            <FileSelector
              files={stagedFiles}
              setFiles={setStagedFiles}
              onFileSelected={(files) => { setStaged(true); }}
            />
          </div>
          <div style={{ display: `${staged ? "initial" : "none"}` }}>
            {/* IMPORTANT: Keep <StagedFileList> mounted, otherwise could lost user input. */}
            <StagedFileList
              files={stagedFiles}
              onCancel={() => setStaged(false)}
              onUpload={(uploads) => {
                uploads.forEach((upload) => uploader.enqueue(...upload));
                setStaged(false);
                setStagedFiles([]);
                setSelectedTab(1);
              }}
            />
          </div>
        </div>
        <div style={{ display: (selectedTab === 1) ? "initial" : "none" }}>
          <UploadStatusList setUploadingCount={setUploadingCount} setFailureCount={setFailureCount} />
        </div>
      </div>
    </div>
  </FullScreenDialog>;
});
