import { DateTime } from "luxon";
export default class Clock {

  get nowDateTime(): DateTime {
    return DateTime.now();
  }

  get nowDate(): Date { return this.nowDateTime.toJSDate(); }
  get unixEpochMills(): number { return this.nowDateTime.toMillis(); }
}
