import { useEffect, useState } from "react";
import { useApp } from "..";
import Search, { SearchResult } from "../model/Search";

/**
 * Watch output of the search provider.
 *
 * @param search Search provider. By default use App-global instance.
 */
export default function useSearchResult(search?: Search): SearchResult | null {
  const { search: SearchGlobal } = useApp();
  const impl = search ?? SearchGlobal;

  const [ searchResult, setSearchResult ] = useState<SearchResult | null>(impl.result);
  useEffect(() => {
    setSearchResult(impl.result); // Because different implementation given, update state.
    const h = impl.addListener("result-changed", () => {
      setSearchResult(impl.result);
    });
    return () => h.remove();
  }, [ impl ]);

  return searchResult;
}
