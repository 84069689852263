import { useCallback, useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './App.css';
import { LoginGate } from "../../components/LoginGate";
import { makeStyles } from '@material-ui/core';
import AppLargeUI from "./AppLargeUI";
import AppSmallUI from "./AppSmallUI";
import { SearchQuery } from '../../model/Search';
import AppDialogs from "./dialog";
import Snackbars from "./snackbar";
import { useApp } from '../..';
import { AppHotKeys } from "../../components/HotKeys";
import { ListTree } from "../../components/ListTree";
import { MediaListWithItems } from '../../model/List';
import SearchResultView from "../../components/SearchResultView";
import { Dialogs } from "../../components/dialog";
import useSearchResult from '../../hooks/useSearchResult';
import useSearchQueued from '../../hooks/useSearchQueued';

export type AppUIProps = {
  showDialog: (type: AppDialogType) => void,
};
export type AppUIMode = "music" | "movie" | "confidential-films";
const searchQueryTemplates: { [mode in AppUIMode]: Omit<SearchQuery, "query"> } = {
  music: { types: [ "audio" ], confidential: false },
  movie: { types: [ "video" ], confidential: false },
  "confidential-films": { types: [ "image", "video" ], confidential: true },
};

export type AppState = {
  mode: AppUIMode,
  searchQuery: Omit<SearchQuery, "query">,
};
export function useAppMode(): [AppState, (mode: AppUIMode) => void] {
  const { search: Search } = useApp();
  const [ mode, _setMode ] = useState<AppUIMode>("music");
  const setMode = useCallback((mode: AppUIMode) => {
    Search.clearSearch();
    _setMode(mode);
  }, [ Search ]);
  return [ {
    mode,
    searchQuery: { ...searchQueryTemplates[mode] },
  }, setMode ];
}

export type AppDialogType = "upload" | "tasks";

// https://github.com/mui-org/material-ui/issues/22594
const dartScrollBar = {
  track: '#202022',
  thumb: '#585859',
  active: '#838384',
} as const;

export const toolbarHeight = "64px";
export const uiStylesBase = makeStyles(theme => ({
  '@global': {
    // Next version of Material-UI supports this: https://github.com/mui-org/material-ui/pull/24780/files
    // Until then, using this workaround: https://github.com/mui-org/material-ui/issues/22594
    ...(theme.palette.type === 'dark' ? {
      scrollbarColor: `${dartScrollBar.thumb} ${dartScrollBar.track}`,
      '*::-webkit-scrollbar': {
        backgroundColor: dartScrollBar.track,
      },
      '*::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: dartScrollBar.thumb,
        minHeight: 24,
        border: `3px solid ${dartScrollBar.track}`,
      },
      '*::-webkit-scrollbar-thumb:focus': {
        backgroundColor: dartScrollBar.active,
      },
      '*::-webkit-scrollbar-thumb:active': {
        backgroundColor: dartScrollBar.active,
      },
      '*::-webkit-scrollbar-corner': {
        backgroundColor: dartScrollBar.track,
      },
    }: {}),
  },
  root: {},
  toolBarButtons: {
    display: "flex",
    flexGrow: 0,
  },
  toolBarGrow: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 2,
    overflow: "hidden",

    "&.clickable:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.dark,
    },
  },
  mediaPlayer: {
    display: "flex",
    flexDirection: "column",
  },
  playerContainer: {
    background: theme.palette.background.default,
    flex: "0 0 auto",
  },
  mediaListContainer: {
    flex: "1 1 auto",
    minHeight: "100px",
  },
}));

const useAppStyles = makeStyles({
  root: {
    height: "100%",
  },
});

export default function App(): JSX.Element {
  const { firebase } = useApp();
  const classes = useAppStyles();
  const isLargeWindow = useMediaQuery("(min-width:425px)"); // 425 = "Mobile L" (Chrome dev tools)

  const [firebaseInitialized, setFirebaseInitialized] = useState<boolean>();
  useEffect(() => {
    (async () => {
      try {
        await firebase.init();
        setFirebaseInitialized(true);
      } catch (e) {
        console.error("Failed to initialize Firebase", e);
        alert(`Failed to initialize Firebase: ${e}`);
      }
    })()
  }, [setFirebaseInitialized, firebase]);

  const [ dialog, setDialog ] = useState<AppDialogType | null>(null);
  const closeDialog = useCallback(() => setDialog(null), [ setDialog ]);

  if (!firebaseInitialized) {
    return <div>Initializing firebase...</div>;
  }
  const uiProps: AppUIProps = { showDialog: setDialog };
  return <LoginGate content={() => <div className={classes.root}>
    <Dialogs>
      <AppHotKeys>
        {isLargeWindow ? <AppLargeUI {...uiProps} /> : <AppSmallUI {...uiProps} />}
        <AppDialogs showDialog={dialog} onClose={closeDialog} />
        <Snackbars />
      </AppHotKeys>
    </Dialogs>
  </div>} />
}

export function ListNavigation(props: AppState & {
  onSelect: (mediaList: MediaListWithItems) => void,
}): JSX.Element {
  const searchResult = useSearchResult();
  const searchLoading = useSearchQueued();
  if (searchResult !== null || searchLoading) return <SearchResultView onSelect={props.onSelect} />;
  return <ListTree types={props.searchQuery.types} showRootFolder={true} onSelect={props.onSelect} />;
}
