import { useEffect, useState } from 'react';
import { useApp } from '..';
import { MediaListWithItems } from '../model/List';
import useMediaList from "./useMediaList";

export default function useCurrentMediaList(): MediaListWithItems | null {
  const { player } = useApp();
  const [ currentList, setCurrentList ] = useState(player.currentList);
  useEffect(() => {
    const h = player.addListener("current-list-changed", () => setCurrentList(player.currentList));
    return () => h.remove();
  }, [ player, setCurrentList ]);
  return useMediaList(currentList);
}
