/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Attributes
 */
export interface Attributes {
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    dateAdded?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Attributes
     */
    hidden: boolean;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    artist?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    musicComposer?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    albumName?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    albumArtist?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    musicGenre?: string;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    discNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    discCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    trackNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    trackCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    releaseYear?: number;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    releaseAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    lyrics?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    gameBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    movieType?: AttributesMovieTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Attributes
     */
    geohash?: string;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    latitudeSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    longitudeSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof Attributes
     */
    altitudeMeters?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AttributesMovieTypeEnum {
    GameOp = 'GameOP',
    GameEd = 'GameED',
    GameDemo = 'GameDemo',
    GameOthers = 'GameOthers',
    AnimeOp = 'AnimeOP',
    AnimeEd = 'AnimeED',
    AnimeOthers = 'AnimeOthers'
}

/**
 * 
 * @export
 * @interface AudioFileInfo
 */
export interface AudioFileInfo extends FileInfo {
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfo
     */
    containerFormat: AudioFileInfoContainerFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfo
     */
    codingFormat: AudioFileInfoCodingFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfo
     */
    codingProfile?: string;
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfo
     */
    duration: string;
    /**
     * 
     * @type {number}
     * @memberof AudioFileInfo
     */
    sampleRateHz: number;
    /**
     * 
     * @type {number}
     * @memberof AudioFileInfo
     */
    sampleBitDepth: number;
    /**
     * 
     * @type {number}
     * @memberof AudioFileInfo
     */
    audioChannels: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AudioFileInfoContainerFormatEnum {
    Mp4 = 'MP4',
    MpegTs = 'MpegTS',
    MpegPs = 'MpegPS',
    Matroska = 'Matroska',
    Ogg = 'Ogg',
    Avi = 'AVI',
    Flv = 'FLV',
    Aac = 'AAC',
    Mp3 = 'MP3',
    Flac = 'FLAC',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum AudioFileInfoCodingFormatEnum {
    Flac = 'FLAC',
    Alac = 'ALAC',
    Aac = 'AAC',
    Opus = 'Opus',
    Mp3 = 'MP3',
    Vorbis = 'Vorbis',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface AudioFileInfoAllOf
 */
export interface AudioFileInfoAllOf {
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfoAllOf
     */
    containerFormat?: AudioFileInfoAllOfContainerFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfoAllOf
     */
    codingFormat?: AudioFileInfoAllOfCodingFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfoAllOf
     */
    codingProfile?: string;
    /**
     * 
     * @type {string}
     * @memberof AudioFileInfoAllOf
     */
    duration?: string;
    /**
     * 
     * @type {number}
     * @memberof AudioFileInfoAllOf
     */
    sampleRateHz?: number;
    /**
     * 
     * @type {number}
     * @memberof AudioFileInfoAllOf
     */
    sampleBitDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof AudioFileInfoAllOf
     */
    audioChannels?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AudioFileInfoAllOfContainerFormatEnum {
    Mp4 = 'MP4',
    MpegTs = 'MpegTS',
    MpegPs = 'MpegPS',
    Matroska = 'Matroska',
    Ogg = 'Ogg',
    Avi = 'AVI',
    Flv = 'FLV',
    Aac = 'AAC',
    Mp3 = 'MP3',
    Flac = 'FLAC',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum AudioFileInfoAllOfCodingFormatEnum {
    Flac = 'FLAC',
    Alac = 'ALAC',
    Aac = 'AAC',
    Opus = 'Opus',
    Mp3 = 'MP3',
    Vorbis = 'Vorbis',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface AuthStatus
 */
export interface AuthStatus {
    /**
     * 
     * @type {string}
     * @memberof AuthStatus
     */
    principal: string;
    /**
     * 
     * @type {string}
     * @memberof AuthStatus
     */
    expireAt: string;
}
/**
 * 
 * @export
 * @interface CreateMediaListRequestBody
 */
export interface CreateMediaListRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateMediaListRequestBody
     */
    parentID: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMediaListRequestBody
     */
    mediaType: CreateMediaListRequestBodyMediaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateMediaListRequestBody
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMediaListRequestBody
     */
    items: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateMediaListRequestBodyMediaTypeEnum {
    Audio = 'audio',
    Image = 'image',
    Video = 'video'
}

/**
 * 
 * @export
 * @interface CreateSmartMediaListRequestBody
 */
export interface CreateSmartMediaListRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateSmartMediaListRequestBody
     */
    parentID: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSmartMediaListRequestBody
     */
    mediaType: CreateSmartMediaListRequestBodyMediaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSmartMediaListRequestBody
     */
    name: string;
    /**
     * 
     * @type {SearchQuery}
     * @memberof CreateSmartMediaListRequestBody
     */
    query: SearchQuery;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateSmartMediaListRequestBodyMediaTypeEnum {
    Audio = 'audio',
    Image = 'image',
    Video = 'video'
}

/**
 * 
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    sizeBytes?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    type: string;
    /**
     * 
     * @type {any}
     * @memberof FileInfo
     */
    file: any;
    /**
     * 
     * @type {boolean}
     * @memberof FileInfo
     */
    confidential: boolean;
}
/**
 * 
 * @export
 * @interface FileURL
 */
export interface FileURL {
    /**
     * 
     * @type {string}
     * @memberof FileURL
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof FileURL
     */
    validUntil: string;
}
/**
 * 
 * @export
 * @interface GpsDataPoint
 */
export interface GpsDataPoint {
    /**
     * LongFraction
     * @type {string}
     * @memberof GpsDataPoint
     */
    latitudeSeconds: string;
    /**
     * LongFraction
     * @type {string}
     * @memberof GpsDataPoint
     */
    longitudeSeconds: string;
    /**
     * LongFraction
     * @type {string}
     * @memberof GpsDataPoint
     */
    altitudeMeters?: string;
    /**
     * LongFraction
     * @type {string}
     * @memberof GpsDataPoint
     */
    horizontalErrorMeter?: string;
    /**
     * 
     * @type {string}
     * @memberof GpsDataPoint
     */
    datum?: string;
    /**
     * LongFraction
     * @type {string}
     * @memberof GpsDataPoint
     */
    speedKmH?: string;
    /**
     * 
     * @type {string}
     * @memberof GpsDataPoint
     */
    geohash: string;
}
/**
 * 
 * @export
 * @interface HistoriaHistory
 */
export interface HistoriaHistory {
    /**
     * 
     * @type {string}
     * @memberof HistoriaHistory
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof HistoriaHistory
     */
    deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof HistoriaHistory
     */
    at: string;
    /**
     * 
     * @type {HistoriaHistoryCategory}
     * @memberof HistoriaHistory
     */
    category: HistoriaHistoryCategory;
    /**
     * May contain LF character.
     * @type {string}
     * @memberof HistoriaHistory
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof HistoriaHistory
     */
    stars: number;
    /**
     * 
     * @type {number}
     * @memberof HistoriaHistory
     */
    days?: number;
    /**
     * If null, use category value.
     * @type {boolean}
     * @memberof HistoriaHistory
     */
    photogenic?: boolean;
}
/**
 * 
 * @export
 * @interface HistoriaHistoryCategory
 */
export interface HistoriaHistoryCategory {
    /**
     * 
     * @type {string}
     * @memberof HistoriaHistoryCategory
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof HistoriaHistoryCategory
     */
    deleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof HistoriaHistoryCategory
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof HistoriaHistoryCategory
     */
    photogenic: boolean;
}
/**
 * 
 * @export
 * @interface History
 */
export interface History {
    /**
     * 
     * @type {HistoryMetadata}
     * @memberof History
     */
    metadata: HistoryMetadata;
    /**
     * 
     * @type {string}
     * @memberof History
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof History
     */
    type: string;
}
/**
 * 
 * @export
 * @interface HistoryMetadata
 */
export interface HistoryMetadata {
    /**
     * 
     * @type {string}
     * @memberof HistoryMetadata
     */
    at: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryMetadata
     */
    player?: HistoryMetadataPlayerEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoryMetadata
     */
    migratedFrom?: HistoryMetadataMigratedFromEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum HistoryMetadataPlayerEnum {
    WebBrowser = 'WebBrowser',
    MobileNativeApp = 'MobileNativeApp',
    Test = 'Test'
}
/**
    * @export
    * @enum {string}
    */
export enum HistoryMetadataMigratedFromEnum {
    ITynesLibraryXml = 'ITynesLibraryXML'
}

/**
 * 
 * @export
 * @interface ITunesTrack
 */
export interface ITunesTrack {
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    trackID: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    sizeBytes: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    totalTimeMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    discNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    discCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    trackNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    trackCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    dateModified: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    dateAdded: string;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    bitRateKBps?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    sampleRateHz?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    playCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    playDateNumeric?: number;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    playDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    skipCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    skipDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    rawRating?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    loved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    rawAlbumRating?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    albumRatingComputed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    artworkCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    persistentID: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    type: ITunesTrackTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    fileFolderCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    libraryFolderCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    artist?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    composer?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    album?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    genre?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    kind: ITunesTrackKindEnum;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    location?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    ratingComputed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    disabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    bpm?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    compilation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    sortName?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    sortArtist?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    fileType?: ITunesTrackFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    grouping?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    albumArtist?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    sortAlbum?: string;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    volumeAdjustment?: number;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    sortComposer?: string;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    work?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    movie?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    hasVideo?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    startTime?: number;
    /**
     * 
     * @type {number}
     * @memberof ITunesTrack
     */
    stopTime?: number;
    /**
     * 
     * @type {string}
     * @memberof ITunesTrack
     */
    releaseDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    podcast?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    unplayed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    purchased?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    _protected?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ITunesTrack
     */
    musicVideo?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ITunesTrackTypeEnum {
    File = 'File',
    Url = 'URL',
    Remote = 'Remote'
}
/**
    * @export
    * @enum {string}
    */
export enum ITunesTrackKindEnum {
    Mp3 = 'MP3',
    Aac = 'AAC',
    M4A = 'M4A',
    Alac = 'ALAC',
    Wav = 'WAV',
    AacProtected = 'AACProtected',
    AacPurchased = 'AACPurchased',
    Mp4 = 'MP4',
    Mov = 'MOV'
}
/**
    * @export
    * @enum {string}
    */
export enum ITunesTrackFileTypeEnum {
    _1295270176 = '1295270176'
}

/**
 * 
 * @export
 * @interface ImageFileInfo
 */
export interface ImageFileInfo extends FileInfo {
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfo
     */
    format: ImageFileInfoFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof ImageFileInfo
     */
    widthPx: number;
    /**
     * 
     * @type {number}
     * @memberof ImageFileInfo
     */
    heightPx: number;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfo
     */
    cameraMaker?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfo
     */
    cameraModel?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfo
     */
    softwareVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfo
     */
    computer?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfo
     */
    orientation: ImageFileInfoOrientationEnum;
    /**
     * 
     * @type {GpsDataPoint}
     * @memberof ImageFileInfo
     */
    gps?: GpsDataPoint;
}

/**
    * @export
    * @enum {string}
    */
export enum ImageFileInfoFormatEnum {
    Jpeg = 'JPEG',
    Png = 'PNG',
    Gif = 'GIF',
    WebP = 'WebP',
    Heic = 'HEIC',
    Tiff = 'TIFF',
    Svg = 'SVG',
    Bitmap = 'Bitmap'
}
/**
    * @export
    * @enum {string}
    */
export enum ImageFileInfoOrientationEnum {
    Unknown = 'UNKNOWN',
    Aligned = 'ALIGNED',
    FlipX = 'FLIP_X',
    Rotate180 = 'ROTATE_180',
    FlipY = 'FLIP_Y',
    Rotate90ThenFlipX = 'ROTATE_90_THEN_FLIP_X',
    Rotate90 = 'ROTATE_90',
    Rotate270ThenFlipX = 'ROTATE_270_THEN_FLIP_X',
    Rotate270 = 'ROTATE_270'
}

/**
 * 
 * @export
 * @interface ImageFileInfoAllOf
 */
export interface ImageFileInfoAllOf {
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfoAllOf
     */
    format?: ImageFileInfoAllOfFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof ImageFileInfoAllOf
     */
    widthPx?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageFileInfoAllOf
     */
    heightPx?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfoAllOf
     */
    cameraMaker?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfoAllOf
     */
    cameraModel?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfoAllOf
     */
    softwareVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfoAllOf
     */
    computer?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageFileInfoAllOf
     */
    orientation?: ImageFileInfoAllOfOrientationEnum;
    /**
     * 
     * @type {GpsDataPoint}
     * @memberof ImageFileInfoAllOf
     */
    gps?: GpsDataPoint;
}

/**
    * @export
    * @enum {string}
    */
export enum ImageFileInfoAllOfFormatEnum {
    Jpeg = 'JPEG',
    Png = 'PNG',
    Gif = 'GIF',
    WebP = 'WebP',
    Heic = 'HEIC',
    Tiff = 'TIFF',
    Svg = 'SVG',
    Bitmap = 'Bitmap'
}
/**
    * @export
    * @enum {string}
    */
export enum ImageFileInfoAllOfOrientationEnum {
    Unknown = 'UNKNOWN',
    Aligned = 'ALIGNED',
    FlipX = 'FLIP_X',
    Rotate180 = 'ROTATE_180',
    FlipY = 'FLIP_Y',
    Rotate90ThenFlipX = 'ROTATE_90_THEN_FLIP_X',
    Rotate90 = 'ROTATE_90',
    Rotate270ThenFlipX = 'ROTATE_270_THEN_FLIP_X',
    Rotate270 = 'ROTATE_270'
}

/**
 * 
 * @export
 * @interface ImageTiling
 */
export interface ImageTiling {
    /**
     * 
     * @type {number}
     * @memberof ImageTiling
     */
    tiles: number;
    /**
     * 
     * @type {string}
     * @memberof ImageTiling
     */
    tileInterval: string;
    /**
     * 
     * @type {number}
     * @memberof ImageTiling
     */
    tileX: number;
    /**
     * 
     * @type {number}
     * @memberof ImageTiling
     */
    tileY: number;
    /**
     * 
     * @type {number}
     * @memberof ImageTiling
     */
    tileWidth: number;
    /**
     * 
     * @type {number}
     * @memberof ImageTiling
     */
    tileHeight: number;
}
/**
 * 
 * @export
 * @interface ListEditHistory
 */
export interface ListEditHistory extends History {
    /**
     * 
     * @type {string}
     * @memberof ListEditHistory
     */
    listId: string;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistory
     */
    editType: ListEditHistoryEditTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistory
     */
    oldValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistory
     */
    newValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistory
     */
    mediaId?: string;
    /**
     * 
     * @type {number}
     * @memberof ListEditHistory
     */
    mediaIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ListEditHistory
     */
    mediaIndexOld?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ListEditHistoryEditTypeEnum {
    CreateNode = 'CreateNode',
    DisableNode = 'DisableNode',
    MoveToAnotherParent = 'MoveToAnotherParent',
    ParentChangeDueToDisable = 'ParentChangeDueToDisable',
    Rename = 'Rename',
    InsertChildMedia = 'InsertChildMedia',
    RemoveChildMedia = 'RemoveChildMedia',
    MoveChildMedia = 'MoveChildMedia',
    SmartListQueryChange = 'SmartListQueryChange'
}

/**
 * 
 * @export
 * @interface ListEditHistoryAllOf
 */
export interface ListEditHistoryAllOf {
    /**
     * 
     * @type {string}
     * @memberof ListEditHistoryAllOf
     */
    listId?: string;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistoryAllOf
     */
    editType?: ListEditHistoryAllOfEditTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistoryAllOf
     */
    oldValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistoryAllOf
     */
    newValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ListEditHistoryAllOf
     */
    mediaId?: string;
    /**
     * 
     * @type {number}
     * @memberof ListEditHistoryAllOf
     */
    mediaIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ListEditHistoryAllOf
     */
    mediaIndexOld?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ListEditHistoryAllOfEditTypeEnum {
    CreateNode = 'CreateNode',
    DisableNode = 'DisableNode',
    MoveToAnotherParent = 'MoveToAnotherParent',
    ParentChangeDueToDisable = 'ParentChangeDueToDisable',
    Rename = 'Rename',
    InsertChildMedia = 'InsertChildMedia',
    RemoveChildMedia = 'RemoveChildMedia',
    MoveChildMedia = 'MoveChildMedia',
    SmartListQueryChange = 'SmartListQueryChange'
}

/**
 * 
 * @export
 * @interface ListFolder
 */
export interface ListFolder extends ListTreeNode {
}
/**
 * 
 * @export
 * @interface ListGetResult
 */
export interface ListGetResult {
    /**
     * 
     * @type {ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems}
     * @memberof ListGetResult
     */
    node: ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems;
    /**
     * 
     * @type {Set<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems>}
     * @memberof ListGetResult
     */
    subLists?: Set<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems>;
}
/**
 * 
 * @export
 * @interface ListTreeNode
 */
export interface ListTreeNode {
    /**
     * 
     * @type {string}
     * @memberof ListTreeNode
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ListTreeNode
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListTreeNode
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ListTreeNode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ListTreeNode
     */
    type: string;
}
/**
 * 
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    id: string;
    /**
     * 
     * @type {MediaFile}
     * @memberof Media
     */
    file: MediaFile;
    /**
     * 
     * @type {boolean}
     * @memberof Media
     */
    confidential: boolean;
    /**
     * 
     * @type {Array<MediaFile>}
     * @memberof Media
     */
    artworks: Array<MediaFile>;
    /**
     * 
     * @type {Array<MediaThumbnail>}
     * @memberof Media
     */
    thumbnails: Array<MediaThumbnail>;
    /**
     * 
     * @type {Attributes}
     * @memberof Media
     */
    attributes: Attributes;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    type: string;
}
/**
 * 
 * @export
 * @interface MediaAttributeChangeHistory
 */
export interface MediaAttributeChangeHistory extends History {
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistory
     */
    mediaID: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistory
     */
    attribute: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistory
     */
    oldValue?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistory
     */
    newValue?: string;
}
/**
 * 
 * @export
 * @interface MediaAttributeChangeHistoryAllOf
 */
export interface MediaAttributeChangeHistoryAllOf {
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistoryAllOf
     */
    mediaID?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistoryAllOf
     */
    attribute?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistoryAllOf
     */
    oldValue?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaAttributeChangeHistoryAllOf
     */
    newValue?: string;
}
/**
 * 
 * @export
 * @interface MediaFile
 */
export interface MediaFile {
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    fileHash: string;
    /**
     * 
     * @type {Array<FileInfo>}
     * @memberof MediaFile
     */
    files: Array<FileInfo>;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    type: string;
}
/**
 * 
 * @export
 * @interface MediaFileInfo
 */
export interface MediaFileInfo {
    /**
     * 
     * @type {string}
     * @memberof MediaFileInfo
     */
    id: string;
    /**
     * 
     * @type {MediaFile}
     * @memberof MediaFileInfo
     */
    file: MediaFile;
    /**
     * 
     * @type {boolean}
     * @memberof MediaFileInfo
     */
    confidential: boolean;
    /**
     * 
     * @type {Array<MediaFile>}
     * @memberof MediaFileInfo
     */
    artworks: Array<MediaFile>;
    /**
     * 
     * @type {Array<MediaThumbnail>}
     * @memberof MediaFileInfo
     */
    thumbnails: Array<MediaThumbnail>;
    /**
     * 
     * @type {Attributes}
     * @memberof MediaFileInfo
     */
    attributes: Attributes;
    /**
     * 
     * @type {string}
     * @memberof MediaFileInfo
     */
    type: string;
}
/**
 * 
 * @export
 * @interface MediaList
 */
export interface MediaList extends ListTreeNode {
    /**
     * 
     * @type {string}
     * @memberof MediaList
     */
    mediaType: string;
    /**
     * 
     * @type {Array<Media>}
     * @memberof MediaList
     */
    items: Array<Media>;
    /**
     * 
     * @type {boolean}
     * @memberof MediaList
     */
    mobileSync: boolean;
}
/**
 * 
 * @export
 * @interface MediaListAllOf
 */
export interface MediaListAllOf {
    /**
     * 
     * @type {string}
     * @memberof MediaListAllOf
     */
    mediaType?: string;
    /**
     * 
     * @type {Array<Media>}
     * @memberof MediaListAllOf
     */
    items?: Array<Media>;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListAllOf
     */
    mobileSync?: boolean;
}
/**
 * 
 * @export
 * @interface MediaListFileInfo
 */
export interface MediaListFileInfo {
    /**
     * 
     * @type {string}
     * @memberof MediaListFileInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MediaListFileInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaListFileInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListFileInfo
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MediaListFileInfo
     */
    mediaType: string;
    /**
     * 
     * @type {Array<Media>}
     * @memberof MediaListFileInfo
     */
    items: Array<Media>;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListFileInfo
     */
    mobileSync: boolean;
}
/**
 * 
 * @export
 * @interface MediaListMobileSyncHistory
 */
export interface MediaListMobileSyncHistory extends History {
    /**
     * 
     * @type {string}
     * @memberof MediaListMobileSyncHistory
     */
    listId: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListMobileSyncHistory
     */
    oldValue: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListMobileSyncHistory
     */
    newValue: boolean;
}
/**
 * 
 * @export
 * @interface MediaListMobileSyncHistoryAllOf
 */
export interface MediaListMobileSyncHistoryAllOf {
    /**
     * 
     * @type {string}
     * @memberof MediaListMobileSyncHistoryAllOf
     */
    listId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListMobileSyncHistoryAllOf
     */
    oldValue?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListMobileSyncHistoryAllOf
     */
    newValue?: boolean;
}
/**
 * 
 * @export
 * @interface MediaListWithoutItems
 */
export interface MediaListWithoutItems extends ListTreeNode {
    /**
     * 
     * @type {string}
     * @memberof MediaListWithoutItems
     */
    mediaType: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListWithoutItems
     */
    mobileSync: boolean;
    /**
     * 
     * @type {number}
     * @memberof MediaListWithoutItems
     */
    itemsCount: number;
}
/**
 * 
 * @export
 * @interface MediaListWithoutItemsAllOf
 */
export interface MediaListWithoutItemsAllOf {
    /**
     * 
     * @type {string}
     * @memberof MediaListWithoutItemsAllOf
     */
    mediaType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaListWithoutItemsAllOf
     */
    mobileSync?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MediaListWithoutItemsAllOf
     */
    itemsCount?: number;
}
/**
 * 
 * @export
 * @interface MediaPlayHistory
 */
export interface MediaPlayHistory extends History {
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistory
     */
    mediaId: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistory
     */
    fileHash: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistory
     */
    playStartedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistory
     */
    playEndedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistory
     */
    playbackTotalLength?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaPlayHistory
     */
    playbackCount: number;
}
/**
 * 
 * @export
 * @interface MediaPlayHistoryAllOf
 */
export interface MediaPlayHistoryAllOf {
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistoryAllOf
     */
    mediaId?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistoryAllOf
     */
    fileHash?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistoryAllOf
     */
    playStartedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistoryAllOf
     */
    playEndedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaPlayHistoryAllOf
     */
    playbackTotalLength?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaPlayHistoryAllOf
     */
    playbackCount?: number;
}
/**
 * 
 * @export
 * @interface MediaThumbnail
 */
export interface MediaThumbnail {
    /**
     * 
     * @type {string}
     * @memberof MediaThumbnail
     */
    id: string;
    /**
     * 
     * @type {MediaFile}
     * @memberof MediaThumbnail
     */
    imageFile: MediaFile;
    /**
     * 
     * @type {string}
     * @memberof MediaThumbnail
     */
    offsetAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MediaThumbnail
     */
    artworkIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaThumbnail
     */
    score?: number;
    /**
     * 
     * @type {ImageTiling}
     * @memberof MediaThumbnail
     */
    tiling?: ImageTiling;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    hash: string;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    bytes: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    confidential: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    specialFileNameKind?: ModelFileSpecialFileNameKindEnum;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    specialFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    modifiedAt?: string;
    /**
     * 
     * @type {Attributes}
     * @memberof ModelFile
     */
    derivedAttributes?: Attributes;
}

/**
    * @export
    * @enum {string}
    */
export enum ModelFileSpecialFileNameKindEnum {
    GameMovie = 'GAME_MOVIE'
}

/**
 * 
 * @export
 * @interface ModifyResult
 */
export interface ModifyResult {
    /**
     * 
     * @type {number}
     * @memberof ModifyResult
     */
    count: number;
}
/**
 * 
 * @export
 * @interface SearchCriterion
 */
export interface SearchCriterion {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterion
     */
    t: string;
}
/**
 * Partial match to any attribute
 * @export
 * @interface SearchCriterionAnyKeyword
 */
export interface SearchCriterionAnyKeyword extends SearchCriterion {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionAnyKeyword
     */
    v: string;
}
/**
 * 
 * @export
 * @interface SearchCriterionAnyKeywordAllOf
 */
export interface SearchCriterionAnyKeywordAllOf {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionAnyKeywordAllOf
     */
    v?: string;
}
/**
 * Partial match to the attribute
 * @export
 * @interface SearchCriterionAttributeKeyword
 */
export interface SearchCriterionAttributeKeyword extends SearchCriterion {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionAttributeKeyword
     */
    a: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionAttributeKeyword
     */
    v: string;
}
/**
 * 
 * @export
 * @interface SearchCriterionAttributeKeywordAllOf
 */
export interface SearchCriterionAttributeKeywordAllOf {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionAttributeKeywordAllOf
     */
    a?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionAttributeKeywordAllOf
     */
    v?: string;
}
/**
 * Filter by confidential attribute
 * @export
 * @interface SearchCriterionConfidentialFlag
 */
export interface SearchCriterionConfidentialFlag extends SearchCriterion {
    /**
     * 
     * @type {boolean}
     * @memberof SearchCriterionConfidentialFlag
     */
    b?: boolean;
}
/**
 * 
 * @export
 * @interface SearchCriterionConfidentialFlagAllOf
 */
export interface SearchCriterionConfidentialFlagAllOf {
    /**
     * 
     * @type {boolean}
     * @memberof SearchCriterionConfidentialFlagAllOf
     */
    b?: boolean;
}
/**
 * Find by range of DateAdded attribute.
 * @export
 * @interface SearchCriterionDateAdded
 */
export interface SearchCriterionDateAdded extends SearchCriterion {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionDateAdded
     */
    since: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionDateAdded
     */
    until: string;
}
/**
 * 
 * @export
 * @interface SearchCriterionDateAddedAllOf
 */
export interface SearchCriterionDateAddedAllOf {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionDateAddedAllOf
     */
    since?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionDateAddedAllOf
     */
    until?: string;
}
/**
 * Find recently added.
 * @export
 * @interface SearchCriterionDateAddedIn
 */
export interface SearchCriterionDateAddedIn extends SearchCriterion {
    /**
     * 
     * @type {number}
     * @memberof SearchCriterionDateAddedIn
     */
    days: number;
}
/**
 * 
 * @export
 * @interface SearchCriterionDateAddedInAllOf
 */
export interface SearchCriterionDateAddedInAllOf {
    /**
     * 
     * @type {number}
     * @memberof SearchCriterionDateAddedInAllOf
     */
    days?: number;
}
/**
 * Find by prefix of the file hash
 * @export
 * @interface SearchCriterionFileHash
 */
export interface SearchCriterionFileHash extends SearchCriterion {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionFileHash
     */
    h: string;
}
/**
 * 
 * @export
 * @interface SearchCriterionFileHashAllOf
 */
export interface SearchCriterionFileHashAllOf {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionFileHashAllOf
     */
    h?: string;
}
/**
 * Find by prefix of the geohash
 * @export
 * @interface SearchCriterionGeoHash
 */
export interface SearchCriterionGeoHash extends SearchCriterion {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionGeoHash
     */
    h: string;
}
/**
 * Filter by hidden attribute
 * @export
 * @interface SearchCriterionHiddenFlag
 */
export interface SearchCriterionHiddenFlag extends SearchCriterion {
    /**
     * 
     * @type {boolean}
     * @memberof SearchCriterionHiddenFlag
     */
    b?: boolean;
}
/**
 * Find by id.
 * @export
 * @interface SearchCriterionMediaID
 */
export interface SearchCriterionMediaID extends SearchCriterion {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionMediaID
     */
    id: string;
}
/**
 * 
 * @export
 * @interface SearchCriterionMediaIDAllOf
 */
export interface SearchCriterionMediaIDAllOf {
    /**
     * 
     * @type {string}
     * @memberof SearchCriterionMediaIDAllOf
     */
    id?: string;
}
/**
 * Filter by rating attribute
 * @export
 * @interface SearchCriterionRating
 */
export interface SearchCriterionRating extends SearchCriterion {
    /**
     * 
     * @type {number}
     * @memberof SearchCriterionRating
     */
    l: number;
    /**
     * 
     * @type {number}
     * @memberof SearchCriterionRating
     */
    u: number;
}
/**
 * 
 * @export
 * @interface SearchCriterionRatingAllOf
 */
export interface SearchCriterionRatingAllOf {
    /**
     * 
     * @type {number}
     * @memberof SearchCriterionRatingAllOf
     */
    l?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchCriterionRatingAllOf
     */
    u?: number;
}
/**
 * 
 * @export
 * @interface SearchQuery
 */
export interface SearchQuery {
    /**
     * 
     * @type {Array<SearchCriterionAnyKeyword | SearchCriterionAttributeKeyword | SearchCriterionConfidentialFlag | SearchCriterionDateAdded | SearchCriterionDateAddedIn | SearchCriterionFileHash | SearchCriterionGeoHash | SearchCriterionHiddenFlag | SearchCriterionMediaID | SearchCriterionRating>}
     * @memberof SearchQuery
     */
    criteria: Array<SearchCriterionAnyKeyword | SearchCriterionAttributeKeyword | SearchCriterionConfidentialFlag | SearchCriterionDateAdded | SearchCriterionDateAddedIn | SearchCriterionFileHash | SearchCriterionGeoHash | SearchCriterionHiddenFlag | SearchCriterionMediaID | SearchCriterionRating>;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchQuery
     */
    sort?: SearchQuerySortEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchQuerySortEnum {
    DateAdded = 'DateAdded'
}

/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * TYPE of FileInfo.
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    t: Array<string>;
    /**
     * 
     * @type {Array<SearchCriterionAnyKeyword | SearchCriterionAttributeKeyword | SearchCriterionConfidentialFlag | SearchCriterionDateAdded | SearchCriterionDateAddedIn | SearchCriterionFileHash | SearchCriterionGeoHash | SearchCriterionHiddenFlag | SearchCriterionMediaID | SearchCriterionRating>}
     * @memberof SearchRequest
     */
    q: Array<SearchCriterionAnyKeyword | SearchCriterionAttributeKeyword | SearchCriterionConfidentialFlag | SearchCriterionDateAdded | SearchCriterionDateAddedIn | SearchCriterionFileHash | SearchCriterionGeoHash | SearchCriterionHiddenFlag | SearchCriterionMediaID | SearchCriterionRating>;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    sort?: SearchRequestSortEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SearchRequestSortEnum {
    DateAdded = 'DateAdded'
}

/**
 * 
 * @export
 * @interface SmartMediaList
 */
export interface SmartMediaList {
    /**
     * 
     * @type {string}
     * @memberof SmartMediaList
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SmartMediaList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SmartMediaList
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmartMediaList
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SmartMediaList
     */
    mediaType: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmartMediaList
     */
    mobileSync: boolean;
    /**
     * 
     * @type {SearchQuery}
     * @memberof SmartMediaList
     */
    searchQuery: SearchQuery;
}
/**
 * 
 * @export
 * @interface SmartMediaListWithItems
 */
export interface SmartMediaListWithItems extends ListTreeNode {
    /**
     * 
     * @type {string}
     * @memberof SmartMediaListWithItems
     */
    mediaType: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmartMediaListWithItems
     */
    mobileSync: boolean;
    /**
     * 
     * @type {Array<MediaFileInfo>}
     * @memberof SmartMediaListWithItems
     */
    items: Array<MediaFileInfo>;
    /**
     * 
     * @type {SearchQuery}
     * @memberof SmartMediaListWithItems
     */
    searchQuery: SearchQuery;
}
/**
 * 
 * @export
 * @interface SmartMediaListWithItemsAllOf
 */
export interface SmartMediaListWithItemsAllOf {
    /**
     * 
     * @type {string}
     * @memberof SmartMediaListWithItemsAllOf
     */
    mediaType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SmartMediaListWithItemsAllOf
     */
    mobileSync?: boolean;
    /**
     * 
     * @type {Array<MediaFileInfo>}
     * @memberof SmartMediaListWithItemsAllOf
     */
    items?: Array<MediaFileInfo>;
    /**
     * 
     * @type {SearchQuery}
     * @memberof SmartMediaListWithItemsAllOf
     */
    searchQuery?: SearchQuery;
}
/**
 * 
 * @export
 * @interface TaskStat
 */
export interface TaskStat {
    /**
     * 
     * @type {string}
     * @memberof TaskStat
     */
    taskType: string;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    awaiting: number;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    sleeping: number;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    retry: number;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    willRetry: number;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    running: number;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    completed: number;
    /**
     * 
     * @type {number}
     * @memberof TaskStat
     */
    abandoned: number;
}
/**
 * 
 * @export
 * @interface TaskTimingModifyRequest
 */
export interface TaskTimingModifyRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskTimingModifyRequest
     */
    at: string;
    /**
     * 
     * @type {string}
     * @memberof TaskTimingModifyRequest
     */
    taskType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskTimingModifyRequest
     */
    awaitingTasks: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskTimingModifyRequest
     */
    sleepingTasks: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskTimingModifyRequest
     */
    retryTasks: boolean;
}
/**
 * 
 * @export
 * @interface VideoFileInfo
 */
export interface VideoFileInfo extends FileInfo {
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfo
     */
    containerFormat: VideoFileInfoContainerFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfo
     */
    codingFormat: VideoFileInfoCodingFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfo
     */
    codingProfile?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfo
     */
    widthPx: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfo
     */
    heightPx: number;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfo
     */
    duration: string;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfo
     */
    frameRateAverage?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfo
     */
    audioCodingFormat?: VideoFileInfoAudioCodingFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfo
     */
    audioCodingProfile?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfo
     */
    audioSampleRateHz?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfo
     */
    audioSampleBitDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfo
     */
    audioChannels?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VideoFileInfoContainerFormatEnum {
    Mp4 = 'MP4',
    MpegTs = 'MpegTS',
    MpegPs = 'MpegPS',
    Matroska = 'Matroska',
    Ogg = 'Ogg',
    Avi = 'AVI',
    Flv = 'FLV',
    Aac = 'AAC',
    Mp3 = 'MP3',
    Flac = 'FLAC',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum VideoFileInfoCodingFormatEnum {
    H265 = 'H265',
    H264 = 'H264',
    Mpeg4Part2 = 'Mpeg4Part2',
    Vp9 = 'VP9',
    Vp8 = 'VP8',
    Theora = 'Theora',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum VideoFileInfoAudioCodingFormatEnum {
    Flac = 'FLAC',
    Alac = 'ALAC',
    Aac = 'AAC',
    Opus = 'Opus',
    Mp3 = 'MP3',
    Vorbis = 'Vorbis',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface VideoFileInfoAllOf
 */
export interface VideoFileInfoAllOf {
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfoAllOf
     */
    containerFormat?: VideoFileInfoAllOfContainerFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfoAllOf
     */
    codingFormat?: VideoFileInfoAllOfCodingFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfoAllOf
     */
    codingProfile?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfoAllOf
     */
    widthPx?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfoAllOf
     */
    heightPx?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfoAllOf
     */
    duration?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfoAllOf
     */
    frameRateAverage?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfoAllOf
     */
    audioCodingFormat?: VideoFileInfoAllOfAudioCodingFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof VideoFileInfoAllOf
     */
    audioCodingProfile?: string;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfoAllOf
     */
    audioSampleRateHz?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfoAllOf
     */
    audioSampleBitDepth?: number;
    /**
     * 
     * @type {number}
     * @memberof VideoFileInfoAllOf
     */
    audioChannels?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VideoFileInfoAllOfContainerFormatEnum {
    Mp4 = 'MP4',
    MpegTs = 'MpegTS',
    MpegPs = 'MpegPS',
    Matroska = 'Matroska',
    Ogg = 'Ogg',
    Avi = 'AVI',
    Flv = 'FLV',
    Aac = 'AAC',
    Mp3 = 'MP3',
    Flac = 'FLAC',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum VideoFileInfoAllOfCodingFormatEnum {
    H265 = 'H265',
    H264 = 'H264',
    Mpeg4Part2 = 'Mpeg4Part2',
    Vp9 = 'VP9',
    Vp8 = 'VP8',
    Theora = 'Theora',
    Other = 'Other'
}
/**
    * @export
    * @enum {string}
    */
export enum VideoFileInfoAllOfAudioCodingFormatEnum {
    Flac = 'FLAC',
    Alac = 'ALAC',
    Aac = 'AAC',
    Opus = 'Opus',
    Mp3 = 'MP3',
    Vorbis = 'Vorbis',
    Other = 'Other'
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: any): AxiosPromise<AuthStatus> {
            return localVarFp.getStatus(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getStatus(options?: any) {
        return AuthApiFp(this.configuration).getStatus(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get File information
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stat1: async (hash: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('stat1', 'hash', hash)
            const localVarPath = `/api/file/{hash}/stat`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get File information
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stat1(hash: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stat1(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Get File information
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stat1(hash: string, options?: any): AxiosPromise<any> {
            return localVarFp.stat1(hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stat1 operation in FileApi.
 * @export
 * @interface FileApiStat1Request
 */
export interface FileApiStat1Request {
    /**
     * 
     * @type {string}
     * @memberof FileApiStat1
     */
    readonly hash: string
}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Get File information
     * @param {FileApiStat1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public stat1(requestParameters: FileApiStat1Request, options?: any) {
        return FileApiFp(this.configuration).stat1(requestParameters.hash, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HistoriaApi - axios parameter creator
 * @export
 */
export const HistoriaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/historia/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoriaApi - functional programming interface
 * @export
 */
export const HistoriaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoriaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoriaHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoriaApi - factory interface
 * @export
 */
export const HistoriaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoriaApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): AxiosPromise<Array<HistoriaHistory>> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HistoriaApi - object-oriented interface
 * @export
 * @class HistoriaApi
 * @extends {BaseAPI}
 */
export class HistoriaApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriaApi
     */
    public list(options?: any) {
        return HistoriaApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HistoryApi - axios parameter creator
 * @export
 */
export const HistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory} listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put: async (listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory: ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory' is not null or undefined
            assertParamExists('put', 'listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory', listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory)
            const localVarPath = `/api/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoryApi - functional programming interface
 * @export
 */
export const HistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory} listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async put(listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory: ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.put(listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoryApi - factory interface
 * @export
 */
export const HistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory} listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put(listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory: ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory, options?: any): AxiosPromise<object> {
            return localVarFp.put(listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for put operation in HistoryApi.
 * @export
 * @interface HistoryApiPutRequest
 */
export interface HistoryApiPutRequest {
    /**
     * 
     * @type {ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory}
     * @memberof HistoryApiPut
     */
    readonly listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory: ListEditHistory | MediaAttributeChangeHistory | MediaListMobileSyncHistory | MediaPlayHistory
}

/**
 * HistoryApi - object-oriented interface
 * @export
 * @class HistoryApi
 * @extends {BaseAPI}
 */
export class HistoryApi extends BaseAPI {
    /**
     * 
     * @param {HistoryApiPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public put(requestParameters: HistoryApiPutRequest, options?: any) {
        return HistoryApiFp(this.configuration).put(requestParameters.listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get media information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMedia', 'id', id)
            const localVarPath = `/api/media/m/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Overwrite/merge attributes
         * @param {string} id 
         * @param {Attributes} attributes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttributes: async (id: string, attributes: Attributes, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setAttributes', 'id', id)
            // verify required parameter 'attributes' is not null or undefined
            assertParamExists('setAttributes', 'attributes', attributes)
            const localVarPath = `/api/media/m/{id}/attributes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attributes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get media information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedia(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedia(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Overwrite/merge attributes
         * @param {string} id 
         * @param {Attributes} attributes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAttributes(id: string, attributes: Attributes, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAttributes(id, attributes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaApiFp(configuration)
    return {
        /**
         * 
         * @summary Get media information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedia(id: string, options?: any): AxiosPromise<MediaFileInfo> {
            return localVarFp.getMedia(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Overwrite/merge attributes
         * @param {string} id 
         * @param {Attributes} attributes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttributes(id: string, attributes: Attributes, options?: any): AxiosPromise<void> {
            return localVarFp.setAttributes(id, attributes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMedia operation in MediaApi.
 * @export
 * @interface MediaApiGetMediaRequest
 */
export interface MediaApiGetMediaRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaApiGetMedia
     */
    readonly id: string
}

/**
 * Request parameters for setAttributes operation in MediaApi.
 * @export
 * @interface MediaApiSetAttributesRequest
 */
export interface MediaApiSetAttributesRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaApiSetAttributes
     */
    readonly id: string

    /**
     * 
     * @type {Attributes}
     * @memberof MediaApiSetAttributes
     */
    readonly attributes: Attributes
}

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @summary Get media information
     * @param {MediaApiGetMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public getMedia(requestParameters: MediaApiGetMediaRequest, options?: any) {
        return MediaApiFp(this.configuration).getMedia(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Overwrite/merge attributes
     * @param {MediaApiSetAttributesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public setAttributes(requestParameters: MediaApiSetAttributesRequest, options?: any) {
        return MediaApiFp(this.configuration).setAttributes(requestParameters.id, requestParameters.attributes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaListApi - axios parameter creator
 * @export
 */
export const MediaListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Move list to another parent
         * @param {string} id 
         * @param {string} [parentID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeListParent: async (id: string, parentID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeListParent', 'id', id)
            const localVarPath = `/api/media/list/{id}/parent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentID !== undefined) {
                localVarQueryParameter['parentID'] = parentID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} indexFrom 
         * @param {number} indexTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMediaOrder: async (id: string, mediaId: string, indexFrom: number, indexTo: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeMediaOrder', 'id', id)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('changeMediaOrder', 'mediaId', mediaId)
            // verify required parameter 'indexFrom' is not null or undefined
            assertParamExists('changeMediaOrder', 'indexFrom', indexFrom)
            // verify required parameter 'indexTo' is not null or undefined
            assertParamExists('changeMediaOrder', 'indexTo', indexTo)
            const localVarPath = `/api/media/list/{id}/media-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"mediaId"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (indexFrom !== undefined) {
                localVarQueryParameter['indexFrom'] = indexFrom;
            }

            if (indexTo !== undefined) {
                localVarQueryParameter['indexTo'] = indexTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SearchQuery} searchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQuery: async (id: string, searchQuery: SearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeQuery', 'id', id)
            // verify required parameter 'searchQuery' is not null or undefined
            assertParamExists('changeQuery', 'searchQuery', searchQuery)
            const localVarPath = `/api/media/list/{id}/query`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create folder
         * @param {string} id 
         * @param {string} parentID 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: async (id: string, parentID: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createFolder', 'id', id)
            // verify required parameter 'parentID' is not null or undefined
            assertParamExists('createFolder', 'parentID', parentID)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('createFolder', 'name', name)
            const localVarPath = `/api/media/list/{id}/folder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentID !== undefined) {
                localVarQueryParameter['parentID'] = parentID;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create media list (playlist)
         * @param {string} id 
         * @param {CreateMediaListRequestBody} createMediaListRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMediaList: async (id: string, createMediaListRequestBody: CreateMediaListRequestBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createMediaList', 'id', id)
            // verify required parameter 'createMediaListRequestBody' is not null or undefined
            assertParamExists('createMediaList', 'createMediaListRequestBody', createMediaListRequestBody)
            const localVarPath = `/api/media/list/{id}/media-list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMediaListRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create smart media list (playlist)
         * @param {string} id 
         * @param {CreateSmartMediaListRequestBody} createSmartMediaListRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSmartMediaList: async (id: string, createSmartMediaListRequestBody: CreateSmartMediaListRequestBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSmartMediaList', 'id', id)
            // verify required parameter 'createSmartMediaListRequestBody' is not null or undefined
            assertParamExists('createSmartMediaList', 'createSmartMediaListRequestBody', createSmartMediaListRequestBody)
            const localVarPath = `/api/media/list/{id}/smart-media-list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSmartMediaListRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable (delete) list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableList: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('disableList', 'id', id)
            const localVarPath = `/api/media/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get content of playlist or playlist folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNode: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNode', 'id', id)
            const localVarPath = `/api/media/list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get children of ROOT folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoot: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/media/list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add/inert media into list (playlist)
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} [index] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertMediaToList: async (id: string, mediaId: string, index?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('insertMediaToList', 'id', id)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('insertMediaToList', 'mediaId', mediaId)
            const localVarPath = `/api/media/list/{id}/mediums/{mediaId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"mediaId"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete media from list (playlist)
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMediaFromList: async (id: string, mediaId: string, index: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeMediaFromList', 'id', id)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('removeMediaFromList', 'mediaId', mediaId)
            // verify required parameter 'index' is not null or undefined
            assertParamExists('removeMediaFromList', 'index', index)
            const localVarPath = `/api/media/list/{id}/mediums/{mediaId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"mediaId"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rename list / list folder
         * @param {string} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameList: async (id: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('renameList', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('renameList', 'name', name)
            const localVarPath = `/api/media/list/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change mobile sync flag.
         * @param {string} id 
         * @param {boolean} mobileSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMobileSync: async (id: string, mobileSync: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setMobileSync', 'id', id)
            // verify required parameter 'mobileSync' is not null or undefined
            assertParamExists('setMobileSync', 'mobileSync', mobileSync)
            const localVarPath = `/api/media/list/{id}/mobile-sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobileSync !== undefined) {
                localVarQueryParameter['mobileSync'] = mobileSync;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaListApi - functional programming interface
 * @export
 */
export const MediaListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Move list to another parent
         * @param {string} id 
         * @param {string} [parentID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeListParent(id: string, parentID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeListParent(id, parentID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} indexFrom 
         * @param {number} indexTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeMediaOrder(id: string, mediaId: string, indexFrom: number, indexTo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaListFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeMediaOrder(id, mediaId, indexFrom, indexTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SearchQuery} searchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeQuery(id: string, searchQuery: SearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmartMediaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeQuery(id, searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create folder
         * @param {string} id 
         * @param {string} parentID 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFolder(id: string, parentID: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFolder(id, parentID, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create media list (playlist)
         * @param {string} id 
         * @param {CreateMediaListRequestBody} createMediaListRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMediaList(id: string, createMediaListRequestBody: CreateMediaListRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaListFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMediaList(id, createMediaListRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create smart media list (playlist)
         * @param {string} id 
         * @param {CreateSmartMediaListRequestBody} createSmartMediaListRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSmartMediaList(id: string, createSmartMediaListRequestBody: CreateSmartMediaListRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmartMediaList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSmartMediaList(id, createSmartMediaListRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable (delete) list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableList(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get content of playlist or playlist folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNode(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListGetResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNode(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get children of ROOT folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoot(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListGetResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoot(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add/inert media into list (playlist)
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} [index] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertMediaToList(id: string, mediaId: string, index?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaListFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertMediaToList(id, mediaId, index, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete media from list (playlist)
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMediaFromList(id: string, mediaId: string, index: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaListFileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMediaFromList(id, mediaId, index, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rename list / list folder
         * @param {string} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameList(id: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameList(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change mobile sync flag.
         * @param {string} id 
         * @param {boolean} mobileSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMobileSync(id: string, mobileSync: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMobileSync(id, mobileSync, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaListApi - factory interface
 * @export
 */
export const MediaListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaListApiFp(configuration)
    return {
        /**
         * 
         * @summary Move list to another parent
         * @param {string} id 
         * @param {string} [parentID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeListParent(id: string, parentID?: string, options?: any): AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems> {
            return localVarFp.changeListParent(id, parentID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} indexFrom 
         * @param {number} indexTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMediaOrder(id: string, mediaId: string, indexFrom: number, indexTo: number, options?: any): AxiosPromise<MediaListFileInfo> {
            return localVarFp.changeMediaOrder(id, mediaId, indexFrom, indexTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SearchQuery} searchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQuery(id: string, searchQuery: SearchQuery, options?: any): AxiosPromise<SmartMediaList> {
            return localVarFp.changeQuery(id, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create folder
         * @param {string} id 
         * @param {string} parentID 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(id: string, parentID: string, name: string, options?: any): AxiosPromise<ListFolder> {
            return localVarFp.createFolder(id, parentID, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create media list (playlist)
         * @param {string} id 
         * @param {CreateMediaListRequestBody} createMediaListRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMediaList(id: string, createMediaListRequestBody: CreateMediaListRequestBody, options?: any): AxiosPromise<MediaListFileInfo> {
            return localVarFp.createMediaList(id, createMediaListRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create smart media list (playlist)
         * @param {string} id 
         * @param {CreateSmartMediaListRequestBody} createSmartMediaListRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSmartMediaList(id: string, createSmartMediaListRequestBody: CreateSmartMediaListRequestBody, options?: any): AxiosPromise<SmartMediaList> {
            return localVarFp.createSmartMediaList(id, createSmartMediaListRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable (delete) list
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableList(id: string, options?: any): AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems> {
            return localVarFp.disableList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get content of playlist or playlist folder
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNode(id: string, options?: any): AxiosPromise<ListGetResult> {
            return localVarFp.getNode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get children of ROOT folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoot(options?: any): AxiosPromise<ListGetResult> {
            return localVarFp.getRoot(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add/inert media into list (playlist)
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} [index] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertMediaToList(id: string, mediaId: string, index?: number, options?: any): AxiosPromise<MediaListFileInfo> {
            return localVarFp.insertMediaToList(id, mediaId, index, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete media from list (playlist)
         * @param {string} id 
         * @param {string} mediaId 
         * @param {number} index 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMediaFromList(id: string, mediaId: string, index: number, options?: any): AxiosPromise<MediaListFileInfo> {
            return localVarFp.removeMediaFromList(id, mediaId, index, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rename list / list folder
         * @param {string} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameList(id: string, name: string, options?: any): AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems> {
            return localVarFp.renameList(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change mobile sync flag.
         * @param {string} id 
         * @param {boolean} mobileSync 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMobileSync(id: string, mobileSync: boolean, options?: any): AxiosPromise<ListFolder | MediaList | MediaListWithoutItems | SmartMediaList | SmartMediaListWithItems> {
            return localVarFp.setMobileSync(id, mobileSync, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changeListParent operation in MediaListApi.
 * @export
 * @interface MediaListApiChangeListParentRequest
 */
export interface MediaListApiChangeListParentRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiChangeListParent
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MediaListApiChangeListParent
     */
    readonly parentID?: string
}

/**
 * Request parameters for changeMediaOrder operation in MediaListApi.
 * @export
 * @interface MediaListApiChangeMediaOrderRequest
 */
export interface MediaListApiChangeMediaOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiChangeMediaOrder
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MediaListApiChangeMediaOrder
     */
    readonly mediaId: string

    /**
     * 
     * @type {number}
     * @memberof MediaListApiChangeMediaOrder
     */
    readonly indexFrom: number

    /**
     * 
     * @type {number}
     * @memberof MediaListApiChangeMediaOrder
     */
    readonly indexTo: number
}

/**
 * Request parameters for changeQuery operation in MediaListApi.
 * @export
 * @interface MediaListApiChangeQueryRequest
 */
export interface MediaListApiChangeQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiChangeQuery
     */
    readonly id: string

    /**
     * 
     * @type {SearchQuery}
     * @memberof MediaListApiChangeQuery
     */
    readonly searchQuery: SearchQuery
}

/**
 * Request parameters for createFolder operation in MediaListApi.
 * @export
 * @interface MediaListApiCreateFolderRequest
 */
export interface MediaListApiCreateFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiCreateFolder
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MediaListApiCreateFolder
     */
    readonly parentID: string

    /**
     * 
     * @type {string}
     * @memberof MediaListApiCreateFolder
     */
    readonly name: string
}

/**
 * Request parameters for createMediaList operation in MediaListApi.
 * @export
 * @interface MediaListApiCreateMediaListRequest
 */
export interface MediaListApiCreateMediaListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiCreateMediaList
     */
    readonly id: string

    /**
     * 
     * @type {CreateMediaListRequestBody}
     * @memberof MediaListApiCreateMediaList
     */
    readonly createMediaListRequestBody: CreateMediaListRequestBody
}

/**
 * Request parameters for createSmartMediaList operation in MediaListApi.
 * @export
 * @interface MediaListApiCreateSmartMediaListRequest
 */
export interface MediaListApiCreateSmartMediaListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiCreateSmartMediaList
     */
    readonly id: string

    /**
     * 
     * @type {CreateSmartMediaListRequestBody}
     * @memberof MediaListApiCreateSmartMediaList
     */
    readonly createSmartMediaListRequestBody: CreateSmartMediaListRequestBody
}

/**
 * Request parameters for disableList operation in MediaListApi.
 * @export
 * @interface MediaListApiDisableListRequest
 */
export interface MediaListApiDisableListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiDisableList
     */
    readonly id: string
}

/**
 * Request parameters for getNode operation in MediaListApi.
 * @export
 * @interface MediaListApiGetNodeRequest
 */
export interface MediaListApiGetNodeRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiGetNode
     */
    readonly id: string
}

/**
 * Request parameters for insertMediaToList operation in MediaListApi.
 * @export
 * @interface MediaListApiInsertMediaToListRequest
 */
export interface MediaListApiInsertMediaToListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiInsertMediaToList
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MediaListApiInsertMediaToList
     */
    readonly mediaId: string

    /**
     * 
     * @type {number}
     * @memberof MediaListApiInsertMediaToList
     */
    readonly index?: number
}

/**
 * Request parameters for removeMediaFromList operation in MediaListApi.
 * @export
 * @interface MediaListApiRemoveMediaFromListRequest
 */
export interface MediaListApiRemoveMediaFromListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiRemoveMediaFromList
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MediaListApiRemoveMediaFromList
     */
    readonly mediaId: string

    /**
     * 
     * @type {number}
     * @memberof MediaListApiRemoveMediaFromList
     */
    readonly index: number
}

/**
 * Request parameters for renameList operation in MediaListApi.
 * @export
 * @interface MediaListApiRenameListRequest
 */
export interface MediaListApiRenameListRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiRenameList
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof MediaListApiRenameList
     */
    readonly name: string
}

/**
 * Request parameters for setMobileSync operation in MediaListApi.
 * @export
 * @interface MediaListApiSetMobileSyncRequest
 */
export interface MediaListApiSetMobileSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaListApiSetMobileSync
     */
    readonly id: string

    /**
     * 
     * @type {boolean}
     * @memberof MediaListApiSetMobileSync
     */
    readonly mobileSync: boolean
}

/**
 * MediaListApi - object-oriented interface
 * @export
 * @class MediaListApi
 * @extends {BaseAPI}
 */
export class MediaListApi extends BaseAPI {
    /**
     * 
     * @summary Move list to another parent
     * @param {MediaListApiChangeListParentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public changeListParent(requestParameters: MediaListApiChangeListParentRequest, options?: any) {
        return MediaListApiFp(this.configuration).changeListParent(requestParameters.id, requestParameters.parentID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaListApiChangeMediaOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public changeMediaOrder(requestParameters: MediaListApiChangeMediaOrderRequest, options?: any) {
        return MediaListApiFp(this.configuration).changeMediaOrder(requestParameters.id, requestParameters.mediaId, requestParameters.indexFrom, requestParameters.indexTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MediaListApiChangeQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public changeQuery(requestParameters: MediaListApiChangeQueryRequest, options?: any) {
        return MediaListApiFp(this.configuration).changeQuery(requestParameters.id, requestParameters.searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create folder
     * @param {MediaListApiCreateFolderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public createFolder(requestParameters: MediaListApiCreateFolderRequest, options?: any) {
        return MediaListApiFp(this.configuration).createFolder(requestParameters.id, requestParameters.parentID, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create media list (playlist)
     * @param {MediaListApiCreateMediaListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public createMediaList(requestParameters: MediaListApiCreateMediaListRequest, options?: any) {
        return MediaListApiFp(this.configuration).createMediaList(requestParameters.id, requestParameters.createMediaListRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create smart media list (playlist)
     * @param {MediaListApiCreateSmartMediaListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public createSmartMediaList(requestParameters: MediaListApiCreateSmartMediaListRequest, options?: any) {
        return MediaListApiFp(this.configuration).createSmartMediaList(requestParameters.id, requestParameters.createSmartMediaListRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable (delete) list
     * @param {MediaListApiDisableListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public disableList(requestParameters: MediaListApiDisableListRequest, options?: any) {
        return MediaListApiFp(this.configuration).disableList(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get content of playlist or playlist folder
     * @param {MediaListApiGetNodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public getNode(requestParameters: MediaListApiGetNodeRequest, options?: any) {
        return MediaListApiFp(this.configuration).getNode(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get children of ROOT folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public getRoot(options?: any) {
        return MediaListApiFp(this.configuration).getRoot(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add/inert media into list (playlist)
     * @param {MediaListApiInsertMediaToListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public insertMediaToList(requestParameters: MediaListApiInsertMediaToListRequest, options?: any) {
        return MediaListApiFp(this.configuration).insertMediaToList(requestParameters.id, requestParameters.mediaId, requestParameters.index, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete media from list (playlist)
     * @param {MediaListApiRemoveMediaFromListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public removeMediaFromList(requestParameters: MediaListApiRemoveMediaFromListRequest, options?: any) {
        return MediaListApiFp(this.configuration).removeMediaFromList(requestParameters.id, requestParameters.mediaId, requestParameters.index, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rename list / list folder
     * @param {MediaListApiRenameListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public renameList(requestParameters: MediaListApiRenameListRequest, options?: any) {
        return MediaListApiFp(this.configuration).renameList(requestParameters.id, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change mobile sync flag.
     * @param {MediaListApiSetMobileSyncRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaListApi
     */
    public setMobileSync(requestParameters: MediaListApiSetMobileSyncRequest, options?: any) {
        return MediaListApiFp(this.configuration).setMobileSync(requestParameters.id, requestParameters.mobileSync, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaSearchApi - axios parameter creator
 * @export
 */
export const MediaSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Count grouped media
         * @param {'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7'} aggregateBy 
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregatedSearch: async (aggregateBy: 'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7', searchRequest: SearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'aggregateBy' is not null or undefined
            assertParamExists('aggregatedSearch', 'aggregateBy', aggregateBy)
            // verify required parameter 'searchRequest' is not null or undefined
            assertParamExists('aggregatedSearch', 'searchRequest', searchRequest)
            const localVarPath = `/api/media/search/aggregated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (aggregateBy !== undefined) {
                localVarQueryParameter['aggregateBy'] = aggregateBy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search media by query
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (searchRequest: SearchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            assertParamExists('search', 'searchRequest', searchRequest)
            const localVarPath = `/api/media/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaSearchApi - functional programming interface
 * @export
 */
export const MediaSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Count grouped media
         * @param {'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7'} aggregateBy 
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aggregatedSearch(aggregateBy: 'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7', searchRequest: SearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aggregatedSearch(aggregateBy, searchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search media by query
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(searchRequest: SearchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MediaFileInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(searchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaSearchApi - factory interface
 * @export
 */
export const MediaSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaSearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Count grouped media
         * @param {'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7'} aggregateBy 
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aggregatedSearch(aggregateBy: 'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7', searchRequest: SearchRequest, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.aggregatedSearch(aggregateBy, searchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search media by query
         * @param {SearchRequest} searchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(searchRequest: SearchRequest, options?: any): AxiosPromise<Array<MediaFileInfo>> {
            return localVarFp.search(searchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aggregatedSearch operation in MediaSearchApi.
 * @export
 * @interface MediaSearchApiAggregatedSearchRequest
 */
export interface MediaSearchApiAggregatedSearchRequest {
    /**
     * 
     * @type {'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7'}
     * @memberof MediaSearchApiAggregatedSearch
     */
    readonly aggregateBy: 'DateAddedYear' | 'DateAddedMonth' | 'DateAddedDay' | 'GeoHashLv4' | 'GeoHashLv5' | 'GeoHashLv6' | 'GeoHashLv7'

    /**
     * 
     * @type {SearchRequest}
     * @memberof MediaSearchApiAggregatedSearch
     */
    readonly searchRequest: SearchRequest
}

/**
 * Request parameters for search operation in MediaSearchApi.
 * @export
 * @interface MediaSearchApiSearchRequest
 */
export interface MediaSearchApiSearchRequest {
    /**
     * 
     * @type {SearchRequest}
     * @memberof MediaSearchApiSearch
     */
    readonly searchRequest: SearchRequest
}

/**
 * MediaSearchApi - object-oriented interface
 * @export
 * @class MediaSearchApi
 * @extends {BaseAPI}
 */
export class MediaSearchApi extends BaseAPI {
    /**
     * 
     * @summary Count grouped media
     * @param {MediaSearchApiAggregatedSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaSearchApi
     */
    public aggregatedSearch(requestParameters: MediaSearchApiAggregatedSearchRequest, options?: any) {
        return MediaSearchApiFp(this.configuration).aggregatedSearch(requestParameters.aggregateBy, requestParameters.searchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search media by query
     * @param {MediaSearchApiSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaSearchApi
     */
    public search(requestParameters: MediaSearchApiSearchRequest, options?: any) {
        return MediaSearchApiFp(this.configuration).search(requestParameters.searchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaUploadApi - axios parameter creator
 * @export
 */
export const MediaUploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} confidential 
         * @param {string} attributesJson 
         * @param {any} file 
         * @param {string} [fileName] 
         * @param {'GAME_MOVIE'} [specialFileNameKind] 
         * @param {string} [contentType] 
         * @param {ITunesTrack} [iTunesTrack] 
         * @param {string} [createdAt] 
         * @param {string} [modifiedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post: async (confidential: boolean, attributesJson: string, file: any, fileName?: string, specialFileNameKind?: 'GAME_MOVIE', contentType?: string, iTunesTrack?: ITunesTrack, createdAt?: string, modifiedAt?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confidential' is not null or undefined
            assertParamExists('post', 'confidential', confidential)
            // verify required parameter 'attributesJson' is not null or undefined
            assertParamExists('post', 'attributesJson', attributesJson)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('post', 'file', file)
            const localVarPath = `/api/media/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (confidential !== undefined) {
                localVarQueryParameter['confidential'] = confidential;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (specialFileNameKind !== undefined) {
                localVarQueryParameter['specialFileNameKind'] = specialFileNameKind;
            }

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }

            if (iTunesTrack !== undefined) {
                localVarQueryParameter['iTunesTrack'] = iTunesTrack;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['createdAt'] = (createdAt as any instanceof Date) ?
                    (createdAt as any).toISOString() :
                    createdAt;
            }

            if (modifiedAt !== undefined) {
                localVarQueryParameter['modifiedAt'] = (modifiedAt as any instanceof Date) ?
                    (modifiedAt as any).toISOString() :
                    modifiedAt;
            }

            if (attributesJson !== undefined) {
                localVarQueryParameter['attributesJson'] = attributesJson;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaUploadApi - functional programming interface
 * @export
 */
export const MediaUploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaUploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} confidential 
         * @param {string} attributesJson 
         * @param {any} file 
         * @param {string} [fileName] 
         * @param {'GAME_MOVIE'} [specialFileNameKind] 
         * @param {string} [contentType] 
         * @param {ITunesTrack} [iTunesTrack] 
         * @param {string} [createdAt] 
         * @param {string} [modifiedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async post(confidential: boolean, attributesJson: string, file: any, fileName?: string, specialFileNameKind?: 'GAME_MOVIE', contentType?: string, iTunesTrack?: ITunesTrack, createdAt?: string, modifiedAt?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.post(confidential, attributesJson, file, fileName, specialFileNameKind, contentType, iTunesTrack, createdAt, modifiedAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaUploadApi - factory interface
 * @export
 */
export const MediaUploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaUploadApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} confidential 
         * @param {string} attributesJson 
         * @param {any} file 
         * @param {string} [fileName] 
         * @param {'GAME_MOVIE'} [specialFileNameKind] 
         * @param {string} [contentType] 
         * @param {ITunesTrack} [iTunesTrack] 
         * @param {string} [createdAt] 
         * @param {string} [modifiedAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        post(confidential: boolean, attributesJson: string, file: any, fileName?: string, specialFileNameKind?: 'GAME_MOVIE', contentType?: string, iTunesTrack?: ITunesTrack, createdAt?: string, modifiedAt?: string, options?: any): AxiosPromise<any> {
            return localVarFp.post(confidential, attributesJson, file, fileName, specialFileNameKind, contentType, iTunesTrack, createdAt, modifiedAt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for post operation in MediaUploadApi.
 * @export
 * @interface MediaUploadApiPostRequest
 */
export interface MediaUploadApiPostRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MediaUploadApiPost
     */
    readonly confidential: boolean

    /**
     * 
     * @type {string}
     * @memberof MediaUploadApiPost
     */
    readonly attributesJson: string

    /**
     * 
     * @type {any}
     * @memberof MediaUploadApiPost
     */
    readonly file: any

    /**
     * 
     * @type {string}
     * @memberof MediaUploadApiPost
     */
    readonly fileName?: string

    /**
     * 
     * @type {'GAME_MOVIE'}
     * @memberof MediaUploadApiPost
     */
    readonly specialFileNameKind?: 'GAME_MOVIE'

    /**
     * 
     * @type {string}
     * @memberof MediaUploadApiPost
     */
    readonly contentType?: string

    /**
     * 
     * @type {ITunesTrack}
     * @memberof MediaUploadApiPost
     */
    readonly iTunesTrack?: ITunesTrack

    /**
     * 
     * @type {string}
     * @memberof MediaUploadApiPost
     */
    readonly createdAt?: string

    /**
     * 
     * @type {string}
     * @memberof MediaUploadApiPost
     */
    readonly modifiedAt?: string
}

/**
 * MediaUploadApi - object-oriented interface
 * @export
 * @class MediaUploadApi
 * @extends {BaseAPI}
 */
export class MediaUploadApi extends BaseAPI {
    /**
     * 
     * @param {MediaUploadApiPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaUploadApi
     */
    public post(requestParameters: MediaUploadApiPostRequest, options?: any) {
        return MediaUploadApiFp(this.configuration).post(requestParameters.confidential, requestParameters.attributesJson, requestParameters.file, requestParameters.fileName, requestParameters.specialFileNameKind, requestParameters.contentType, requestParameters.iTunesTrack, requestParameters.createdAt, requestParameters.modifiedAt, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageDownloadApi - axios parameter creator
 * @export
 */
export const StorageDownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk get download URL
         * @param {string} validUntilAtLeast 
         * @param {Array<string>} fileHashes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUrlBulk: async (validUntilAtLeast: string, fileHashes: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validUntilAtLeast' is not null or undefined
            assertParamExists('getUrlBulk', 'validUntilAtLeast', validUntilAtLeast)
            // verify required parameter 'fileHashes' is not null or undefined
            assertParamExists('getUrlBulk', 'fileHashes', fileHashes)
            const localVarPath = `/api/storage/download/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (validUntilAtLeast !== undefined) {
                localVarQueryParameter['validUntilAtLeast'] = (validUntilAtLeast as any instanceof Date) ?
                    (validUntilAtLeast as any).toISOString() :
                    validUntilAtLeast;
            }

            if (fileHashes) {
                localVarQueryParameter['fileHashes'] = fileHashes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageDownloadApi - functional programming interface
 * @export
 */
export const StorageDownloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageDownloadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Bulk get download URL
         * @param {string} validUntilAtLeast 
         * @param {Array<string>} fileHashes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUrlBulk(validUntilAtLeast: string, fileHashes: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: FileURL; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUrlBulk(validUntilAtLeast, fileHashes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageDownloadApi - factory interface
 * @export
 */
export const StorageDownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageDownloadApiFp(configuration)
    return {
        /**
         * 
         * @summary Bulk get download URL
         * @param {string} validUntilAtLeast 
         * @param {Array<string>} fileHashes 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUrlBulk(validUntilAtLeast: string, fileHashes: Array<string>, options?: any): AxiosPromise<{ [key: string]: FileURL; }> {
            return localVarFp.getUrlBulk(validUntilAtLeast, fileHashes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUrlBulk operation in StorageDownloadApi.
 * @export
 * @interface StorageDownloadApiGetUrlBulkRequest
 */
export interface StorageDownloadApiGetUrlBulkRequest {
    /**
     * 
     * @type {string}
     * @memberof StorageDownloadApiGetUrlBulk
     */
    readonly validUntilAtLeast: string

    /**
     * 
     * @type {Array<string>}
     * @memberof StorageDownloadApiGetUrlBulk
     */
    readonly fileHashes: Array<string>
}

/**
 * StorageDownloadApi - object-oriented interface
 * @export
 * @class StorageDownloadApi
 * @extends {BaseAPI}
 */
export class StorageDownloadApi extends BaseAPI {
    /**
     * 
     * @summary Bulk get download URL
     * @param {StorageDownloadApiGetUrlBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageDownloadApi
     */
    public getUrlBulk(requestParameters: StorageDownloadApiGetUrlBulkRequest, options?: any) {
        return StorageDownloadApiFp(this.configuration).getUrlBulk(requestParameters.validUntilAtLeast, requestParameters.fileHashes, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TaskTimingModifyRequest} taskTimingModifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTiming: async (taskTimingModifyRequest: TaskTimingModifyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskTimingModifyRequest' is not null or undefined
            assertParamExists('modifyTiming', 'taskTimingModifyRequest', taskTimingModifyRequest)
            const localVarPath = `/api/task/timing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskTimingModifyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stat: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/stat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TaskTimingModifyRequest} taskTimingModifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyTiming(taskTimingModifyRequest: TaskTimingModifyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModifyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyTiming(taskTimingModifyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stat(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskStat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stat(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 
         * @param {TaskTimingModifyRequest} taskTimingModifyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyTiming(taskTimingModifyRequest: TaskTimingModifyRequest, options?: any): AxiosPromise<ModifyResult> {
            return localVarFp.modifyTiming(taskTimingModifyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stat(options?: any): AxiosPromise<Array<TaskStat>> {
            return localVarFp.stat(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for modifyTiming operation in TaskApi.
 * @export
 * @interface TaskApiModifyTimingRequest
 */
export interface TaskApiModifyTimingRequest {
    /**
     * 
     * @type {TaskTimingModifyRequest}
     * @memberof TaskApiModifyTiming
     */
    readonly taskTimingModifyRequest: TaskTimingModifyRequest
}

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @param {TaskApiModifyTimingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public modifyTiming(requestParameters: TaskApiModifyTimingRequest, options?: any) {
        return TaskApiFp(this.configuration).modifyTiming(requestParameters.taskTimingModifyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public stat(options?: any) {
        return TaskApiFp(this.configuration).stat(options).then((request) => request(this.axios, this.basePath));
    }
}


