import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { PlayerEvents } from "../model/player/Player";
import { useApp } from "..";
import PopperJs from 'popper.js';
import { Duration } from "luxon";
import DurationText from "../atoms/DurationText";
import { Media } from "../model/Media";
import MediaTiledThumbnail from "../atoms/MediaTiledThumbnail";

const barVerticalMargin = 8;
const barHeight = 6;
const barHoverHeight = 12;
const useStyles = makeStyles((theme) => ({
  root: {
    height: `${barVerticalMargin * 2 + barHeight}px`,
    // Caveats: .root and .bars must have exactly same width & location.
  },
  bars: {
    position: "relative",
    top: `${barVerticalMargin}px`,
    height: `${barHeight}px`,

    cursor: "pointer",

    "*:hover > &": {
      top: `${barVerticalMargin - (barHoverHeight - barHeight) / 2}px`,
      height: `${barHoverHeight}px`,
    },
  },
  bar: {
    position: "absolute",
    top: 0,
    height: "100%",

    "&.past": {
      backgroundColor: theme.palette.secondary.dark,
    },
    "&.buffered": {
      backgroundColor: theme.palette.grey[400],
    },
    "&.unbuffered": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export default function PlayingProgressBar({ containerRef }: { containerRef: React.RefObject<React.ReactInstance> }) {
  const { player } = useApp();
  const barsRef = useRef<HTMLDivElement>(null);
  const playerState = usePlayerState();
  const duration = playerState?.duration ?? null;
  const [ mouseLocation, setMouseLocation ] = useState<{ clientX: number, clientY: number, duration: Duration } | null>(null);

  const mouseXtoDurationSec = useCallback((clientX: number): number | null => {
    const barRect = barsRef.current?.getBoundingClientRect();
    if (! barRect || ! duration) return null;

    const ratio = (clientX - barRect.left) / barRect.width;
    return duration.toMillis() / 1000 * ratio;
  }, [ duration ]);

  const onPointerDown = useCallback((e: React.PointerEvent<unknown>) => {
    const d = mouseXtoDurationSec(e.clientX);
    if (d !== null) player.currentTimeSec = d;
  }, [ player, mouseXtoDurationSec ]);

  const updatePopoover = useCallback((e: React.MouseEvent<unknown, unknown>) => {
    const d = mouseXtoDurationSec(e.clientX);
    setMouseLocation((d === null) ? null : { clientX: e.clientX, clientY: e.clientY, duration: Duration.fromMillis(d * 1000) });
  }, [ mouseXtoDurationSec ]);
  const clearPopover = useCallback(() => setMouseLocation(null), []);

  const classes = useStyles();
  return <PeekPopover containerRef={containerRef} barsRef={barsRef} mouseLocation={mouseLocation} playerState={playerState}>
    <div
      className={classes.root}
      onPointerDown={onPointerDown}
      onMouseOver={updatePopoover}
      onMouseMove={updatePopoover}
      onMouseLeave={clearPopover}
  >
      <div
        ref={barsRef}
        className={classes.bars}
      ><ProgresBarBars playerState={playerState} /></div>
    </div>
  </PeekPopover>;
}

const ProgresBarBars = React.memo(({ playerState }: { playerState: PlayerState | null }): JSX.Element => {
  let currentRatio = playerState ? playerState.currentTime.toMillis() / playerState.duration.toMillis() : 0.0;
  if (!isFinite(currentRatio)) currentRatio = 0.0;

  const classes = useStyles();
  if (playerState === null) return <></>;
  let leftPercent = 0;
  return <>{
    [
      { className: "past", percent: currentRatio * 100 },
      { className: "buffered", percent: 0 },
      { className: "unbuffered", percent: 100 - currentRatio * 100 },
    ].map(({className, percent}) => {
      const bar = <div key={className} className={`${classes.bar} ${className}`} style={{
        left: `${leftPercent}%`,
        width: `${percent}%`,
      }} />;
      leftPercent += percent;
      return bar;
    })
  }</>;
});

/** Show popover on mouse hover */
const PeekPopover = React.memo(({ containerRef, children, barsRef, mouseLocation, playerState }: {
  containerRef: React.RefObject<React.ReactInstance>,
  children: React.ReactElement<any, any>,
  barsRef: RefObject<HTMLDivElement>,
  mouseLocation: null | { clientX: number, clientY: number, duration: Duration },
  playerState: null | PlayerState,
}): JSX.Element => {
  // Because ToolTip.followCursor is not supported in current Material-UI, made similar functionality from scratch.
  // ref: https://github.com/mui-org/material-ui/pull/22876/files#diff-aaff5319cd9e147388098dec9be1f45ecf638dad2057663b04ef97566246c58c
  const popperRef = useRef<PopperJs>(null);
  useEffect(() => {
    if (popperRef.current) popperRef.current.scheduleUpdate();
  }, [ mouseLocation?.clientX ]);

  const getBoundingClientRect = useCallback((): ClientRect => ({
    top: barsRef.current?.getBoundingClientRect().top ?? 0,
    left: mouseLocation!!.clientX,
    right: mouseLocation!!.clientX,
    bottom: barsRef.current?.getBoundingClientRect().bottom ?? 0,
    width: 0,
    height: 0,
  }), [ barsRef, mouseLocation ]);

  return <Tooltip
    title={mouseLocation && playerState ? <>
      <div><DurationText duration={mouseLocation.duration} /> / <DurationText duration={playerState.duration} /></div>
      <MediaTiledThumbnail
        media={playerState.media}
        currentTime={mouseLocation.duration}
        maxSize={[ 300, 300 ]}
      />
    </> : <></>}
    placement="top"
    arrow
    PopperProps={{
      popperRef,
      container: containerRef.current, // Required to mount component within FullScreen target: https://stackoverflow.com/a/54891984/914786
      placement: "top",
      anchorEl: mouseLocation ? {
        clientHeight: 0,
        clientWidth: 0,
        getBoundingClientRect,
      } : null,
    }}
  >
    {children}
  </Tooltip>;
});

type PlayerState = {
  media: Media,
  duration: Duration,
  currentTime: Duration,
};

const targetEvents: readonly PlayerEvents[] = [ "current-media-changed", "current-time-changed", "duration-changed", "end-of-media"] as const;
function usePlayerState(): PlayerState | null {
  const { player } = useApp();
  const [ state, setState ] = useState<PlayerState | null>(null);
  useEffect(() => {
    const handles = targetEvents.map((type) => player.addListener(type, () => {
      const media = player.media;
      const duration = player.durationSec;
      const currentTime = player.currentTimeSec;
      setState((media != null && duration !== null && currentTime !== null) ? {
        media,
        duration: Duration.fromMillis(duration * 1000),
        currentTime: Duration.fromMillis(currentTime * 1000),
      } : null);
    }));
    return () => handles.forEach((h) => h.remove());
  }, [ player ]);
  return state;
}
