import { Media, MediaFileType } from "../../../Media";
import { EventSubscription } from 'fbemitter';
import { StubMediaPlayer } from "./StubMediaPlayer";
import AudioPlayer, { AudioPlayerDeps } from "./AudioPlayer";
import VideoPlayer, { VideoPlayerDeps } from "./VideoPlayer";
import { PlayerSettings } from "../../PlayerSettings";

export const mediaPlayerEventTypes = [
  "html-element-changed",
  "media-not-playable",
  "media-playable-changed",
  "file-changed",
  "pause-resume",
  "end-of-media",
  "seek-started", // On complete, raise "current-time-changed"
  "current-time-changed",
  "duration-changed",
  "settings-changed",
] as const;
export type MediaPlayerEventType = (typeof mediaPlayerEventTypes)[number];

export type MediaPlayerDeps = AudioPlayerDeps | VideoPlayerDeps;

export const mediaPlayerCtors: { [type in MediaFileType]: (deps: MediaPlayerDeps) => MediaPlayer } = {
  audio: (deps) => new AudioPlayer(deps),
  video: (deps) => new VideoPlayer(deps),
  image: (deps) => new StubMediaPlayer("image"),
};

/**
 * Low level object to play media.
 */
export interface MediaPlayer {
  shutdown(): void;

  readonly type: MediaFileType;
  addListener(t: MediaPlayerEventType, f: Function): EventSubscription;

  readonly htmlElement: HTMLElement | null;

  media: Media | null;
  readonly fileHash: string | null;
  paused: boolean;

  readonly mediaPlayable: boolean;
  /** Implementation should ignore duplicate call. */
  prefetch(m: Media): void;

  readonly durationSec: number | null;
  currentTimeSec: number | null;

  settings: PlayerSettings;

  isCompatible(m: Media): boolean;
}
