import { History as HistoryBase, MediaPlayHistory } from "./api/generated/api-client";
import API from "./api";
import Session from "./Session";

export type { MediaPlayHistory };
export type History = HistoryBase & (MediaPlayHistory);

const sleepAfterCycle = 1000;
const sleepAfterNoOpCycle = 3000;
const sleepAfterError = 3000;

export default class HistoryModel {
  private storage = window.localStorage;
  private session: Session;
  private api: API;

  constructor(deps: { session: Session, api: API }) {
    this.session = deps.session;
    this.api = deps.api;
    this.startWorkerOnAuthorized();
  }

  enqueue(history: History) {
    // Assume history.id is globally unique, thus this code is safe even if multiple windows accessing.
    this.storage.setItem(keyOf(history), JSON.stringify(history));
  }

  private startWorkerOnAuthorized() {
    if (this.session.sessionAvailable) this.startWorker();
    this.session.addListener("changed", () => {
      if (this.session.sessionAvailable) this.startWorker();
    });
  }

  private workerRunning = false;
  private startWorker() {
    (async () => {
      if (this.workerRunning) return;
      this.workerRunning = true;
      try {
        while(true) {
          try {
            const uploaded = await this.uploadHistories();
            await sleep(uploaded === 0 ? sleepAfterNoOpCycle : sleepAfterCycle);
          }catch(e){
            console.error("Unexpected error in History upload worker", e);
            await sleep(sleepAfterError);
          }
        }
      } finally {
        this.workerRunning = false;
      }
    })();
  }

  private async uploadHistories(): Promise<number> {
    let sent = 0;
    while(true) {
      const history = this.findQueuedItem();
      if (!history) break;

      try {
        await this.api.historyApi.put({
          listEditHistoryMediaAttributeChangeHistoryMediaListMobileSyncHistoryMediaPlayHistory: history,
        });
      } catch(e) {
        console.warn("Failed to upload history", history, e);
        await sleep(sleepAfterError);
        continue;
      }
      this.deleteFromQueue(history);
      sent++;
    }
    return sent;
  }

  private findQueuedItem(): null | History {
    for(let i = 0; true; i++) {
      const key = this.storage.key(i);
      if (key === null) return null;
      if (!isHistoryKey(key)) continue;

      const json = this.storage.getItem(key);
      if (json === null) continue; // Other window could consume entry concurrently.
      const history = JSON.parse(json) as History;
      if (typeof(history.id) !== "string" || typeof(history.metadata) !== "object") {
        console.warn(`Corrupted History JSON found: ${json}`);
        continue;
      }
      return history;
    }
  }

  private deleteFromQueue(history: History) {
    this.storage.removeItem(keyOf(history));
  }
};

const keyOf = (history: History) => "smss.history.queue.item." + history.id;
const isHistoryKey = (key: String) => key.startsWith("smss.history.queue.item.");

const sleep = (ms: number) => new Promise((resolve) => window.setTimeout(resolve, ms));