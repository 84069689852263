import { useCallback, useEffect, useState } from "react";
import { useApp } from "..";
import Search, { SearchQuery, SearchResult } from "../model/Search";
import useSearchResult from "./useSearchResult";
import useSearchQueued from "./useSearchQueued";

/**
 * Asynchronously perform media search and returns latest result.
 *
 * @returns 2nd element is SearchResult. 3rd element indicates loading state (true = loading), 3rd is Search provider itself.
 */
export default function useSearch(opts?: {
  /** (default: false) If true, use separate Search instance instead of App-global one. */
  isolate?: boolean,
}): {
  setQuery: (query: SearchQuery | null) => void,
  result: SearchResult | null,
  loading: boolean,
  Search: Search,
} {
  const { isolate } = opts ?? {};

  const { search: SearchGlobal } = useApp();
  const [ Search, setSearch ] = useState(isolate ? SearchGlobal.createSeparateInstance() : SearchGlobal);
  useEffect(() => {
    if (isolate) {
      if (Search === SearchGlobal) setSearch(SearchGlobal.createSeparateInstance());
    } else {
      if (Search !== SearchGlobal) setSearch(SearchGlobal);
    }
  }, [ isolate, Search, SearchGlobal ]);

  return {
    setQuery: useCallback((query) => query ? Search.search(query) : Search.clearSearch(), [ Search ]),
    result: useSearchResult(Search),
    loading: useSearchQueued(Search),
    Search,
  };
}
