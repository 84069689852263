import { useEffect, useState } from "react";
import { PinnableList } from "../model/ListPinning";
import { useApp } from "../";

export default function useListPinning(list: PinnableList | null): boolean {
  const { listPinning } = useApp();
  const [ pinned, setPinned ] = useState(list === null ? false : listPinning.isPinned(list));
  useEffect(() => {
    if (list === null) return;
    const h = listPinning.addListener("changed", () => {
      setPinned(listPinning.isPinned(list));
    });
    return () => h.remove();
  }, [ list, listPinning ]);
  return pinned;
}
