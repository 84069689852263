import firebase from 'firebase/app';
import '@firebase/auth';
import { EventEmitter, EventSubscription } from 'fbemitter';
import FirebaseModel from "./Firebase";

export default class Session {
  private firebase: FirebaseModel;
  constructor(deps: { firebase: FirebaseModel }) {
    this.firebase = deps.firebase;

    this.firebase.addListener("initialized", () => this.init());
  }

  private init() {
    if (!this.auth) throw new Error("Auth.initGlobal() should be called AFTER firebase client initialization.");

    this.auth.setPersistence("local");
    this.auth.onAuthStateChanged(() => {
      /// Prevent throwing error to firebase SDK.
      setTimeout(async () => {
        this.sessionAvailable = this.canGetJWT() && await this.authCheckApi();
      }, 1);
    });
    (async () => {
      this.sessionAvailable = this.canGetJWT() && await this.authCheckApi();
    })();
  }

  private emitter = new EventEmitter();
  addListener(t: "changed", f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  async getJWT(): Promise<string> {
    if (!this.canGetJWT()) throw new Error("No login session found.");
    return this.auth!.currentUser!.getIdToken();
  }
  private canGetJWT() {
    return !!(this.auth && this.auth.currentUser);
  }

  private authCheckApi: () => Promise<boolean> = async () => false;
  setAuthCheckApi(f: () => Promise<boolean>) {
    this.authCheckApi = f;
  }

  private _sessionAvailable: boolean = false;
  get sessionAvailable(): boolean {
    return this._sessionAvailable;
  }
  set sessionAvailable(value: boolean) {
    if (this._sessionAvailable === value) return;
    this._sessionAvailable = value;
    this.emitter.emit("changed");

    if (value) this.getJWT().then((jwt) => console.debug("JWT", jwt));
  }

  private get auth(): undefined | firebase.auth.Auth {
    if (! this.firebase.app) return undefined;
    return this.firebase.app.auth();
  }
};
