import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useApp } from "..";

export default function useStorageUrl(fileHash: string | null): string | null {
  const { storage } = useApp();
  const [ url, setUrl ] = useState<string | null>(null);
  const [ validUntil, setValidUntil ] = useState<string | null>(null);
  const [ urlExpired, setUrlExpired ] = useState(false);
  useEffect(
    () => {
      if (fileHash === null) {
        setUrl(null);
      } else {
        const requestedFileHash = fileHash;
        storage.getDownloadUrl(requestedFileHash).then(({ url, validUntil }) => {
          if (fileHash === requestedFileHash) {
            setUrl(url);
            setValidUntil(validUntil.toISO());
            setUrlExpired(false);
          }
        });
      }
    }, [ storage, setUrl, fileHash, setValidUntil, urlExpired, setUrlExpired ]
  );
  useEffect(
    () => {
      if (validUntil === null) return;
      const timer = window.setTimeout(() => {
        setUrlExpired(true);
      }, DateTime.fromISO(validUntil).diffNow().as("milliseconds"));
      return () => window.clearTimeout(timer);
    }, [ validUntil, setUrlExpired ]
  );
  return url;
}
