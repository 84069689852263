import React, { ForwardedRef } from "react";
import { Divider, MenuItem } from "@material-ui/core";
import { isEditableMediaList, isMediaList, isSmartMediaList, MediaListWithItems } from "../../model/List";
import ContextMenu, { ContextMenuProps } from ".";
import { useDialog } from "../dialog";
import useCurrentMediaList from "../../hooks/useCurrentMediaList";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import { useApp } from "../..";
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import EditIcon from '@material-ui/icons/Edit';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import usePinnedLists from "../../hooks/usePinnedLists";
import FilterListIcon from '@material-ui/icons/FilterList';

export default function MediaListViewContextMenu(props: {
  list: MediaListWithItems,
} & ContextMenuProps): JSX.Element {
  return <>
    <ContextMenu
      {...props}
      content={function Content({ ref }: { ref: ForwardedRef<any> }): JSX.Element {
        const { list, onClose } = props;
        const { list: List, listPinning } = useApp();
        const currentList = useCurrentMediaList();
        const pinnedLists = usePinnedLists();
        const [ openMediaListRenameDialog ] = useDialog("MediaListRenameDialog");
        const [ openEditSmartMediaListDialog ] = useDialog("EditSmartMediaListDialog");
        const [ openMediaListBulkAddDialog ] = useDialog("MediaListBulkAddDialog");

        const toggleMobileSync = async (mobileSync: boolean) => {
          if (list.id) await List.setMobileSync(list.id, mobileSync);
        };

        return <div ref={ref}>
          {listPinning.isPinned(list)
            ? <MenuItem onClick={() => { listPinning.unpin(list); onClose(); }}><LabelOffIcon /> Unpin this list</MenuItem>
            : <MenuItem onClick={() => { listPinning.pin(list); onClose(); }}><LabelIcon /> Pin this list</MenuItem>}

          <Divider />

          {isMediaList(list) || isSmartMediaList(list) ? <>
            <MenuItem onClick={() => { if (isMediaList(list)) openMediaListRenameDialog({ node: list }); onClose(); }}><EditIcon /> Rename...</MenuItem>
            <MenuItem onClick={() => { toggleMobileSync(!list.mobileSync); onClose(); }}>
              {list.mobileSync ? <MobileOffIcon /> : <PhoneIphoneIcon />}
              Turn {list.mobileSync ? "off" : "on"} mobile sync
            </MenuItem>
            {isSmartMediaList(list) ? <MenuItem onClick={() => { openEditSmartMediaListDialog({ list }); onClose(); }}>
              <FilterListIcon /> Edit criteria
            </MenuItem> : null}

            <Divider />
          </> : null}

          <MenuItem onClick={() => { openMediaListBulkAddDialog({ src: list, dest: "create-new-list" }); onClose(); }}><PlaylistAddIcon /> Create new list based on this...</MenuItem>
          {pinnedLists.map((pinnedList, index) => (!isEditableMediaList(pinnedList) || pinnedList.id === list.id || pinnedList.id === currentList?.id) ? null : <MenuItem key={`add-to-pinned-list-${pinnedList.id ?? index}`} onClick={() => { openMediaListBulkAddDialog({ src: list, dest: pinnedList }); onClose(); }}>
            <PlaylistAddIcon /> Copy items to pinned list "{pinnedList.name}" ...
          </MenuItem>)}
          {currentList && isEditableMediaList(currentList) && currentList.id !== list.id ? <MenuItem onClick={() => { openMediaListBulkAddDialog({ src: list, dest: currentList }); onClose(); }}>
            <PlaylistAddIcon /> Copy items to playing list "{currentList.name}" ...
          </MenuItem> : null}
        </div>;
      }}
    />
  </>;
}
