import { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider, IconButton, ListItemIcon, makeStyles, Typography } from '@material-ui/core';
import { AppDialogType, AppUIMode } from ".";
import MediaFileTypeIcon from "../../atoms/MediaFileTypeIcon";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const useStyles = makeStyles(theme => ({
  menuLabel: {
    marginLeft: "-16px",
  },
}));

export default function MenuButton({ mode: currentMode, onSelected, showDialog: showDialogUpstream }: {
  mode: AppUIMode,
  onSelected: (type: AppUIMode) => void,
  showDialog: (type: AppDialogType) => void,
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const select = (selected: AppUIMode) => () => {
    setAnchorEl(null);
    if (currentMode !== selected) onSelected(selected);
  };
  const showDialog = (mode: AppDialogType) => {
    setAnchorEl(null);
    showDialogUpstream(mode);
  };

  const modeItems: {
    mode: AppUIMode,
    icon: JSX.Element,
    label: string,
  }[] = [
    { mode: "music", icon: <MediaFileTypeIcon type="audio" />, label: "Music" },
    { mode: "movie", icon: <MediaFileTypeIcon type="video" />, label: "Movie" },
    { mode: "confidential-films", icon: <MediaFileTypeIcon type="image" />, label: "Confidential Films" },
  ];
  const classes = useStyles();
  return <>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {modeItems.map(({ mode, icon, label }) => <MenuItem key={mode} onClick={select(mode)} selected={mode === currentMode}>
        <ListItemIcon>{icon}</ListItemIcon>
        <Typography className={classes.menuLabel} variant="inherit">{label}</Typography>
      </MenuItem>)}

      <Divider />
      <MenuItem onClick={() => showDialog("upload")}>
        <ListItemIcon><CloudUploadIcon /></ListItemIcon>
        <Typography className={classes.menuLabel} variant="inherit">Upload</Typography>
      </MenuItem>
      <MenuItem onClick={() => showDialog("tasks")}>
        <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
        <Typography className={classes.menuLabel} variant="inherit">Tasks</Typography>
      </MenuItem>
    </Menu>
    <IconButton edge="start" color="inherit" onClick={(event) => setAnchorEl(event.currentTarget)}>
      {modeItems.find((item) => item.mode === currentMode)?.icon}
    </IconButton>
  </>;
}
