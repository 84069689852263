import { Media as RawMedia, MediaFile as RawMediaFile, FileInfo as RawFileInfo, MediaFileInfo as RawMedia2, MediaThumbnail as RawMediaThumbnail } from "./api/generated/api-client";
import { EventEmitter, EventSubscription } from 'fbemitter';
import API from "./api";
import { Attributes, toRawAttributes } from "./Attributes";
import ModelNotification from "./ModelNotification";
import { ImageFileInfo, ImageFormat, ImageOrientation, parseFileInfo } from "./FileInfo";

export const mediaFileTypes = [ "audio", "video", "image" ] as const;
export type MediaFileType = (typeof mediaFileTypes)[number];

export type FileInfo = RawFileInfo & {
  type: MediaFileType,
};

export type MediaFile = Omit<RawMediaFile, "files"> & {
  type: MediaFileType,
  files: FileInfo[],
};

export type MediaThumbnail = Omit<RawMediaThumbnail, "imageFile"> & {
  imageFile: MediaFile,
};

export type Media = Omit<RawMedia, "artworks" | "thumbnails"> & {
  type: MediaFileType,
  file: MediaFile,
  artworks: MediaFile[],
  thumbnails: MediaThumbnail[],
};
export const parseRawMedia = (m: RawMedia2 | RawMedia) => m as Media;

export function imageOrientationOf(m: Media): ImageOrientation {
  const values = m.file.files.map(parseFileInfo).map((fi) =>
    // FIXME: HEIC handling bug fix
    fi.type === "image" && fi.format === ImageFormat.Heic ? (fi as ImageFileInfo).orientation : ImageOrientation.Unknown);
  return values.find((orient) => orient !== ImageOrientation.Unknown && orient !== ImageOrientation.Aligned) ?? ImageOrientation.Unknown;
}

export default class MediaModel {
  private api: API;
  private modelNotification: ModelNotification;
  constructor(deps: { api: API, modelNotification: ModelNotification }) {
    this.api = deps.api;
    this.modelNotification = deps.modelNotification;
  }

  private mediaEmitter: {[id: string]: EventEmitter} = {};

  addListenerOnMedia(id: string, t: "changed", f: Function): EventSubscription {
    return this.emitterOfMedia(id).addListener(t, f);
  }
  private emitterOfMedia(id: string): EventEmitter {
    return this.mediaEmitter[id] = this.mediaEmitter[id] ?? new EventEmitter();
  }

  private mediums: { [id: string]: Media } = {};

  set(m: Media) {
    const old = this.mediums[m.id];
    this.mediums[m.id] = m;
    if (old && old !== m) this.emitterOfMedia(m.id).emit("changed");
  }

  async get(id: string): Promise<Media | null> {
    if (!this.mediums[id]) await this.refresh(id);
    return this.mediums[id];
  }
  private async refresh(id: string): Promise<Media> {
    const media = this.mediums[id] = (await this.api.mediaApi.getMedia({ id })).data as Media;
    this.emitterOfMedia(id).emit("changed");
    return media;
  }

  async changeAttributes(id: string, attributes: Omit<Attributes, "hidden"> & { hidden?: boolean }) {
    const attrs = {
      hidden: (await this.get(id))?.attributes.hidden ?? false,
      ...attributes,
    };
    await this.api.mediaApi.setAttributes({ id, attributes: toRawAttributes(attrs) });
    const after = await this.refresh(id);
    this.modelNotification.emit("Media.media-attributes-changed", after, Object.keys(attributes) as (keyof Attributes)[]);
  }
}
