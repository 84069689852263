import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  stars: {
    color: theme.palette.warning.light,
  },
}));

export default function RatingStars({ rating }: { rating: number }): JSX.Element {
  const classes = useStyles();
  if (rating === 0) return <></>;
  return <span className={classes.stars}>{"★".repeat(rating)}{"☆".repeat(5 - rating)}</span>;
}
