export type NODE_ENV = "production" | "development" | "test";

export type BuildConstants = SentryBuildConstants & ApiBuildConstants & GoogleMapsBuildConstants & Readonly<{
  NODE_ENV: NODE_ENV,
}>;
export type ApiBuildConstants = Readonly<{
  /** URL prefix of server-side APIs */
  REACT_APP_API_BASE_PATH: string | undefined,
  /** URL prefix of server-side APIs. Only applied for /history APIs. Not include "/history" prefix. */
  REACT_APP_HISTORY_API_BASE_PATH : string | undefined,
}>;
export type SentryBuildConstants = Readonly<{
  NODE_ENV: NODE_ENV,

  /** Sentry.io access key. */
  REACT_APP_SENTRY_DSN: string | undefined,
  /** "Release" attribute of Sentry events. */
  REACT_APP_SENTRY_RELEASE: string | undefined,
}>;
export type GoogleMapsBuildConstants = Readonly<{
  REACT_APP_GOOGLE_MAPS_API_KEY: string | undefined,
}>;

const buildConstants: BuildConstants = {
  NODE_ENV: process.env.NODE_ENV,

  // ApiBuildConstants
  REACT_APP_API_BASE_PATH: process.env.REACT_APP_API_BASE_PATH,
  REACT_APP_HISTORY_API_BASE_PATH: process.env.REACT_APP_HISTORY_API_BASE_PATH,

  // SentryBuildConstants
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,

  // GoogleMapsBuildConstants
  REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
} as const;

export default buildConstants;
