import React from "react";
import MediaAlertsSnackbar from "./MediaAlertsSnackbar";
import UploadSnackbar from "./UploadSnackbar";
import ModelNotificationSnackbar from "./ModelNotificationSnackbar";

const Snackbars = React.memo((): JSX.Element => {
  return <>
    <ModelNotificationSnackbar />
    <MediaAlertsSnackbar />
    <UploadSnackbar />
  </>;
});
export default Snackbars;
