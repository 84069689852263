import { useEffect, useState } from "react";
import { useApp } from "..";
import Search from "../model/Search";

export default function useSearchQueued(search?: Search): boolean {
  const { search: SearchGlobal } = useApp();
  const Search = search ?? SearchGlobal;

  const [ searchQueued, setSearchQueued ] = useState(Search.searchQueued);
  useEffect(() => {
    const update = () => setSearchQueued(Search.searchQueued);
    update();

    const handles = [
      Search.addListener("search-queued", update),
      Search.addListener("search-performed", update),
    ];
    return () => handles.forEach((h) => h.remove());
  }, [ Search ]);
  return searchQueued;
}
