import { Player } from "..";
import { ImageFileInfo, parseFileInfo } from "../../FileInfo";
import { Media } from "../../Media";
import Storage from "../../Storage";

export type MediaSessionObserverDeps = {
  player: Player,
  storage: Storage,
  mediaSession: MediaSession,
};

/** Watch Player state and report it to HTML5 Media Session API. */
export default class MediaSessionObserver {
  private player: Player;
  private storage: Storage;
  private mediaSession: MediaSession;
  constructor(deps: MediaSessionObserverDeps) {
    this.player = deps.player;
    this.storage = deps.storage;
    this.mediaSession = deps.mediaSession;

    this.setPlayerHandlers();
    this.setMediaSessionHandlers();
  }

  private setPlayerHandlers() {
    this.player.addListener("current-media-changed", () => { this.updateMediaMetadata(); });
    this.player.addListener("pause-resume", () => {
      const media = this.player.media;
      if (media == null) {
        this.mediaSession.playbackState = "none";
      } else {
        this.mediaSession.playbackState = this.player.paused ? "paused" : "playing";
      }
    });
  }

  private setMediaSessionHandlers() {
    this.mediaSession.setActionHandler("play", () => {
      this.player.paused = false;
    });
    this.mediaSession.setActionHandler("pause", () => {
      this.player.paused = true;
    });
    this.mediaSession.setActionHandler("stop", () => {
      this.player.paused = true;
    });
    this.mediaSession.setActionHandler("previoustrack", () => {
      this.player.previousTrack();
    });
    this.mediaSession.setActionHandler("nexttrack", () => {
      this.player.nextTrack();
    });
  }

  private updateMediaMetadata() {
    const media = this.player.media;
    this.mediaSession.metadata = (media === null) ? null : convertToMediaMetadata(media, []);

    if (media && media.artworks.length > 0) {
      (async () => {
        const image = parseFileInfo(media.artworks[0].files[0]) as ImageFileInfo;
        const { url: imageUrl } = await this.storage.getDownloadUrl(image.fileHash);
        if (this.player.media?.id === media.id) {
          this.mediaSession.metadata = (media === null) ? null : convertToMediaMetadata(media, [
            {
              src: imageUrl,
              sizes: `${image.widthPx}x${image.heightPx}`,
            },
          ]);
        }
      })();
    }
  }
}

const convertToMediaMetadata = (media: Media, artworks: MediaImage[]) => new MediaMetadata({
  title: media.attributes.name,
  album: media.attributes.albumName,
  artist: media.attributes.artist,
  artwork: artworks,
});
