import React from "react";
import { AppDialogType } from "..";
import UploadDialog from "./UploadDialog";
import Tasks from "./Tasks";

const AppDialogs = React.memo(({ showDialog, onClose }: {
  showDialog: AppDialogType | null,
  onClose: () => void,
}): JSX.Element => {
  return <>
    {showDialog === "upload" ? <UploadDialog open={true} onClose={onClose} /> : null}
    {showDialog === "tasks" ? <Tasks open={true} onClose={onClose} /> : null}
  </>;
});
export default AppDialogs;

export type DialogProps = {
  open: boolean,
  onClose: () => void,
};
