import { useEffect, useState } from "react";
import { useApp } from "..";
import { PinnableList } from "../model/ListPinning";

export default function usePinnedLists(): PinnableList[] {
  const { listPinning } = useApp();
  const [ lists, setLists ] = useState(listPinning.lists);
  useEffect(() => {
    const h = listPinning.addListener("changed", () => {
      setLists(listPinning.lists);
    });
    return () => h.remove();
  }, [ setLists, listPinning ]);

  return lists.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
}
