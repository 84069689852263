import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, makeStyles, Slider } from "@material-ui/core";
import ThumbnailGrid from "../../../components/ThumbnailGrid";
import { Media } from "../../../model/Media";
import { ImageCriteria, toSearchQuery } from ".";
import { useApp } from "../../..";
import { SearchRequestSort } from "../../../model/Search";
import Slideshow from "./slideshow";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  container: {
    flex: "1 1 300px",
  },
  bottomBar: {
    flex: "0 0 auto",
    display: "flex",
  },
  bottomBarLeft: {
    flex: "1 1 300px",
  },
  bottomBarRight: {
    flex: "0 0 auto",
  },
}));

export default React.memo(({ criteria }: {
  criteria: ImageCriteria,
}): JSX.Element => {
  const [ itemSize, setItemSize ] = useState(175);
  const itemSizeSliderHandler = useCallback((_: any, newValue: number | number[]) => setItemSize(newValue as number), []);
  const [ itemsLimit ] = useState(10000);

  const [ items, setItems ] = useState<null | Media[]>(null);
  const { searchStateless } = useApp();
  useEffect(() => {
    (async () => {
      setItems((await searchStateless.search(
        toSearchQuery(criteria),
        {
          sort: SearchRequestSort.DateAdded,
          limit: itemsLimit,
        },
      )).items);
    })();
  }, [ searchStateless, criteria, itemsLimit ]);

  const [ slideshow, setSlideshow ] = useState<null | {
    open: boolean,
    items: Media[],
    initialIndex: number,
  }>(null);
  const startSlideshow = useCallback((media: Media) => {
    if (!items) return;
    const index = items.findIndex((m) => m.id === media.id);
    if (index === -1) return;
    setSlideshow({
      open: true,
      items,
      initialIndex: index,
    });
  }, [ items ]);
  const endSlideshow = useCallback(() => { setSlideshow(null) }, []);

  const classes = useStyles();
  if (!items) return <div>
    <CircularProgress /> Loading...
  </div>;
  return <div className={classes.root}>
    {slideshow ? <Slideshow
      {...slideshow}
      onClose={endSlideshow}
    /> : null}
    <div className={classes.container}>
      <ThumbnailGrid
        items={items}
        itemWidth={itemSize}
        itemHeight={itemSize}
        onClick={startSlideshow}
      />
    </div>
    <div className={classes.bottomBar}>
      <div className={classes.bottomBarLeft}>
        <Slider
          value={itemSize}
          min={50}
          max={300}
          onChange={itemSizeSliderHandler}
          valueLabelDisplay="auto"
        />
      </div>
      <div className={classes.bottomBarRight}>
        {items.length} files
      </div>
    </div>
  </div>;
});
