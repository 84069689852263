import { useEffect, useState } from 'react';
import { useApp } from '..';
import { PlayerSettings } from '../model/player/PlayerSettings';

export default function usePlayerSettings(): PlayerSettings {
  const { player } = useApp();
  const [ settings, setSettings ] = useState(player.settings);
  useEffect(() => {
    const h = player.addListener("settings-changed", () => setSettings(player.settings));
    return () => h.remove();
  }, [ player, setSettings ]);
  return settings;
}
