import React, { useState } from "react";
import { DialogContentText, TextField } from "@material-ui/core";
import { ListChildren, ListTreeNode } from "../../model/List";
import { useApp } from "../..";
import { DialogProps } from ".";
import { Dialog, DialogContent } from "./Dialog";

export type MediaListRenameDialogProps = DialogProps & {
  node: ListTreeNode | ListChildren[number],
};

export default function MediaListRenameDialog(): JSX.Element {
  return <Dialog
    id="MediaListRenameDialog"
    content={function Content(props: MediaListRenameDialogProps): JSX.Element {
      const { node } = props;
      const { list } = useApp();
      const [ value, setValue ] = useState(node.name ?? "");
      return <DialogContent
        {...props}
        title="Rename list"
        onSave={async () => {
          await list.rename(node.id, value);
        }}
      >
        <DialogContentText>Renaming "{node.name}"</DialogContentText>
        <TextField
          fullWidth
          autoFocus
          margin="dense"
          label="New name"
          value={value}
          onChange={(e) => { setValue(e.target.value) }}
        />
      </DialogContent>;
    }}
  />;
}
