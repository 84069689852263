import React, { ForwardedRef } from "react";
import { Divider, MenuItem } from "@material-ui/core";
import { EditableMediaList, isEditableMediaList, isMediaList, MediaListWithItems } from "../../model/List";
import ContextMenu, { ContextMenuProps } from ".";
import { useApp } from "../..";
import useMedia from "../../hooks/useMedia";
import useCurrentMediaList from "../../hooks/useCurrentMediaList";
import { useDialog } from "../dialog";
import { Attributes } from "../../model/Attributes";
import { queryToString } from "../../model/Search";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import usePinnedLists from "../../hooks/usePinnedLists";
import RatingStarsEditable from "../../atoms/RatingStarsEditable";

export default function MediaContextMenu(props: {
  list: MediaListWithItems,
  index: number,
} & ContextMenuProps): JSX.Element {
  return <>
    <ContextMenu
      {...props}
      content={function Content({ ref }: { ref: ForwardedRef<any> }): JSX.Element {
        const { onClose, list, index } = props;
        const { list: MediaList, search: Search, media: Media } = useApp();
        const media = useMedia(list.items[index]);
        const currentMediaList = useCurrentMediaList();
        const pinnedLists = usePinnedLists();
        const [ openRawInfoDialog ] = useDialog("MediaRawInfoDialog");

        const search = (attribute: keyof Attributes) => {
          if (! media || ! media.attributes[attribute]) return;
          const error = Search.search({
            types: [ media.type ],
            confidential: media.confidential,
            query: queryToString([ { t: "akw", a: attribute, v: `${media.attributes[attribute]}` } ]),
          });
          if(error) alert(JSON.stringify(error));
          onClose();
        };

        const setStars = async (value: number) => {
          if (media) await Media.changeAttributes(media.id, { rating: value });
          onClose();
        };

        const addToList = async (list: EditableMediaList) => {
          if (media) await MediaList.addMediaToList(list, media);
          onClose();
        };
        let listToAddInto: EditableMediaList[] = [ ...pinnedLists.filter(isEditableMediaList) ];
        if (currentMediaList && isEditableMediaList(currentMediaList)) listToAddInto.push(currentMediaList);
        listToAddInto = listToAddInto.filter((dest) => dest.mediaType === media?.type).filter((dest) => dest.id !== list.id);

        const removeFromThisList = async () => {
          if (media && isMediaList(list)) {
            if (window.confirm(`Are you sure to remove "${media.attributes.name}" from the list "${list.name}"?`)) {
              await MediaList.removeMediaFromList(list, media, index);
            }
          }
          onClose();
        };
        const copyHash = async () => {
          if (media) await navigator.clipboard.writeText(media.id);
          onClose();
        };

        if (!media) return <></>;
        return <div ref={ref}>
          {([ "albumName", "artist", "gameBrand" ] as const).map((attr) => media.attributes[attr]
            ? <MenuItem key={`search-by-${attr}`} onClick={() => search(attr)}><SearchIcon /> Search {attr} "{media.attributes[attr]}"</MenuItem> : null)
          }
          <Divider />
          <MenuItem><RatingStarsEditable value={media.attributes.rating} onChange={setStars} /></MenuItem>
          <Divider />
          {listToAddInto.map((list) => <MenuItem key={`add-into-${list.id}`} onClick={() => addToList(list)}><PlaylistAddIcon /> Add to list "{list.name}"</MenuItem>)}
          {(listToAddInto.length === 0) ? <MenuItem disabled onClick={onClose}>To add this media to list, pin or start playing destination list.</MenuItem> : null}
          {isMediaList(list) ? <MenuItem onClick={removeFromThisList}><RemoveIcon /> Remove from this list "{list.name}"</MenuItem> : null}
          <Divider />
          <MenuItem onClick={copyHash}><FileCopyIcon /> Copy Media ID ({media.id.substring(0, 16)}...)</MenuItem>
          <MenuItem onClick={() => { openRawInfoDialog({ media }); onClose(); }}><LiveHelpIcon /> Raw JSON...</MenuItem>
        </div>;
      }}
    />
  </>;
}
