import { FileInfo } from "./Media";
import { Duration } from "luxon";
import { AudioFileInfo as RawAudioFileInfo, VideoFileInfo as RawVideoFileInfo, ImageFileInfo as RawImageFileInfo, AudioFileInfoCodingFormatEnum, VideoFileInfoCodingFormatEnum, AudioFileInfoContainerFormatEnum as ContainerFormat, ImageFileInfoOrientationEnum as ImageOrientation, ImageFileInfoFormatEnum } from "./api/generated/api-client";

export {
  AudioFileInfoContainerFormatEnum as ContainerFormat,
  AudioFileInfoCodingFormatEnum as AudioCodingFormat,
  VideoFileInfoCodingFormatEnum as VideoCodingFormat,
  ImageFileInfoOrientationEnum as ImageOrientation,
  ImageFileInfoFormatEnum as ImageFormat,
} from "./api/generated/api-client";
type AudioCodingFormat = AudioFileInfoCodingFormatEnum;
type VideoCodingFormat = VideoFileInfoCodingFormatEnum;
type ImageFormat = ImageFileInfoFormatEnum;

export type FileInfoBase = {
  type: string;
  fileHash: string;
  sizeBytes?: number;
};

export type AudioFileInfo = FileInfoBase & {
  type: "audio";

  containerFormat: ContainerFormat,
  codingFormat: AudioCodingFormat,
  duration: Duration;
}

export type VideoFileInfo = FileInfoBase & {
  type: "video";

  containerFormat: ContainerFormat,
  codingFormat: VideoCodingFormat,
  duration: Duration;
}

export type ImageFileInfo = FileInfoBase & {
  type: "image";

  widthPx: number;
  heightPx: number;
  format: ImageFormat;
  orientation: ImageOrientation;
}

export function isAudioOrVideo(fi: AudioFileInfo | VideoFileInfo | ImageFileInfo): fi is AudioFileInfo | VideoFileInfo {
  return typeof((fi as any).duration) === "object";
}

export function mimeTypeOf(fi: AudioFileInfo | VideoFileInfo): string | null {
  switch(fi.containerFormat) {
    case ContainerFormat.Aac: return "audio/aac";
    case ContainerFormat.Flac: return "audio/flac";
    case ContainerFormat.Matroska: return "video/webm";
    case ContainerFormat.Mp3: return "audio/mp3";
    case ContainerFormat.Mp4: return (fi.type === "audio") ? "audio/mp4" : "video/mp4";
    case ContainerFormat.Ogg: return "audio/ogg";
    default: return null;
  }
}

export function parseFileInfo(fi: FileInfo): AudioFileInfo | VideoFileInfo | ImageFileInfo {
  const base: FileInfoBase = {
    type: fi.type,
    fileHash: fi.file.hash,
    sizeBytes: fi.sizeBytes,
  };
  switch(fi.type) {
    case "audio": {
      const props = fi as RawAudioFileInfo;
      return {
        ...base,
        type: "audio",

        containerFormat: props.containerFormat,
        codingFormat: props.codingFormat,
        duration: Duration.fromISO(props.duration),
      };
    }
    case "video": {
      const props = fi as RawVideoFileInfo;
      return {
        ...base,
        type: "video",

        containerFormat: props.containerFormat as unknown as ContainerFormat,
        codingFormat: props.codingFormat,
        duration: Duration.fromISO(props.duration)
      };
    }
    case "image": {
      const props = fi as RawImageFileInfo;
      return {
        ...base,
        type: "image",

        widthPx: props.widthPx,
        heightPx: props.heightPx,
        format: props.format,
        orientation: props.orientation,
      };
    }
  }
}
