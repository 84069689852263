import React, { useMemo } from "react";
import { FixedSizeGrid, GridChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { makeStyles } from "@material-ui/core";
import { Media } from "../model/Media";
import useThumbnail from "../hooks/useThumbnail";
import useStorageUrl from "../hooks/useStorageUrl";
import useImageTransform from "../hooks/useImageTransform";

const itemMarginDefault = 3;

const useGridStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    width: "100%",
  },
  grid: {},
}));

export default React.memo(({ items, itemWidth, itemHeight, itemMargin: _itemMargin, onClick }: {
  items: Media[],
  itemWidth: number,
  itemHeight: number,
  itemMargin?: number,
  onClick?: (m: Media) => void,
}): JSX.Element => {
  const itemMargin = _itemMargin ?? itemMarginDefault;

  const classes = useGridStyles();
  const gridInnerElement = useGridInnerElement({ itemMargin });
  return <div className={classes.root}>
    <GridAdjuster itemCount={items.length} itemWidth={itemWidth + itemMargin} itemHeight={itemHeight + itemMargin}>
      {({ height, width, columnCount, rowCount }) => <FixedSizeGrid
        className={classes.grid}
        style={{
          overflowX: "hidden", // GridAdjuster adjusts column count, no need to scroll horizontally.
        }}
        width={width}
        height={height}
        columnCount={columnCount}
        rowCount={rowCount}
        overscanRowCount={5}
        columnWidth={itemWidth + itemMargin}
        rowHeight={itemHeight + itemMargin}
        innerElementType={gridInnerElement}
        itemKey={({ columnIndex, rowIndex }) => {
          const index = rowIndex * columnCount + columnIndex;
          if (index >= items.length) return `dummy-${index}`;
          return items[index].id;
        }}
        itemData={{ items, itemMargin, columnCount, itemWidth, itemHeight, onClick }}
      >
        {GridItem}
      </FixedSizeGrid>}
    </GridAdjuster>
  </div>
});

const GridAdjuster = React.memo(({ itemWidth, itemCount, children: child }: {
  itemWidth: number,
  itemHeight: number,
  itemCount: number,
  children: (args: { height: number, width: number, columnCount: number, rowCount: number }) => React.ReactNode,
}): JSX.Element => {
  return <AutoSizer>
    {({ height, width }) => {
      const columnCount = Math.max(1, Math.floor(width / itemWidth)); // Must not be zero.
      const rowCount = Math.ceil(itemCount / columnCount);
      return child({
        height, width,
        columnCount, rowCount,
      });
    }}
  </AutoSizer>;
});

function useGridInnerElement({ itemMargin }: {
  itemMargin: number,
}): React.FunctionComponent<any> {
  return useMemo(() => React.forwardRef<HTMLDivElement, { style: React.CSSProperties }>(({ style, ...rest }, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        paddingLeft: itemMargin,
        paddingTop: itemMargin,
      }}
      {...rest}
    />
  )), [ itemMargin ]);
}

const useGridItemStyles = makeStyles(theme => ({
  root: {
    objectFit: "cover",
  },
  dummyRoot: {
    display: "none",
  },
}));

const GridItem = React.memo(({
  columnIndex, rowIndex, style,
  data: { items, itemWidth, itemHeight, itemMargin, columnCount, onClick }
}: GridChildComponentProps<{
  items: Media[],
  itemWidth: number,
  itemHeight: number,
  itemMargin: number,
  columnCount: number,
  onClick?: (m: Media) => void,
}>): JSX.Element => {
  const index = rowIndex * columnCount + columnIndex;
  const media = (index < items.length) ? items[index] : null;

  const image = useThumbnail(media, [ itemWidth, itemHeight ]);
  const imageUrl = useStorageUrl(image?.fileHash ?? null);
  const transform = useImageTransform(media);

  const classes = useGridItemStyles();
  if (!media) return <div className={classes.dummyRoot}></div>;
  return <img
    className={classes.root}
    style={{
      ...style,
      left: (style.left as number) + itemMargin,
      top: (style.top as number) + itemMargin,
      width: (style.width as number) - itemMargin,
      height: (style.height as number) - itemMargin,
      transform,
    }}

    src={imageUrl ?? undefined}
    alt={media.attributes.name ?? undefined}

    onClick={() => onClick ? onClick(media) : null}
  />
});

