import { Media, MediaFileType } from "../../../Media";
import { parseFileInfo, AudioFileInfo, mimeTypeOf } from "../../../FileInfo";
import HTMLMediaElementPlayer, { HTMLMediaElementPlayerDeps, HTMLMediaSource } from "./HTMLMediaElementPlayer";
import { Duration } from "luxon";

export type AudioPlayerDeps = HTMLMediaElementPlayerDeps;

export default class AudioPlayer extends HTMLMediaElementPlayer<HTMLAudioElement> {
  readonly type: MediaFileType = "audio";
  protected readonly elementName = "audio";

  protected chooseFile(m: Media): HTMLMediaSource | null {
    const files = m.file.files.map((fi) => parseFileInfo(fi as any)).filter((file): file is AudioFileInfo => file.type === "audio");
    const file = this.device.audioFormatPreference.flatMap((format) =>
      files.filter((fi) => fi.codingFormat === format && mimeTypeOf(fi) !== null)
    )[0] ?? null;

    if (file === null) return null;
    return {
      hash: file.fileHash,
      mimeType: mimeTypeOf(file)!!,
      shouldValidUntil: Duration.fromObject({ milliseconds: file.duration.toMillis() * 3 }),
    };
  }
}
