import React, { ForwardedRef } from "react";
import { Divider, MenuItem } from "@material-ui/core";
import { isEditableMediaList, isSmartMediaList, ListChildren, ListTreeNode, mobileSyncFlagOf, rootNodeID } from "../../model/List";
import ContextMenu, { ContextMenuProps } from ".";
import { useApp } from "../..";
import { useDialog } from "../dialog";
import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MobileOffIcon from '@material-ui/icons/MobileOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import usePinnedLists from "../../hooks/usePinnedLists";
import useCurrentMediaList from "../../hooks/useCurrentMediaList";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { isPinnableList } from "../../model/ListPinning";
import FilterListIcon from '@material-ui/icons/FilterList';

export default function MediaListContextMenu(props: {
  node: ListTreeNode | ListChildren[number],
} & ContextMenuProps): JSX.Element {
  return <>
    <ContextMenu
      {...props}
      content={function Content({ ref }: { ref: ForwardedRef<any> }): JSX.Element {
        const { node, onClose } = props;
        const { list, listPinning } = useApp();
        const currentList = useCurrentMediaList();
        const pinnedLists = usePinnedLists();
        const [ openCreateFolderDialog ] = useDialog("CreateFolderDialog");
        const [ openListMoveDialog ] = useDialog("ListMoveDialog");
        const [ openMediaListRenameDialog ] = useDialog("MediaListRenameDialog");
        const [ openEditSmartMediaListDialog ] = useDialog("EditSmartMediaListDialog");
        const [ openMediaListBulkAddDialog ] = useDialog("MediaListBulkAddDialog");

        const toggleMobileSync = async (mobileSync: boolean) => {
          await list.setMobileSync(node.id, mobileSync);
        };
        const disable = async () => {
          if(!window.confirm(`Are you sure to delete/disable list "${node.name}"?`)) return;
          await list.disable(node.id);
        };

        return <div ref={ref}>
          { node.id !== rootNodeID && node.type !== "folder" && isPinnableList(node) ? (listPinning.isPinned(node)
            ? <MenuItem onClick={() => { listPinning.unpin(node); onClose(); }}><LabelOffIcon /> Unpin this list</MenuItem>
            : <MenuItem onClick={() => { listPinning.pin(node); onClose(); }}><LabelIcon /> Pin this list</MenuItem>
          ): null}
          <MenuItem onClick={async () => { await list.get(node.id, true); onClose(); }}><CachedIcon /> Reload</MenuItem>

          <Divider />

          { node.type === "folder" ? <>
            <MenuItem onClick={() => { openCreateFolderDialog({ initialParentFolder: node, initialName: "" }); onClose(); }}><CreateNewFolderIcon />Create folder under this</MenuItem>
          </> : null }
          { node.id !== rootNodeID ? <>
            <MenuItem onClick={() => { openListMoveDialog({ node }); onClose(); }}><OpenWithIcon /> Move to...</MenuItem>
          </> : null}
          { node.id !== rootNodeID ? <MenuItem onClick={() => { openMediaListRenameDialog({ node }); onClose(); }}><EditIcon /> Rename...</MenuItem> : null}
          { isSmartMediaList(node) ? <MenuItem onClick={() => { openEditSmartMediaListDialog({ list: node }); onClose(); }}><FilterListIcon /> Edit criteria...</MenuItem> : null }
          { mobileSyncFlagOf(node) !== null
            ? <MenuItem onClick={() => { toggleMobileSync(!mobileSyncFlagOf(node)); onClose(); }}>
                {mobileSyncFlagOf(node) ? <MobileOffIcon /> : <PhoneIphoneIcon />}
                Turn {mobileSyncFlagOf(node) ? "off" : "on"} mobile sync
              </MenuItem>
            : null
          }
          {node.id !== rootNodeID ? <MenuItem onClick={disable}><DeleteOutlineIcon /> Delete (disable) this</MenuItem> : null}

          {node.type === "folder" ? null : <>
            <Divider />

            <MenuItem onClick={() => { openMediaListBulkAddDialog({ src: node, dest: "create-new-list" }); onClose(); }}><PlaylistAddIcon /> Create new list based on this...</MenuItem>
            {pinnedLists.map((pinnedList, index) => (!isEditableMediaList(pinnedList) || pinnedList.id === node.id || pinnedList.id === currentList?.id) ? null : <MenuItem key={`add-to-pinned-list-${pinnedList.id ?? index}`} onClick={() => { openMediaListBulkAddDialog({ src: node, dest: pinnedList }); onClose(); }}>
              <PlaylistAddIcon /> Copy items to pinned list "{pinnedList.name}" ...
            </MenuItem>)}
            {currentList && isEditableMediaList(currentList) && currentList.id !== node.id ? <MenuItem onClick={() => { openMediaListBulkAddDialog({ src: node, dest: currentList }); onClose(); }}>
              <PlaylistAddIcon /> Copy items to playing list "{currentList.name}" ...
            </MenuItem> : null}
          </>}
        </div>;
      }}
    />
  </>;
}
