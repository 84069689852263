import React, { useCallback } from "react";
import { Checkbox, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Chip } from "@material-ui/core";
import { isTemporaryMediaList, MediaListWithItems } from "../model/List";
import { parseFileInfo } from "../model/FileInfo";
import DurationText from "../atoms/DurationText";
import RatingStars from "../atoms/RatingStars";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MediaAvatar from "../atoms/MediaAvatar";
import useCurrentMedia from "../hooks/useCurrentMedia";
import { useApp } from "..";
import { isContextMenuOpen, useContextMenuState } from "./contextmenu";
import MediaContextMenu from "./contextmenu/MediaContextMenu";
import useMedia from "../hooks/useMedia";
import useMediaList from "../hooks/useMediaList";
import MediaListViewContextMenu from "./contextmenu/MediaListViewContextMenu";
import useListPinning from "../hooks/useListPinning";
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { useDialog } from "./dialog";
import usePlayerPaused from "../hooks/usePlayerPaused";

const useMediaListStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    flex: "0 0 auto",
    display: "flex",
  },
  headerTitle: {
    flex: "1 1 auto",
  },
  listName: {
    fontSize: "1.5em",
  },
  listInfo: {
    marginLeft: "2em",
    fontSize: "0.9em",
    color: theme.palette.text.secondary,
  },
  headerToolbar: {
    flex: "0 0 auto",
  },

  listComponent: {
    flex: "1 1 auto",
    minHeight: "100px",
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      display: "none",
    },

    "@media (pointer: fine)": {
      "& .remove-on-hover": { display: "unset" },
      "& .MuiListItem-container:hover .remove-on-hover": { display: "none" },

      "& .remove-unless-hover": { display: "none" },
      "& .MuiListItem-container:hover .remove-unless-hover": { display: "unset" },
    }
  },
}));

type MediaListViewProps = {
  list: MediaListWithItems,
  /** If true, play media when clicked (also called onClick handler) */
  clickToPlay: boolean,
  /**
   * Choose list view secondary action UI (right hand side UI for each list element).
   * - "contenxt-menu" (default) : shows "..." button to open context menu
   * - "pkay" : shows play/pause button
   */
  secondaryAction?: "context-menu" | "play",
  onClick?: (list: MediaListWithItems, index: number) => void,
  isSelected?: (list: MediaListWithItems, index: number) => boolean,
};

const MediaListView = React.memo((props: MediaListViewProps): JSX.Element => {
  const { list: _list } = props;
  const { listPinning } = useApp();
  const list = useMediaList(_list);
  const pinned = useListPinning(list);

  const [ contextMenuProps, onContextMenuOpen ] = useContextMenuState();
  const [ openCreateSmartPlaylistDialog ] = useDialog("CreateSmartPlaylistDialog");

  const classes = useMediaListStyles();
  if (!list) return <></>;
  return <div className={classes.root}>
    <div className={classes.header}>
      <div className={classes.headerTitle}>
        <IconButton onClick={() => { if(pinned) listPinning.unpin(list); else listPinning.pin(list); }}>
          {pinned ? <LabelIcon color="primary" /> : <LabelOffIcon />}
        </IconButton>
        <span className={classes.listName}>{list.name}</span>
        <span className={classes.listInfo}>{list.items.length} items</span>
      </div>
      <div className={classes.headerToolbar}>
        {(isTemporaryMediaList(list) && list.query) ? <>
          <IconButton onClick={() => openCreateSmartPlaylistDialog({ src: list })}>
            <PlaylistAddIcon />
          </IconButton>
        </> : null}
        <IconButton onClick={onContextMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      </div>
    </div>
    <List className={classes.listComponent}>
      {list.items.map((_, index) => <MediaListItem
        {...{
          ...props,
          list, // Must take precedence over props, otherwise could pass stale list object.
        }}
        key={index}
        index={index}
      />)}
    </List>
    {isContextMenuOpen(contextMenuProps) ? <MediaListViewContextMenu {...contextMenuProps} list={list} /> : null}
  </div>;
});
export default MediaListView;

const useMediaListItemStyles = makeStyles((theme) => ({
  listItem: {
    paddingRight: "140px",
    "@media (pointer: fine)": {
      paddingRight: "100px",
    },
  },
  unplayableMedia: {
    textDecoration: "line-through",
  },

  duration: {
    color: theme.palette.text.secondary,
    fontSize: "80%",
  },
  movieTypeChip: {
    marginLeft: "1em",
    height: "18px",
    fontSize: "11.5px",
  },
  rating: {  },
  albumName: { marginRight: "1em" },
  gameBrand: { marginRight: "1em" },
  artist: { marginRight: "1em" },

  secondaryActionInner: {
    display: "flex",
    flexWrap: "nowrap",
  },
  secondaryActionInnerInformationTip: {
    flex: "0 0 min-content",
    textAlign: "right",
    paddingTop: "6px",
  },
  secondaryActionButtons: {
    flex: "0 0 min-content",
  },
}));

const MediaListItem = React.memo(({list, index, clickToPlay, onClick, isSelected, secondaryAction }: MediaListViewProps & {
  index: number,
}): JSX.Element => {
  const { player } = useApp();
  const media = useMedia(list.items[index]);
  const attributes = media?.attributes;
  const fileInfo = media ? parseFileInfo(media.file.files[0]) : null;

  const playing = useCurrentMedia()?.id === media?.id;
  const paused = usePlayerPaused();
  const incompatible = media ? !player.isCompatible(media) : false;

  const selected = isSelected ? isSelected(list, index) : clickToPlay ? playing : false;

  const [ contextMenuProps, onContextMenuOpen, onRightClick ] = useContextMenuState();
  const onClickCallback = useCallback((e: React.MouseEvent) => {
    if (clickToPlay) player.playMediaList(list, index);
    if (onClick) onClick(list, index);
    e.stopPropagation();
    e.preventDefault();
  }, [clickToPlay, list, index, player, onClick]);

  const playButtonCallback = useCallback((e: React.MouseEvent) => {
    if (! playing) {
      player.playMediaList(list, index);
    } else {
      player.paused = !paused;
    }
    e.stopPropagation();
    e.preventDefault();
  }, [player, list, index, playing, paused]);

  const classes = useMediaListItemStyles();
  if (!(media && attributes && fileInfo)) return <></>;
  return <ListItem
    button
    className={classes.listItem}
    selected={selected}
    onClick={onClickCallback}
    tabIndex={-1}
  >
    {isSelected ? <ListItemIcon>
      <Checkbox
        edge="start"
        checked={selected}
        tabIndex={-1}
        disableRipple
        onClick={onClickCallback}
      />
    </ListItemIcon> : <ListItemAvatar>
      <MediaAvatar media={media} badge={playing ? "playing" : null} incompatible={incompatible} />
    </ListItemAvatar>}
    <ListItemText
      onContextMenu={onRightClick}
      primary={<span className={incompatible ? classes.unplayableMedia : ""}>
        {attributes.name ?? "( no name )"}
        {attributes.movieType ? <Chip size="small" variant="outlined" className={classes.movieTypeChip} label={movieTypeToString(attributes.movieType.toString())} /> : null}
      </span>}
      secondary={<>
        {attributes.albumName ? <span className={classes.albumName}>{attributes.albumName}</span> : null}
        {attributes.gameBrand ? <span className={classes.gameBrand}>{attributes.gameBrand}</span> : null}
        {attributes.artist ? <span className={classes.artist}>{attributes.artist}</span> : null}
      </>}
    />
    <ListItemSecondaryAction>
      <div className={classes.secondaryActionInner}>
        <div className={`${classes.secondaryActionInnerInformationTip} remove-on-hover`}>
          {(fileInfo.type !== "image") ? <div className={classes.duration}><DurationText duration={fileInfo.duration} /></div> : null}
          <div className={classes.rating}><RatingStars rating={attributes.rating} /></div>
        </div>
        <div className={`${classes.secondaryActionButtons} ${contextMenuProps.anchorEl ? "" : "remove-unless-hover"}`}>
          {secondaryAction === "play" ? <>
            <IconButton edge="end" onClick={playButtonCallback}>
              { playing && !paused ? <PauseIcon /> : <PlayArrowIcon /> }
            </IconButton>
          </> : <>
            <IconButton edge="end" onClick={onContextMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            {/* Do not render <MediaContextMenu> always, otherwise cause serious performance problem occurs especially when playlist updated. */}
            {list && isContextMenuOpen(contextMenuProps) ? <MediaContextMenu list={list} index={index} {...contextMenuProps} /> : null}
          </>}
        </div>
      </div>
    </ListItemSecondaryAction>
  </ListItem>;
});

function movieTypeToString(type: string): string {
  let result = "";
  for (let i = 0; i < type.length; i++) {
    // Insert space between non-upper-case character and upper-case character.
    if (i >= 1 && type[i - 1].toUpperCase() !== type[i - 1] && type[i].toUpperCase() === type[i]) {
      result += " ";
    }
    result += type[i];
  }
  return result;
}
