import { useEffect, useState } from "react";
import { useApp } from "..";

export default function useCurrentMediaType() {
  const { player } = useApp();
  const [ mediaType, setMediaType ] = useState(player.currentMediaType);
  useEffect(() => {
    const h = player.addListener("current-media-type-changed", () => setMediaType(player.currentMediaType));
    return () => h.remove();
  }, [ player, setMediaType ]);
  return mediaType;
}
