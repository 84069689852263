import { IconButton } from "@material-ui/core";
import usePlayerSettings from "../hooks/usePlayerSettings";
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { useApp } from "..";

export default function PlayingVolumeButton({small}: {
  small?: boolean,
}): JSX.Element {
  const { player } = useApp();
  const settings = usePlayerSettings();

  return <IconButton size={small ? "small" : "medium"} color="inherit" onClick={() => player.setSettings({ mute: !settings.mute })}>
    { settings.mute ? <VolumeOffIcon color="error" />
     : settings.volume >= 0.5 ? <VolumeUpIcon />
     : <VolumeDownIcon />
    }
  </IconButton>;
};
