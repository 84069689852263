import { Media, MediaThumbnail } from "../model/Media";
import { parseFileInfo, ImageFileInfo } from "../model/FileInfo";

export default function useThumbnail(m: Media | null, desiredSize: [ number, number ]): ImageFileInfo | null {
  if (!m) return null;

  const thumbnails: MediaThumbnail[] = m.thumbnails
    .filter((t) => !t.tiling)
    .sort((a, b) => (a.score ?? 0) - (b.score ?? 0))
    ;
  // If the media has thumbnails, use it. Otherwise use artwork image.
  const images = (thumbnails.length > 0) ? thumbnails[0].imageFile.files.map((fi) => parseFileInfo(fi) as ImageFileInfo)
    : (m.artworks.length > 0) ? m.artworks[0].files.map((fi) => parseFileInfo(fi) as ImageFileInfo)
    : [];
  if (images.length === 0) return null; // No candidate image found.

  const ratios = images.map((image, index) => [
    index,
    Math.max(image.widthPx / desiredSize[0], image.heightPx / desiredSize[1])
  ]).sort((a, b) => (a[1] - b[1])); // Larger image last.

  // If exact x1 -  x4 image found, use it.
  const exactMatchIndex = ratios.find(([, ratio]) => ratio % 1 === 0 && 1 <= ratio && ratio <= 4)?.[0];
  if (typeof(exactMatchIndex) === "number") return images[exactMatchIndex];

  // Use image larger than desired size.
  // Otherwise use largest one of the candidates.
  return images[ratios.find(([, ratio]) => ratio >= 1.0)?.[0] ?? ratios[ratios.length - 1][0]];
}
