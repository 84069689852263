import { Slider, withStyles } from "@material-ui/core";
import usePlayerSettings from "../hooks/usePlayerSettings";
import { useApp } from "..";

// https://material-ui.com/components/slider/#customized-sliders
const StyledSlider = withStyles({
  rail: {
    opacity: 0.3,
  },
  mark: {
    opacity: 0.3,
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

export default function PlayingVolumeSlider(): JSX.Element {
  const { player } = useApp();
  const settings = usePlayerSettings();

  // Use log scale
  const scaleBase = 5;
  const scaleToSlider = (volume: number) => Math.log(volume * (scaleBase - 1) + 1.0) / Math.log(scaleBase) * 100.0;
  const scaleFromSlider = (value: number) => ((scaleBase ** (value / 100.0)) - 1.0) / (scaleBase - 1);
  return <StyledSlider
    min={0.0}
    max={100.0}
    value={scaleToSlider(settings.volume)}
    onChange={(_, value) => player.setSettings({ volume: scaleFromSlider(value as number) })}

    color="primary"
    // marks={[1,2,3,4,5,6,7,8,9].map((v) => ({ value: scaleToSlider(v / 10) }))}
    valueLabelDisplay="auto"
    valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
    scale={scaleFromSlider} // scale does not affect value nor marks, only affects valueLabel
  />;
};
