import { useCallback, useState } from 'react';
import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { PlayerUI } from "../../components/PlayerUI";
import MediaListView from "../../components/MediaListView";
import SearchInput from "../../atoms/SearchInput";
import PlayingMediaThumbnail from "../../atoms/PlayingMediaThumbnail";
import PlayingMediaName from "../../atoms/PlayingMediaName";
import PlayingPauseResumeButton from "../../atoms/PlayingPauseResumeButton";
import PlayingNextMediaButtion from "../../atoms/PlayingNextMediaButtion";
import { MediaListWithItems } from '../../model/List';
import { toolbarHeight, uiStylesBase, AppUIProps, useAppMode, ListNavigation } from ".";
import MenuButton from "./MenuButton";
import { useApp } from '../..';
import ImageUI from "./imageUI";

const useLargeUIStyles = makeStyles(theme => ({
  afterAppBar: {
    ...theme.mixins.toolbar,
    height: `calc(100vh - ${toolbarHeight})`,
    overflowY: "scroll",
    '&::-webkit-scrollbar': {
      display: "none",
    },
  },
  leftAppBar: {
    // boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    boxShadow: "none",
  },
  rightAppBar: {
    // boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    boxShadow: "none",
  },
  rightAppBarPlayingMediaName: {
    marginLeft: "12px",
    marginTop: "7px",
  },
  rightToolBar: {
    paddingLeft: 0,
  },
  splitContainer: {
    display: "flex",
    flexFlow: "row nowrap",
  },
  splitLeft: {
    overflow: "hidden",
    minWidth: "70px",
  },
  splitLeftHidden: {
    overflow: "hidden",
    visibility: "hidden",
    minWidth: "0px",
    width: "0px",
  },
  splitRight: {
    overflow: "hidden",
    minWidth: "150px",
  },
}));

// eslint-disable-next-line no-empty-pattern
export default function LargeUI({ showDialog }: AppUIProps): JSX.Element {
  const { player } = useApp();
  const [ splitMode, setSplitMode ] = useState<"hide-left" | "small-left" | "large-left">("large-left");
  const columnStyles = (splitMode === "hide-left") ? [
    { flex: "0 0 0px" },
    { flex: "1 1 auto" },
  ] : (splitMode === "small-left") ? [
    { flex: "1 3 15%" },
    { flex: "3 1 15%" },
  ] : [
    { flex: "2 1 15%" },
    { flex: "1 2 15%" },
  ];

  const [ appState, setMode ] = useAppMode();
  const { mode, searchQuery } = appState;
  const [ mediaList, _setMediaList ] = useState<MediaListWithItems | null>(null);
  const setMediaList = useCallback((item: MediaListWithItems) => {
    if (splitMode === "large-left") setSplitMode("small-left");
    _setMediaList(item);
  }, [ splitMode, setSplitMode, _setMediaList ]);
  const showCurrentMediaList = useCallback(() => {
    if (player.currentList) setMediaList(player.currentList);
  }, [ player, setMediaList ]);

  const classes = { ...uiStylesBase(), ...useLargeUIStyles() };
  if (appState.mode === "confidential-films") return <ImageUI showDialog={showDialog} appState={appState} setMode={setMode} />;
  return <>
    <div className={classes.root + " " + classes.splitContainer}>
      {/* Note: To keep list navigation UI state, preserve virtual dom even when minimized. */}
      <div className={(splitMode === "hide-left" ? classes.splitLeftHidden : classes.splitLeft)} style={{ ...columnStyles[0]}}>
        <AppBar position="sticky" className={classes.leftAppBar}>
          <Toolbar>
            <div className={classes.toolBarButtons}>
              <MenuButton mode={mode} onSelected={setMode} showDialog={showDialog} />
            </div>
            <div className={classes.toolBarGrow}>
              <SearchInput queryTemplate={searchQuery} />
            </div>
            <div className={classes.toolBarButtons}>
              {splitMode !== "large-left" ? <IconButton edge="end" color="inherit" onClick={() => setSplitMode("large-left")}>
                <ChevronRightIcon />
              </IconButton> : null}
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.afterAppBar}>
          {/* Use setter of useState(), it returns same function instance thus can utilize memo. */}
          <ListNavigation {...appState} onSelect={setMediaList}  />
        </div>
      </div>
      <div className={classes.splitRight} style={{ ...columnStyles[1] }}>
        <AppBar position="sticky" className={classes.rightAppBar}>
          <Toolbar className={classes.rightToolBar}>
            <div className={classes.toolBarButtons}>
              {splitMode !== "hide-left" ? <IconButton edge="start" color="inherit" onClick={() => setSplitMode(splitMode === "small-left" ? "hide-left" : "small-left")}>
                <ChevronLeftIcon />
              </IconButton> : <IconButton edge="start" color="inherit" onClick={() => setSplitMode("small-left")}>
                  <ChevronRightIcon />
                </IconButton>}
            </div>
            <div className={`${classes.toolBarGrow} clickable`} onClick={showCurrentMediaList}>
              <PlayingMediaThumbnail />
              <Typography noWrap className={classes.rightAppBarPlayingMediaName}><PlayingMediaName /></Typography>
            </div>
            <div className={classes.toolBarButtons}>
              <PlayingPauseResumeButton />
              <PlayingNextMediaButtion />
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.afterAppBar + " " + classes.mediaPlayer}>
          <div className={classes.playerContainer}><PlayerUI /></div>
          <div className={classes.mediaListContainer}>{mediaList ? <MediaListView clickToPlay={true} list={mediaList} /> : <>No list selected</>}</div>
        </div>
      </div>
    </div> {/* splitContainer */}
  </>;
}
