import { EventEmitter, EventSubscription } from 'fbemitter';
import { isSameList, MediaListWithItems } from "../../List";
import { Media } from '../../Media';

export const playlistControllerEventTypes = [ "current-list-changed", "current-media-changed" ] as const;
export type PlaylistControllerEventType = (typeof playlistControllerEventTypes)[number];

export type PlaylistControllerDeps = {};

/** @internal */
export default class PlaylistController {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(deps: PlaylistControllerDeps) {}

  private emitter = new EventEmitter();
  addListener(t: PlaylistControllerEventType, f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  private _currentList: MediaListWithItems | null = null;
  private _currentTrackIndexOnList: number | null = null;

  get currentList(): MediaListWithItems | null { return this._currentList; };
  get currentMedia(): Media | null { return this.currentList?.items[this._currentTrackIndexOnList ?? -1] ?? null; }

  /** Start playing the list. Start from given index in the list. */
  playMediaList(list: MediaListWithItems, startIndex: number) {
    const listChanged = this._currentList == null || !isSameList(this._currentList, list);
    const trackChanged = listChanged || (this._currentTrackIndexOnList !== startIndex);

    if (listChanged) { this._currentList = list; }
    if (trackChanged) { this.startTrackOfIndex(startIndex); }
    if (listChanged) this.emitter.emit("current-list-changed"); // Raise event after modifications.
  }

  /** Move forward to next track of the current list. */
  nextTrack() {
    const i = this.calcTrackIndex(0, +1);
    if (i === null) return;
    this.startTrackOfIndex(i);
  }

  /** Move to previous track. */
  previousTrack() {
    const i = this.calcTrackIndex(0, -1);
    if (i === null) return;
    this.startTrackOfIndex(i);
  }

  /** @param delta : 0 = current track, +1 = next track, -1 = previous track. */
  getMediaByRelativePosition(delta: number): Media | null {
    const i = this.calcTrackIndex(0, delta);
    if (i === null) return null;
    return this._currentList!!.items[i];
  }

  private calcTrackIndex(defaultIndex: number, delta: number): number | null {
    if (this._currentList === null) return null;

    const length = this._currentList!!.items.length;
    if (length === 0) return 0; // For empty list, always use track number 0 as pseudo position.

    const mod = ((this._currentTrackIndexOnList === null) ? defaultIndex : this._currentTrackIndexOnList + delta) % length;
    return (mod < 0) ? mod + length : mod;
  }

  private startTrackOfIndex(_index: number) {
    const { list, index } = this.checkTrackIndex(_index);
    if (index === this._currentTrackIndexOnList) return;

    if (list.items.length > 0) this._currentTrackIndexOnList = index;
    this.emitter.emit("current-media-changed");
  }

  private checkTrackIndex(index: number): { list: MediaListWithItems, index: number } {
    const list = this._currentList;
    if (list === null) throw new Error("[BUG] No MediaList set.");
    if (list.items.length === 0) return { list, index: 0 }; // For empty list, always use track number 0 as pseudo position.

    assertValidIndex(list, index);
    return { list, index };
  }
}

function assertValidIndex(list: MediaListWithItems, index: number) {
  if (list === null) throw new Error("[BUG] Empty (null) MediaList given.");
  if (list.items.length === 0 && index !== 0) throw new Error(`[BUG] For empty list, pseudo index (0) must be given but got ${index}`);
  if (index < 0 || list.items.length <= index) throw new Error(`[BUG] Invalid index ${index} given (list ${list.id} has ${list.items.length} items).`);
}
