import { useEffect, useState } from 'react';
import { useApp } from '..';
import { Media } from '../model/Media';

export default function useCurrentMedia(): Media | null {
  const { player } = useApp();
  const [ currentMedia, setCurrentMedia ] = useState(player.media);
  useEffect(() => {
    const h = player.addListener("current-media-changed", () => setCurrentMedia(player.media));
    return () => h.remove();
  }, [ player, setCurrentMedia ]);
  return currentMedia;
}
