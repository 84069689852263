import React, { useEffect, useRef, useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import useSize from '@react-hook/size';
import { useWindowHeight } from '@react-hook/window-size';
import PlayingPauseResumeButton from "../atoms/PlayingPauseResumeButton";
import PlayingNextMediaButtion from "../atoms/PlayingNextMediaButtion";
import PlayingPreviousMediaButtion from "../atoms/PlayingPreviousMediaButtion";
import PlayingVolumeButton from "../atoms/PlayingVolumeButton";
import PlayingVolumeSlider from "../atoms/PlayingVolumeSlider";
import PlayingProgressBar from "../atoms/PlayingProgressBar";
import { parseFileInfo, ImageFileInfo } from "../model/FileInfo";
import useStorageUrl from "../hooks/useStorageUrl";
import useCurrentMedia from "../hooks/useCurrentMedia";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useCurrentMediaType from "../hooks/useCurrentMediaType";
import { useApp } from "..";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Color from "color";
import PauseCircleFilledTwoToneIcon from '@material-ui/icons/PauseCircleFilledTwoTone';
import usePlayerPaused from "../hooks/usePlayerPaused";

const progressBarHeight = 16;
const toolBarHeight = 30;
const useUIStyles = makeStyles((theme) => ({
  playerRoot: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",

    // .fullscreen-enabled class is set by <FullScreen> component.
    ".fullscreen-enabled &": {
      display: "block",
    },
  },
  display: {
    flex: "1 1 0",
    zIndex: 1,
    overflow: "hidden",

    ".fullscreen-enabled &": {
      height: "100%",
    },
  },
  control: {
    flex: `0 0 ${progressBarHeight+toolBarHeight}px`,
    zIndex: 2,
    width: "100%",
    backgroundColor: theme.palette.background.default,

    ".fullscreen-enabled &": { // Hide controls by default (fullscreen)
      position: "absolute",
      bottom: 0,
      backgroundColor: "transparent",

      "& > *": {
        visibility: "hidden",
      },
    },
    ".fullscreen-enabled &:hover": { // Show controls on hover (fullscreen)
      backgroundColor: Color(theme.palette.background.default).alpha(0.7).string(),

      "& > *": {
        visibility: "visible",
      }
    },
  },

  progressBar: {
    height: `${progressBarHeight}px`,
    marginLeft: "12px",
    marginRight: "9px",
  },
  toolbar: {
    height: `${toolBarHeight}px`,
    marginLeft: "5px",
    marginRight: "5px",
    display: "flex",
    flexWrap: "nowrap",
  },

  toolbarTrackButtons: {
    flex: "0 0 auto",
  },
  toolbarVolumeButton: {
    flex: "0 0 auto",
  },
  toolbarVolumeSlider: {
    flex: `1 1 100%`,
    maxWidth: "200px",
    marginRight: "20px",
  },
  toolbarFiller: {
    flex: `1 1 100%`,
  },
  toolbarAdditionalButtons: {
    flex: "0 0 auto",
  },
}));

export const PlayerUI = React.memo((): JSX.Element => {
  const { player: Player } = useApp();
  const mediaType = useCurrentMediaType();
  const containerRef = useRef<HTMLDivElement>(null);

  const fullScreen = useFullScreenHandle();
  const windowHeight = useWindowHeight(); // Debounced hook.
  const [ heightMode, setHeightMode ] = useState<"small" | "max">("max");
  const [ width ] = useSize(containerRef);
  let height: string;
  if (fullScreen.active) {
    height = "100%";
  } else {
    const displayAspectRatio = 9/16;
    const heightMultiplier = {
      small: (mediaType === "audio") ? 0 : 0.5,
      max: (mediaType === "audio") ? 0.5 : 1.0,
    }[heightMode];
    height = Math.min(
      windowHeight - 250,
      (width * displayAspectRatio * heightMultiplier) + progressBarHeight + toolBarHeight
    ) + "px";
  }

  const classes = useUIStyles();
  return <FullScreen handle={fullScreen}>
    <div ref={containerRef} className={classes.playerRoot} style={{ height, maxHeight: height }}>
      <div className={classes.display} onClick={() => Player.paused = !Player.paused}>
        <PlayerDisplay />
      </div>
      <div className={classes.control}>
        <div className={classes.progressBar}><PlayingProgressBar containerRef={containerRef} /></div>
        <div className={classes.toolbar}>
          <div className={classes.toolbarTrackButtons}>
            <PlayingPreviousMediaButtion small />
            <PlayingPauseResumeButton small />
            <PlayingNextMediaButtion small />
          </div>
          <div className={classes.toolbarVolumeButton}><PlayingVolumeButton small /></div>
          <div className={classes.toolbarVolumeSlider}><PlayingVolumeSlider /></div>
          <div className={classes.toolbarFiller}></div>
          <div className={classes.toolbarAdditionalButtons}>
            <IconButton size="small" onClick={() => fullScreen.active ? fullScreen.exit() : fullScreen.enter()}>
              {fullScreen.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            {fullScreen.active ? null : <IconButton size="small" onClick={() => setHeightMode(heightMode === "max" ? "small" : "max")}>
              {(heightMode === "max") ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>}
          </div>
        </div>
      </div>
    </div>
  </FullScreen>;
});

const PlayerDisplay = (): JSX.Element => {
  const mediaType = useCurrentMediaType();
  switch (mediaType) {
    case "audio": return <AudioPlayerDisplay />;
    default: return <PlayerHTMLElementWrapper />;
  }
};

const usePlayerHTMLElementWrapperStyles = makeStyles((theme) => ({
  displayRoot: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  pausedIcon: {
    position: "absolute",
    zIndex: 2,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    textAlign: "center",

    color: "#aaa",
    fontSize: "8rem",
    ".fullscreen-enabled &": {
      fontSize: "25rem",
    },
  },
  container: {
    height: "100%",
    width: "100%",
    zIndex: 1,

    "& VIDEO": {
      height: "100%",
      width: "100%",
      margin: "auto",
    },
  },
}));

// Use separate memorized component to keep media element stable.
const PlayerHTMLElementWrapper = React.memo((): JSX.Element => {
  const { player } = useApp();
  const [ containerElement, setContainerElement ] = useState<HTMLElement | null>(null);
  const [ playerElement, setPlayerElement ] = useState<HTMLElement | null>(player.htmlElement);
  const paused = usePlayerPaused();
  useEffect(() => {
    const h = player.addListener("html-element-changed", () => {
      setPlayerElement(player.htmlElement);
    });
    return () => h.remove();
  }, [ player, setPlayerElement ]);
  useEffect(() => {
    if (containerElement && playerElement) containerElement.appendChild(playerElement);
    return () => {
      if (containerElement && playerElement) containerElement.removeChild(playerElement);
    };
  }, [ containerElement, playerElement ]);

  const classes = usePlayerHTMLElementWrapperStyles();
  return <div className={classes.displayRoot}>
    {paused ? <PauseCircleFilledTwoToneIcon className={classes.pausedIcon} /> : null}
    <div className={classes.container} ref={setContainerElement}>{/* Hook inserts Player.htmlElement here. */}</div>
  </div>;
});

const audioPlayerDisplayStyles = makeStyles((theme) => ({
  artwork: {
    display: "block",
    margin: "auto",
    height: "auto",
    width: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

const AudioPlayerDisplay = React.memo((): JSX.Element => {
  const media = useCurrentMedia();
  let image: ImageFileInfo | null = null;
  if (media?.artworks[0]) { // FIXME: Should prefer thumbnails not artwork itself.
    image = parseFileInfo(media.artworks[0].files[0]) as ImageFileInfo;
  }
  const imageUrl = useStorageUrl(image?.fileHash ?? null);

  const classes = audioPlayerDisplayStyles();
  return <img
    className={classes.artwork}
    src={imageUrl ?? undefined}
    alt={media?.attributes.name}
  />;
});
