import { PlayerSettings } from "../PlayerSettings";
import Player from "../Player";
import CurrentWindow from "../../CurrentWindow";
import { DateTime } from "luxon";
import Clock from "../../Clock";

export type PlayerSettingsSyncDeps = { clock: Clock, player: Player, currentWindow: CurrentWindow };

const storageKey = "smss.player-settings";
const saveDebounceMills = 500;

export default class PlayerSettingsSync {
  private clock: Clock;
  private player: Player;
  private currentWindow: CurrentWindow;
  private storage: Storage = window.localStorage;

  private lastModifiedAt: DateTime;

  constructor(deps: PlayerSettingsSyncDeps) {
    this.clock = deps.clock;
    this.player = deps.player;
    this.currentWindow = deps.currentWindow;

    this.lastModifiedAt = this.initialLoad();
    this.initSyncHandler();
  }
  shutdown() {
    this.shutdownSyncHandler();
  }

  private initialLoad(): DateTime {
    const container = this.fetch();
    if (container != null) this.player.setSettings(container.settings);
    return this.clock.nowDateTime;
  }

  private shutdownSyncHandler: () => void = () => {};
  private initSyncHandler() {
    const settingUpdateHandle = this.player.addListener("settings-changed", () => {
      this.lastModifiedAt = this.clock.nowDateTime;
      this.save(this.player.settings);
    });

    const storageUpdateHandler = (event: StorageEvent) => {
      if (event.key !== storageKey) return;

      const container = this.fetch();
    if (container != null) {
      if (container.modifiedAtUnixEpoch > this.lastModifiedAt.toMillis() && container.windowID !== this.currentWindow.id) {
        this.player.setSettings(container.settings);
      }
    }
    };
    window.addEventListener("storage", storageUpdateHandler);

    this.shutdownSyncHandler = () => {
      settingUpdateHandle.remove();
      window.removeEventListener("storage", storageUpdateHandler);
    };
  }

  private fetch(): SettingsContainer | null {
    const json = this.storage.getItem(storageKey);
    if (json === null) return null;

    let container: SettingsContainer;
    try {
      container = JSON.parse(json);
    } catch(e) {
      console.warn("Corrupted PlayerSettings JSON", json, e);
      return null;
    }
    if (typeof(container.windowID) !== "string" || typeof(container.modifiedAtUnixEpoch) !== "number") {
      console.warn("PlayerSettings JSON format error", json);
      return null;
    }
    return container;
  }

  private saveDebounceTimer: number | null = null;
  private save(settings: PlayerSettings) {
    const modifiedAt = this.lastModifiedAt.toMillis();

    if (this.saveDebounceTimer !== null) window.clearTimeout(this.saveDebounceTimer);
    this.saveDebounceTimer = window.setTimeout(() => {
      const currentContainer = this.fetch();
      if (currentContainer && currentContainer.modifiedAtUnixEpoch > modifiedAt) return;

      const container: SettingsContainer = {
        windowID: this.currentWindow.id,
        modifiedAtUnixEpoch: modifiedAt,
        settings,
      };
      this.storage.setItem(storageKey, JSON.stringify(container));
    }, saveDebounceMills);
  }
}

type SettingsContainer = {
  windowID: string,
  modifiedAtUnixEpoch: number,
  settings: Partial<PlayerSettings>,
};
