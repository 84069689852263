import { EventEmitter, EventSubscription } from 'fbemitter';
import { Media, MediaFileType } from '../Media';
import { MediaListWithItems } from "../List";
import { MediaPlayer, MediaPlayerEventType, mediaPlayerEventTypes, mediaPlayerCtors, MediaPlayerDeps } from "./internal/player-core";
import { defaultPlayerSettings, PlayerSettings } from './PlayerSettings';
import PlaylistController, { PlaylistControllerEventType, playlistControllerEventTypes } from "./internal/PlaylistController";

/** Start prefetching next track before end of the current track. */
const prefetchNextTrackBeforeSec = 10;

export type PlayerEvents = "current-media-type-changed" | PlaylistControllerEventType | MediaPlayerEventType;

export type PlayerDeps = {
  playlistController: PlaylistController,
} & MediaPlayerDeps;

/**
 * Provides high level control of media playback.
 */
export default class Player {
  private readonly mediaPlayers: { [type in MediaFileType]: MediaPlayer };
  private readonly playlist: PlaylistController;

  constructor(deps: PlayerDeps) {
    this.playlist = deps.playlistController;
    this.playlist.addListener("current-media-changed", () => this._setCurrentMedia(this.playlist.currentMedia));
    for (const type of playlistControllerEventTypes) {
      this.playlist.addListener(type, (...args: any) => this.emitter.emit(type, ...args));
    }

    this.mediaPlayers = Object.fromEntries(Object.entries(mediaPlayerCtors).map(([type, ctor]) => [ type, ctor(deps) ])) as { [type in MediaFileType]: MediaPlayer };
    this.syncSettings();
    this.setupMediaPlayerEvents();

    this.addListener("end-of-media", () => {
      this.playlist.nextTrack(); // Start next track automatically.
    });
    this.setupPrefetch();
  }
  shutdown() {
    for (const [, player] of Object.entries(this.mediaPlayers)) { player.shutdown(); }
  }

  private emitter = new EventEmitter();
  addListener(t: PlayerEvents, f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  // Bubble currently playing MediaPlayer event.
  private setupMediaPlayerEvents() {
    for(const [type, corePlayer] of Object.entries(this.mediaPlayers)) {
      for(const t of mediaPlayerEventTypes) {
        switch(t) {
          case "settings-changed": break; // No need to bubble, this class emits.
          default:
            corePlayer.addListener(t, (...args: any) => {
              if (this.currentMediaType === type) {
                this.emitter.emit(t, ...args);
              }
            });
            break;
        }
      }
    }
  }

  private _currentMediaType: MediaFileType = "audio";
  private get currentCorePlayer(): MediaPlayer { return this.mediaPlayers[this._currentMediaType]; }
  get currentMediaType() { return this._currentMediaType; }
  private setCurrentMediaType(value: MediaFileType) {
    if (this._currentMediaType === value) return;
    this.currentCorePlayer.paused = true;
    this._currentMediaType = value;

    // Fire core player events because of player change.
    mediaPlayerEventTypes.forEach((eventType) => {
      switch(eventType) {
        case "end-of-media": break;
        default:
          this.emitter.emit(eventType);
          break;
      }
    });
    this.emitter.emit("current-media-type-changed");
  }
  private _setCurrentMedia(m: Media | null) {
    if (this.media?.id === m?.id) return; // Unchanged.

    if (m) this.setCurrentMediaType(m.type);
    this.currentCorePlayer.media = m;
  }

  private setupPrefetch() {
    this.addListener("current-time-changed", () => {
      if(this.durationSec !== null && this.currentTimeSec !== null && this.durationSec - this.currentTimeSec < prefetchNextTrackBeforeSec) {
        const next = this.playlist.getMediaByRelativePosition(+1);
        if (next) this.currentCorePlayer.prefetch(next);
      }
    });
  }


  private _settings: PlayerSettings = { ...defaultPlayerSettings };
  get settings(): PlayerSettings { return { ...this._settings }; }
  setSettings(settings: Partial<PlayerSettings>, limit?: boolean) {
    const nextSettings = { ...this.settings, ...settings };
    this._settings = nextSettings;
    this.syncSettings();
    this.emitter.emit("settings-changed");
  }
  private syncSettings() {
    for(const [, corePlayer] of Object.entries(this.mediaPlayers)) {
      corePlayer.settings = this._settings;
    }
  }


  // --- Delegate to PlaylistController ---

  get currentList(): MediaListWithItems | null { return this.playlist.currentList; }
  playMediaList(list: MediaListWithItems, startIndex: number){
    this.playlist.playMediaList(list, startIndex);
    this.paused = false;
  }
  nextTrack(){
    this.playlist.nextTrack();
    this.paused = false;
  }
  previousTrack(){
    if ((this.currentTimeSec ?? 0.0) >= 1.5) {
      this.currentTimeSec = 0.0;
    } else {
      this.playlist.previousTrack();
      this.paused = false;
    }
  }

  // --- Delegate to Core Player ---

  get htmlElement(): HTMLElement | null { return this.currentCorePlayer.htmlElement; }
  get media(): Media | null { return this.currentCorePlayer.media; }
  get fileHash(): string | null { return this.currentCorePlayer.fileHash; }
  get mediaPlayable(): boolean { return this.currentCorePlayer.mediaPlayable; }
  get paused(): boolean { return this.currentCorePlayer.paused; }
  set paused(value: boolean) { this.currentCorePlayer.paused = value; }
  get currentTimeSec(): number | null { return filterNumber(this.currentCorePlayer.currentTimeSec); }
  set currentTimeSec(value: number | null) { this.currentCorePlayer.currentTimeSec = value; }
  get durationSec(): number | null { return filterNumber(this.currentCorePlayer.durationSec); }
  isCompatible(m: Media) { return this.mediaPlayers[m.type].isCompatible(m); }
};

function filterNumber(num: number | null): number | null {
  if (num === null || !isFinite(num)) return null;
  return num;
}
