import React, { useCallback, useEffect, useState } from "react";
import { imageOrientationOf, Media } from "../../../../model/Media";
import { createMuiTheme, Dialog, IconButton, makeStyles, ThemeProvider } from "@material-ui/core";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ImageFileInfo, ImageFormat, mimeTypeOf, parseFileInfo, VideoFileInfo } from "../../../../model/FileInfo";
import useStorageUrl from "../../../../hooks/useStorageUrl";
import useImageTransform from "../../../../hooks/useImageTransform";
import { useApp } from "../../../..";

// Always use dark theme for this dialog.
const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  menubar: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 100,

    "& .menubarContent": { visibility: "hidden" },
    "&.touched .menubarContent": { visibility: "visible" },
    "&:hover .menubarContent": { visibility: "visible" },
  },
  menubarContent: {
    display: "flex",

    background: theme.palette.background.default,
  },
  menubarContentLeft: {
    flex: "1 1 auto",
  },
  menubarContentRight: {
    flex: "0 0 auto",
  },
  viewer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  footer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    zIndex: 90,

    "& .footerContent": { visibility: "hidden" },
    "&.touched .footerContent": { visibility: "visible" },
    "&:hover .footerContent": { visibility: "visible" },
  },
  footerContent: {
    background: theme.palette.background.default,
  },
}));

export default React.memo(({ open, onClose, items, initialIndex }: {
  open: boolean,
  onClose: () => void,
  items: Media[],
  initialIndex: number,
}): JSX.Element => {
  const [ index, setIndex ] = useState(initialIndex);
  const show = useCallback((delta: number) => {
    if (items.length === 0) return;
    const newIndex = (index + delta) % items.length;
    setIndex(newIndex);
  }, [ items, index ]);
  const showPrevious = useCallback(() => show(-1), [ show ]);
  const showNext = useCallback(() => show(+1), [ show ]);

  const [ touched, setTouched ] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setTouched(false), 3000);
    return () => clearTimeout(timer);
  }, [ touched ]);

  const media = (items.length === 0) ? null : items[index];

  const classes = useStyles();
  return <ThemeProvider theme={theme}>
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen
    >
      <div className={`${classes.container} ${touched ? "touched" : ""}`} onClick={() => setTouched(true)}>
        <div className={classes.menubar}>
          <div className={`${classes.menubarContent} menubarContent`}>
            <div className={classes.menubarContentLeft}>
              <IconButton onClick={onClose}><CloseOutlinedIcon /></IconButton>
            </div>
            <div className={classes.menubarContentRight}>
              <IconButton onClick={showPrevious} disabled={index < 1}><NavigateBeforeIcon /></IconButton>
              <IconButton onClick={showNext} disabled={index + 1 >= items.length}><NavigateNextIcon /></IconButton>
            </div>
          </div>
        </div>
        <div className={classes.viewer} onClick={showNext}>
          {media?.type === "image" ? <ImageViewer media={media} />
          : media?.type === "video" ? <VideoViewer media={media} />
          : null
          }
        </div>
        <div className={classes.footer}>
          <div className={`${classes.footerContent} footerContent`}>
            {media?.type},&nbsp;
            {media?.attributes.dateAdded},&nbsp;
            {media?.attributes.name},&nbsp;
            {media ? imageOrientationOf(media) : null},&nbsp;
            {media?.file.files[0].sizeBytes} bytes,&nbsp;
          </div>
        </div>
      </div>
    </Dialog>
  </ThemeProvider>;
});

const useImageStyles = makeStyles(theme => ({
  img: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

const ImageViewer = React.memo(({ media }: {
  media: Media,
}): JSX.Element => {
  const images = media.file.files.map(parseFileInfo)
    .filter((fi): fi is ImageFileInfo => fi.type === "image")
    .filter((fi) => [ImageFormat.Gif, ImageFormat.Jpeg, ImageFormat.Png].indexOf(fi.format) !== -1);
  images.sort((a, b) => b.heightPx * b.widthPx - a.heightPx * b.widthPx);
  const image = images[0]; // Largest image
  const imageUrl = useStorageUrl(image?.fileHash ?? null);

  const classes = useImageStyles();
  const transform = useImageTransform(media);
  return <img
    className={classes.img}
    style={{
      transform,
    }}
    src={imageUrl ?? undefined}
    alt={media.attributes.name}
  />;
});

const useVideoStyles = makeStyles(theme => ({
  video: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

const VideoViewer = React.memo(({ media }: {
  media: Media,
}): JSX.Element => {
  const { device } = useApp();

  const files = media.file.files.map((fi) => parseFileInfo(fi as any)).filter((file): file is VideoFileInfo => file.type === "video");
  const file = device.videoFormatPreference.flatMap((format) =>
    files.filter((fi) => fi.codingFormat === format && mimeTypeOf(fi) !== null)
  )[0] ?? null;
  const videoUrl = useStorageUrl(file?.fileHash ?? null);

  const classes = useVideoStyles();
  const transform = useImageTransform(media);
  return <video
    className={classes.video}
    style={{
      transform,
    }}
    src={videoUrl ?? undefined}
  />;
});
