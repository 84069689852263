import { useEffect, useState } from 'react';
import { useApp } from '..';

export default function usePlayerPaused(): boolean {
  const { player } = useApp();
  const [ paused, setPaused ] = useState(player.paused);
  useEffect(() => {
    const h = player.addListener("pause-resume", () => setPaused(player.paused));
    return () => h.remove();
  }, [ player, setPaused ]);
  return paused;
}
