import { useEffect, useState } from "react";
import { DateTime, Duration, Interval } from "luxon";
import { useApp } from "..";
import { HistoriaHistory } from "../model/Historia";

export default function useHistoriaHistories(between?: Interval): null | HistoriaHistory[] {
  const { historia } = useApp();

  const [ histories, setHistories ] = useState<null | HistoriaHistory[]>(null);
  useEffect(() => {
    (async () => {
      setHistories((await historia.list()).filter((h) => !between || between.overlaps(Interval.fromDateTimes(
        DateTime.fromISO(h.at),
        DateTime.fromISO(h.at).plus(Duration.fromObject({ days: (h.days ?? 1) - 1 })),
      ))));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ historia, between ? between.toISO() : null ]);
  return histories;
}
