export type PlayerSettings = {
  mute: boolean;
  /** 0.0 to 1.0 */
  volume: number;
};

export const defaultPlayerSettings: PlayerSettings = {
  mute: false,
  volume: 0.1,
};
