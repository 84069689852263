import React, { useCallback } from "react";
import { HotKeys as ReactHotKeys, GlobalHotKeys as ReactGlobalHotKeys } from "react-hotkeys";
import { useApp } from "../";

const keyMap = {
  // ref: Keyboard shortcuts for YouTube https://support.google.com/youtube/answer/7631406?hl=en
  PAUSE_RESUME: ["space", "k"],

  MUTE_UNMUTE: "m",
  VOLUME_DOWN_5percent: "down",
  VOLUME_PLUS_5percent: "up",

  BACK_10sec: "j",
  BACK_5sec: "left",
  FORWARD_5sec: "right",
  FORWARD_10sec: "l",

  FOCUS_SEARCH_BOX: "/",
  FULL_SCREEN: "f",

  PREVIOUS_TRACK: "shift+p",
  NEXT_TRACK: "shift+n",
};

/** Hot Key not handled by the <AppHotKeys>. */
export const hotKeyTypes = [ "FOCUS_SEARCH_BOX", "FULL_SCREEN" ] as const;
export type HotKeyType = (typeof hotKeyTypes)[number];

export default function HotKeys({ children, handlers }: {
  children: React.ReactNode,
  handlers: { [key in HotKeyType]?: (keyEvent?: KeyboardEvent) => void },
}): JSX.Element {
  return <ReactHotKeys handlers={handlers}>
    {children}
  </ReactHotKeys>;
}

export function AppHotKeys({ children }: {
  children: React.ReactNode,
}): JSX.Element {
  const { player } = useApp();
  const handlers: {
    [key in Exclude<(keyof typeof keyMap), HotKeyType>]: (keyEvent?: KeyboardEvent) => void
  } = {
    PAUSE_RESUME: useCallback(() => { player.paused = !player.paused; }, [player]),

    MUTE_UNMUTE: useCallback(() => { player.setSettings({ mute: !player.settings.mute }); }, [player]),
    VOLUME_DOWN_5percent: useCallback(() => { player.setSettings({ volume: Math.max(0.0, player.settings.volume - 0.05) }); }, [player]),
    VOLUME_PLUS_5percent: useCallback(() => { player.setSettings({ volume: Math.min(1.0, player.settings.volume + 0.05) }); }, [player]),

    BACK_10sec: useCallback(() => { if (player.currentTimeSec) player.currentTimeSec = Math.max(0.0, player.currentTimeSec - 10.0); }, [player]),
    BACK_5sec: useCallback(() => { if (player.currentTimeSec) player.currentTimeSec = Math.max(0.0, player.currentTimeSec - 5.0); }, [player]),
    FORWARD_5sec: useCallback(() => { if (player.currentTimeSec && player.durationSec) player.currentTimeSec = Math.min(player.durationSec, player.currentTimeSec + 5.0); }, [player]),
    FORWARD_10sec: useCallback(() => { if (player.currentTimeSec && player.durationSec) player.currentTimeSec = Math.min(player.durationSec, player.currentTimeSec + 10.0); }, [player]),

    PREVIOUS_TRACK: useCallback(() => { player.previousTrack(); }, [player]),
    NEXT_TRACK: useCallback(() => { player.nextTrack(); }, [player]),
  };
  return <>
    <ReactGlobalHotKeys keyMap={keyMap} handlers={handlers} />
    {children}
  </>;
}
