import firebase from 'firebase/app';
import { EventEmitter, EventSubscription } from 'fbemitter';

export default class Firebase {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(deps: {}) {}

  private emitter = new EventEmitter();
  addListener(t: "initialized", f: Function): EventSubscription {
    return this.emitter.addListener(t, f);
  }

  async init() {
    if (this.app) {
      console.debug("Firebase already initialized.", firebase.apps);
      this.emitter.emit("initialized");
      return;
    }

    const firebaseInitJSON = await (await fetch("/__/firebase/init.json")).json();
    // firebaseInitJSON.authDomain = window.location.hostname;
    console.debug("Firebase init JSON is: ", firebaseInitJSON);
    firebase.initializeApp(firebaseInitJSON);
    console.debug("Firebase successfully initialized.", firebase.apps);
    this.emitter.emit("initialized");
  }

  get app(): firebase.app.App {
    return firebase.apps[0];
  }
};

